import { Controller, useForm } from 'react-hook-form';
import { Loader } from '../../components/Loader';
import { ErrorMessage } from '@hookform/error-message';
import { useSignupPartner } from '../../hooks/useSignupPartner';
import notifications from '../notifications';

export const PartnerSignupForm = ({
  isEditing,
  defaultValues,
  setIsSuccess,
}) => {
  const { control, handleSubmit, errors } = useForm({
    shouldUnregister: false,
    defaultValues: isEditing ? defaultValues : undefined,
  });

  const { mutation: signupPartner } = useSignupPartner({ invalidate: false });

  const onSubmit = (data) => {
    const origin = window.origin;
    const payload = {
      ...data,
      clusterId: '61818869c729d2843791b95a',
      redirectUrl: `${origin}/verify/partner`,
    };

    signupPartner.mutate(payload, {
      onSuccess: () => {
        setIsSuccess(true);
      },
      onError: (error) => {
        notifications.error('Cannot Signup', error.message);
      },
    });
  };

  return (
    <div>
      <form className='space-y-4' onSubmit={handleSubmit(onSubmit)}>
        <div className='mt-7'>
          <label
            className='block text-sm font-medium leading-6 text-gray-900'
            htmlFor='companyName'
          >
            Company Name
          </label>
          <div className='mt-2'>
            <Controller
              placeholder='Company Name'
              rules={{ required: true }}
              id='companyName'
              name='companyName'
              className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
              control={control}
              as={<input />}
            />
            <ErrorMessage
              errors={errors}
              name='companyName'
              message='Company name is required'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>

        <div className='grid grid-cols-6 gap-6'>
          <div className='col-span-3'>
            <label
              className='block text-sm font-medium leading-6 text-gray-900'
              htmlFor='firstname'
            >
              First Name
            </label>
            <div className='mt-2'>
              <Controller
                placeholder='First Name'
                rules={{ required: true }}
                id='firstname'
                name='firstName'
                className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                control={control}
                as={<input />}
              />
              <ErrorMessage
                errors={errors}
                name='firstName'
                message='Firstname is required'
                render={({ message }) => (
                  <p className='text-red-500 mt-2 text-sm'>{message}</p>
                )}
              />
            </div>
          </div>

          <div className='col-span-3'>
            <label
              className='block text-sm font-medium leading-6 text-gray-900'
              htmlFor='lname'
            >
              Last Name
            </label>
            <div className='mt-2'>
              <Controller
                placeholder='Last Name'
                rules={{ required: true }}
                id='name'
                name='lastName'
                className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                control={control}
                as={<input />}
              />
              <ErrorMessage
                errors={errors}
                name='lastName'
                message='Lastname is required'
                render={({ message }) => (
                  <p className='text-red-500 mt-2 text-sm'>{message}</p>
                )}
              />
            </div>
          </div>
        </div>

        <div>
          <label
            className='block text-sm font-medium leading-6 text-gray-900'
            htmlFor='tel'
          >
            Phone Number
          </label>
          <div className='mt-2'>
            <Controller
              id='tel'
              name='phoneNumber'
              type='tel'
              rules={{ required: false }}
              placeholder='+1 (555) 987-6543'
              className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
              control={control}
              as={<input />}
            />
            <ErrorMessage
              errors={errors}
              name='phoneNumber'
              message='Phonenumber is required'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>

        <div>
          <label
            className='block text-sm font-medium leading-6 text-gray-900'
            htmlFor='name'
          >
            Website
          </label>
          <div className='mt-2'>
            <Controller
              placeholder='Partner Name'
              id='name'
              name='website'
              className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
              control={control}
              as={<input />}
            />
            <ErrorMessage
              errors={errors}
              name='website'
              message='Website is required'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>

        <div>
          <label
            className='block text-sm font-medium leading-6 text-gray-900'
            htmlFor='email'
          >
            Email Address
          </label>
          <div className='mt-2'>
            <Controller
              id='email'
              name='email'
              type='email'
              rules={{ required: true }}
              placeholder='johndoe@gmail.com'
              className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
              control={control}
              as={<input />}
            />
            <ErrorMessage
              errors={errors}
              name='email'
              message='Email is required'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>

        <div>
          <label
            className='block text-sm font-medium leading-6 text-gray-900'
            htmlFor='password'
          >
            Password
          </label>
          <div className='mt-2'>
            <Controller
              id='password'
              name='password'
              type='password'
              rules={{ required: true }}
              placeholder='Password'
              className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
              control={control}
              as={<input />}
            />
            <ErrorMessage
              errors={errors}
              name='password'
              message='Password is required'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>

        <button
          className='md:mt-0 mt-3 inline-flex w-full justify-center rounded-md bg-sky-600 px-3 py-3.5 text-center text-base font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600'
          disabled={signupPartner.isLoading}
          type='submit'
        >
          {signupPartner.isLoading ? <Loader /> : null}
          Become a Partner
        </button>
      </form>
    </div>
  );
};
