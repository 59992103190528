import React from 'react';
import { useParams } from 'react-router-dom';
import { RequestTable } from '../components/properties-components/requests/RequestTable';
import { Layout } from '../components/Layout';
import { useGetPropertyRequests } from '../hooks/useGetPropertyRequests';

const NotFound = () => {
  return (
    <div className='text-center mt-10'>
      <svg
        className='mx-auto h-12 w-12 text-gray-400'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'
        aria-hidden='true'
      >
        <path
          vectorEffect='non-scaling-stroke'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z'
        />
      </svg>
      <h3 className='mt-2 text-sm font-semibold text-gray-900'>No Inquiries</h3>
      <p className='mt-1 text-sm text-gray-500'>
        No inquiries found for this listing.
      </p>
    </div>
  );
};

const Request = () => {
  const { id } = useParams();
  const { requests, isLoading } = useGetPropertyRequests({ id });

  if (requests?.length === 0) {
    return (
      <Layout loading={isLoading}>
        <NotFound />
      </Layout>
    );
  }

  return (
    <Layout loading={isLoading}>
      <div className='sm:flex sm:items-center pb-7 border-b mb-10'>
        <div className='sm:flex-auto'>
          <h1 className='text-base font-semibold leading-6 text-gray-900'>
            Enquiries For
          </h1>
          <p className='mt-2 text-sm text-gray-700'>
            {requests?.[0]?.property?.title}
          </p>
        </div>
      </div>
      <RequestTable requests={requests} />
    </Layout>
  );
};

export default Request;
