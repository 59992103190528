import { NotFound } from '../../NotFound';
import { PropertyItem } from './propertyItem';

export const PropertyList = ({ properties }) => {
  if (properties?.length === 0) {
    return (
      <NotFound
        title='No Listings'
        subTitle='Get Started by adding new listing.'
      />
    );
  }

  return (
    <div className='mt-10  sm:block'>
      <div>
        <div className='mt-2 flex flex-col'>
          <div className='min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg'>
            <table className='min-w-full divide-y divide-gray-200'>
              <thead>
                <tr>
                  <th
                    className='bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900'
                    scope='col'
                  >
                    Name
                  </th>
                  <th
                    className='bg-gray-50 whitespace-nowrap px-6 py-3 text-right text-sm font-semibold text-gray-900'
                    scope='col'
                  >
                    Property Type
                  </th>
                  <th
                    className='  bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900 md:block'
                    scope='col'
                  >
                    Status
                  </th>
                  <th
                    className='bg-gray-50 whitespace-nowrap px-6 py-3 text-right text-sm font-semibold text-gray-900'
                    scope='col'
                  >
                    Published By
                  </th>
                  <th
                    className='bg-gray-50 whitespace-nowrap px-6 py-3 text-right text-sm font-semibold text-gray-900'
                    scope='col'
                  >
                    Published On
                  </th>
                  <th
                    className='bg-gray-50 whitespace-nowrap px-6 py-3 text-right text-sm font-semibold text-gray-900'
                    scope='col'
                  >
                    Enquiries Count
                  </th>
                  <th
                    className='bg-gray-50 whitespace-nowrap px-6 py-3 text-right text-sm font-semibold text-gray-900'
                    scope='col'
                  ></th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {properties?.map((property) => (
                  <PropertyItem key={property._id} property={property} />
                ))}
              </tbody>
            </table>

            {/* <nav
              className='flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6'
              aria-label='Pagination'
            >
              <div className='hidden sm:block'>
                <p className='text-sm text-gray-700'>
                  Showing <span className='font-medium'>1</span> to{' '}
                  <span className='font-medium'>10</span> of{' '}
                  <span className='font-medium'>20</span> results
                </p>
              </div>
              <div className='flex flex-1 justify-between gap-x-3 sm:justify-end'>
                <div className='relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400'>
                  Previous
                </div>
                <div className='relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400'>
                  Next
                </div>
              </div>
            </nav> */}
          </div>
        </div>
      </div>
    </div>
  );
};
