import React from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { DashboardCardLoader } from '../components/shimmers/dashboardCards';
import DashboardActivity from '../components/Activity/dashboardActivity';
import { useGetProfile } from '../hooks/useGetProfile';
import { Layout } from '../components/Layout';

const getUserDashboard = async () => {
  try {
    const res = await axios.get('/profile/dashboard/count');
    return res.data;
  } catch (err) {
    throw err.response;
  }
};

const MyProfile = () => {
  const { data: profile } = useGetProfile();
  const { data, isLoading } = useQuery('get-user-dashboard', getUserDashboard);

  const dashboardData = [
    {
      number: data?.propertiesCount,
      url: `/listings?state=active`,
      title: 'Total Properties',
    },
    // {
    //   number: data?.activeCount,
    //   url: `/listings?state=active`,
    //   title: 'Active Properties',
    // },
    // {
    //   number: data?.inActiveCount,
    //   url: `/listings?state=inActive`,
    //   title: 'In Active Properties',
    // },
    {
      number: data?.requestsCount,
      url: '/listings?state=active',
      title: 'Total Requests',
    },
  ];

  const name = profile?.name
    ? profile?.name
    : profile?.firstName && profile?.lastName
    ? `${profile?.firstName} ${profile?.lastName}`
    : '';

  return (
    <Layout>
      <div className='space-y-7'>
        <h2 className='text-base capitalize font-semibold leading-6 text-gray-900'>
          Welcome Back, {name}
        </h2>
        <div className='grid sm:grid-cols-4 gap-x-6'>
          {isLoading ? (
            <>
              <DashboardCardLoader />
              <DashboardCardLoader />
              <DashboardCardLoader />
              <DashboardCardLoader />
            </>
          ) : (
            <>
              {dashboardData.map((item) => (
                <div
                  key={item.title}
                  className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'
                >
                  <div className='mb-0 col px-0 mt-2 text-capitalize'>
                    <h6 className='truncate text-sm font-medium text-gray-500'>
                      {item.title}
                    </h6>
                    <h3 className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>
                      {item.number}
                    </h3>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>

      <DashboardActivity />
    </Layout>
  );
};

export default MyProfile;
