import axios from 'axios';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

const getUserProperties = async () => {
  try {
    const res = await axios.get('/property/user/all');
    return res.data;
  } catch (err) {
    throw err?.response?.data;
  }
};

export const useListing = () => {
  const { user } = useSelector((state) => state.user);

  const userId = user?._id;

  const { data, isLoading, error } = useQuery(
    ['LISTING', userId],
    getUserProperties,
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data,
    isLoading,
    error,
  };
};
