import { Fragment, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  Cog6ToothIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  DocumentIcon,
  CalendarIcon,
  UserIcon,
  TableCellsIcon,
  UserGroupIcon,
  ArrowUpRightIcon,
  ArrowDownLeftIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { NavLink, useLocation, useHistory, Link } from 'react-router-dom';
import { Loader } from '../Loader';
import { useDispatch } from 'react-redux';
import { useGetProfile } from '../../hooks/useGetProfile';
import { logout } from '../../features/userAuthentication';

const clusterOwnerRoutes = [
  { name: 'Dashboard', href: '/dashboard', icon: HomeIcon },
  { name: 'Listings', href: '/listings', icon: TableCellsIcon },
  { name: 'Partners', href: '/partners', icon: UserGroupIcon },
  { name: 'Members', href: '/members', icon: UsersIcon },
  { name: 'Referrals', href: '/referrals', icon: ArrowUpRightIcon },
  // {
  //   name: 'Opportunities',
  //   href: '/opportunities',
  //   icon: ArrowDownLeftIcon,
  // },
  { name: 'Events', href: '/events', icon: CalendarIcon },
  { name: 'Enquiries', href: '/enquiries', icon: PhoneIcon },
];

const partnerRoutes = [
  { name: 'Dashboard', href: '/dashboard', icon: CalendarIcon },
  { name: 'Listings', href: '/listings', icon: CalendarIcon },
];

const memberRoutes = [
  { name: 'Dashboard', href: '/dashboard', icon: CalendarIcon },
  { name: 'Listings', href: '/listings', icon: CalendarIcon },

  { name: 'Referrals', href: '/referrals', icon: HomeIcon },
  { name: 'Members', href: '/members', icon: UsersIcon },
  {
    name: 'Opportunities',
    href: '/opportunities',
    icon: DocumentIcon,
  },
  { name: 'Events', href: '/events', icon: CalendarIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const Layout = ({ loading, children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { pathname } = useLocation();
  const { data, isLoading } = useGetProfile();

  const history = useHistory();

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    history.push('/sign-in');
  };

  const navigation =
    data?.userType === 'CLUSTER_OWNER'
      ? clusterOwnerRoutes
      : data?.userType === 'PARTNER'
      ? partnerRoutes
      : memberRoutes ?? [];

  const profilePage =
    data?.userType === 'CLUSTER_OWNER'
      ? '/profile'
      : data?.userType === 'PARTNER'
      ? '/partner/profile'
      : '/member/profile';

  const name = data?.name
    ? data?.name
    : data?.firstName && data?.lastName
    ? `${data?.firstName} ${data?.lastName}`
    : '';

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-50 lg:hidden'
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-900/80' />
          </Transition.Child>

          <div className='fixed inset-0 flex'>
            <Transition.Child
              as={Fragment}
              enter='transition ease-in-out duration-300 transform'
              enterFrom='-translate-x-full'
              enterTo='translate-x-0'
              leave='transition ease-in-out duration-300 transform'
              leaveFrom='translate-x-0'
              leaveTo='-translate-x-full'
            >
              <Dialog.Panel className='relative mr-16 flex w-full max-w-xs flex-1'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-in-out duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='ease-in-out duration-300'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
                    <button
                      type='button'
                      className='-m-2.5 p-2.5'
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className='sr-only'>Close sidebar</span>
                      <XMarkIcon
                        className='h-6 w-6 text-white'
                        aria-hidden='true'
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className='flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4'>
                  <div className='flex h-16 shrink-0 items-center'>
                    <img
                      className='h-8 w-auto'
                      src='https://bdibucket1.s3.eu-west-2.amazonaws.com/Coduq_LOGO_2023.png'
                      alt='Your Company'
                    />
                  </div>
                  <nav className='flex flex-1 flex-col'>
                    <ul className='flex flex-1 flex-col gap-y-7'>
                      <li>
                        <ul className='-mx-2 space-y-1'>
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <Link
                                to={item.href}
                                className={classNames(
                                  item.current
                                    ? 'bg-gray-50 text-sky-600'
                                    : 'text-gray-700 hover:text-sky-600 hover:bg-gray-50',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}
                              >
                                <item.icon
                                  className={classNames(
                                    item.current
                                      ? 'text-sky-600'
                                      : 'text-gray-400 group-hover:text-sky-600',
                                    'h-6 w-6 shrink-0'
                                  )}
                                  aria-hidden='true'
                                />
                                {item.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>

                      {/* <li className='mt-auto'>
                        <a
                          href='#'
                          className='group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-sky-600'
                        >
                          <Cog6ToothIcon
                            className='h-6 w-6 shrink-0 text-gray-400 group-hover:text-sky-600'
                            aria-hidden='true'
                          />
                          Settings
                        </a>
                      </li> */}
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div className='hidden  lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col'>
        <div className='flex grow  flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4'>
          <div className='flex h-16 shrink-0 items-center'>
            <img
              className='h-8 w-auto'
              src='https://bdibucket1.s3.eu-west-2.amazonaws.com/Coduq_LOGO_2023.png'
              alt='Your Company'
            />
          </div>
          <nav className='flex flex-1 flex-col'>
            <ul className='flex flex-1 flex-col gap-y-7'>
              <li>
                {!isLoading ? (
                  <ul className='-mx-2 space-y-1'>
                    {navigation?.map((item) => (
                      <li key={item.name}>
                        <NavLink
                          to={item.href}
                          className={classNames(
                            pathname === item.href
                              ? 'bg-gray-50 text-sky-600'
                              : 'text-gray-700 hover:text-sky-600 hover:bg-gray-50',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              pathname === item.href
                                ? 'text-sky-600'
                                : 'text-gray-400 group-hover:text-sky-600',
                              'h-6 w-6 shrink-0'
                            )}
                            aria-hidden='true'
                          />
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </li>

              {/* <li className='mt-auto'>
                <div className='group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-sky-600'>
                  <Cog6ToothIcon
                    className='h-6 w-6 shrink-0 text-gray-400 group-hover:text-sky-600'
                    aria-hidden='true'
                  />
                  Settings
                </div>
              </li> */}
            </ul>
          </nav>
        </div>
      </div>

      <div className='lg:pl-72'>
        <div className='sticky top-0 z-40 lg:mx-auto lg:max-w-7xl '>
          <div className='flex h-16 lg:px-8 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6  lg:shadow-none'>
            <button
              type='button'
              className='-m-2.5 p-2.5 text-gray-700 lg:hidden'
              onClick={() => setSidebarOpen(true)}
            >
              <span className='sr-only'>Open sidebar</span>
              <Bars3Icon className='h-6 w-6' aria-hidden='true' />
            </button>

            {/* Separator */}
            <div
              className='h-6 w-px bg-gray-200 lg:hidden'
              aria-hidden='true'
            />

            <div className='flex flex-1 gap-x-4 self-stretch lg:gap-x-6'>
              <div className='flex flex-1 justify-end items-center gap-x-4 lg:gap-x-6'>
                <Menu as='div' className='relative'>
                  <Menu.Button className='-m-1.5 flex items-center p-1.5'>
                    <span className='sr-only'>Open user menu</span>
                    {data?.imageUrl ? (
                      <img
                        className='h-8 w-8 rounded-full object-cover bg-gray-50'
                        src={data?.imageUrl}
                        alt=''
                      />
                    ) : (
                      <div className='h-8 w-8 flex items-center justify-center rounded-full bg-gray-50'>
                        <UserIcon className='h-4 w-4 text-sky-400' />
                      </div>
                    )}

                    <span className='hidden lg:flex lg:items-center'>
                      <span
                        className='ml-4 text-sm capitalize font-semibold leading-6 text-gray-900'
                        aria-hidden='true'
                      >
                        {name}
                      </span>
                      <ChevronDownIcon
                        className='ml-2 h-5 w-5 text-gray-400'
                        aria-hidden='true'
                      />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to={profilePage}
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-900'
                            )}
                          >
                            Your Profile
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={handleLogout}
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 cursor-pointer text-sm leading-6 text-gray-900'
                            )}
                          >
                            Sign out
                          </div>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>

        <main className='py-10'>
          <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
            {loading || isLoading ? (
              <div className='flex items-center mt-4 justify-center'>
                <Loader className=' h-10 w-10' />
              </div>
            ) : (
              children
            )}
          </div>
        </main>
      </div>
    </div>
  );
};
