import React from 'react';
import { Layout } from '../../components/Layout';
import { useEvents } from '../../hooks/useEvents';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
import { NotFound } from '../../components/NotFound';

export const Events = () => {
  const { user, isLoading: isloading } = useSelector((state) => state.user);

  const params =
    user?.userType === 'MEMBER'
      ? {
          leadTo: user?._id,
          leadFrom: user?._id,
        }
      : {
          leadTo: user?._id,
          leadFrom: user?._id,
        };

  const { events, isLoading } = useEvents({ params, enabled: !isloading });
  return (
    <Layout loading={isLoading}>
      <div className='sm:flex sm:items-center pb-7 border-b mb-10'>
        <div className='sm:flex-auto'>
          <h1 className='text-base font-semibold leading-6 text-gray-900'>
            Events
          </h1>
          <p className='mt-2 text-sm text-gray-700'>
            A list of all the events in your account.
          </p>
        </div>
      </div>
      {events?.length === 0 ? (
        <NotFound
          title='No Events'
          subTitle={`You haven't been invited to any events Yet!`}
        />
      ) : (
        <div className='mt-10 block'>
          <div>
            <div className='mt-2 flex flex-col'>
              <div className='min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-200'>
                  <thead>
                    <tr>
                      <th
                        className='bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900'
                        scope='col'
                      >
                        Event
                      </th>
                      <th
                        className='bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900'
                        scope='col'
                      >
                        Event Date
                      </th>
                      <th
                        className=' bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900 md:block'
                        scope='col'
                      >
                        From
                      </th>
                      <th
                        className='bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900'
                        scope='col'
                      >
                        To
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {events?.map((event) => (
                      <tr key={event._id} className='bg-white'>
                        <td className='w-full max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900'>
                          <Link
                            to={`/referrals/${event?.referalId?._id}`}
                            className='flex'
                          >
                            <div className='bg-gray-100 hidden rounded-full md:flex flex-shrink-0 items-center justify-center h-8 w-8'>
                              <CalendarDaysIcon
                                strokeWidth={2}
                                className='h-5 w-5 flex-shrink-0 text-sky-500 group-hover:text-gray-500'
                                aria-hidden='true'
                              />
                            </div>
                            <div className='group md:ml-3 flex-col flex  truncate text-sm'>
                              <p className='truncate capitalize text-gray-900 group-hover:text-gray-900'>
                                {event.subject}
                              </p>
                              <p className='truncate text-gray-500 group-hover:text-gray-900'>
                                For {event?.referalId?.refId}
                              </p>
                            </div>
                          </Link>
                        </td>
                        <td className='whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500'>
                          <time>
                            {moment.utc(event.eventDate).format('MMM DD, YYYY')}
                          </time>
                        </td>
                        <td className='whitespace-nowrap px-6 py-4 text-left text-sm text-gray-500'>
                          {event?.leadFrom?.firstName &&
                          event?.leadFrom?.lastName
                            ? `${event?.leadFrom?.firstName} ${event?.leadFrom?.lastName}`
                            : event?.leadFrom?.name ?? '-'}
                        </td>
                        <td className='whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500'>
                          {event?.leadTo?.firstName} {event?.leadTo?.lastName}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Pagination */}
                {/* <nav
                className='flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6'
                aria-label='Pagination'
              >
                <div className='hidden sm:block'>
                  <p className='text-sm text-gray-700'>
                    Showing <span className='font-medium'>1</span> to{' '}
                    <span className='font-medium'>10</span> of{' '}
                    <span className='font-medium'>20</span> results
                  </p>
                </div>
                <div className='flex flex-1 justify-between gap-x-3 sm:justify-end'>
                  <div className='relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400'>
                    Previous
                  </div>
                  <div className='relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400'>
                    Next
                  </div>
                </div>
              </nav> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};
