import { useState } from 'react';
import { Layout } from '../../components/Layout';
import { ArrowUpRightIcon, PaperClipIcon } from '@heroicons/react/24/outline';
import { HomeIcon } from '@heroicons/react/24/solid';
import { useSingleReferral } from '../../hooks/useSingleReferral';
import { useParams, Link } from 'react-router-dom';
import { numberWithCommas } from '../../utils/numberWithCommas';
import moment from 'moment';
import { useStatusChange } from '../../hooks/useStatusChange';
import { Loader } from '../../components/Loader';
import classNames from 'classnames';
import { Modal } from '../../components/Modal';
import { CreateEvent } from '../Create-Event';
import { Switch } from '@headlessui/react';
import { useSelector } from 'react-redux';
import { RejectNotes } from '../../components/RejectNotes';
import { EventsList } from '../../components/Events';

const statusStyles = {
  ACCEPTED: 'bg-green-100 text-green-800',
  PENDING: 'bg-yellow-100 text-yellow-800',
  EXPIRED: 'bg-gray-100 text-gray-800',
  REJECTED: 'bg-red-100 text-red-800',
};

export default function Refferal() {
  const { id } = useParams();
  const { isLoading, referral } = useSingleReferral({ id });
  const [enabled, setEnabled] = useState(false);

  const [currentStatus, setCurrentStatus] = useState(false);

  const { user } = useSelector((state) => state.user);

  const [openModal, setOpenModal] = useState(false);

  const [rejectModal, setRejectModal] = useState(false);

  const [underTakingModal, setUnderTakingModal] = useState(false);

  const [viewEvents, setViewEvents] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };

  const { mutation } = useStatusChange();

  const handleStatusChange = (status) => {
    setCurrentStatus(status);
    const payload = {
      id,
      status,
    };
    mutation.mutateAsync(payload, {
      onSuccess: () => {
        if (referral.undertakingRequired) {
          setUnderTakingModal(false);
        }
      },
    });
  };

  const pages = [
    { name: 'Referrals', href: '/referrals', current: false },
    { name: referral?.subject, current: true },
  ];

  return (
    <Layout loading={isLoading}>
      <Modal isOpen={openModal} onClose={handleClose}>
        <CreateEvent lead={referral} onClose={handleClose} />
      </Modal>
      <Modal
        isOpen={rejectModal}
        onClose={() => {
          setRejectModal(false);
        }}
      >
        <RejectNotes
          lead={referral}
          onClose={() => {
            setRejectModal(false);
          }}
        />
      </Modal>
      <Modal
        isOpen={underTakingModal}
        onClose={() => {
          setUnderTakingModal(false);
        }}
      >
        <div>
          <h1 className='text-lg font-medium capitalize leading-6 text-gray-900'>
            Undertaking For {referral?.subject}
          </h1>

          {referral?.attachment?.length ? (
            <div className='py-4 sm:col-span-2 sm:px-0'>
              <dd className='text-sm text-gray-900'>
                <ul className='divide-y divide-gray-100 rounded-md border border-gray-200'>
                  <li className='flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6'>
                    <div className='flex w-0 flex-1 items-center'>
                      <PaperClipIcon
                        className='h-5 w-5 flex-shrink-0 text-gray-400'
                        aria-hidden='true'
                      />
                      <div className='ml-4 flex min-w-0 flex-1 gap-2'>
                        <span className='truncate font-medium'>
                          Undertaking Document
                        </span>
                      </div>
                    </div>
                    <div className='ml-4 flex-shrink-0'>
                      <a
                        rel='noreferrer'
                        target='_blank'
                        href={referral?.attachment[0]}
                        className='font-medium text-sky-600 hover:text-sky-500'
                      >
                        View
                      </a>
                    </div>
                  </li>
                </ul>
              </dd>
            </div>
          ) : null}
          <div className='border-t border-gray-100 py-4 sm:col-span-2 sm:px-0'>
            <Switch.Group
              as='div'
              className='flex items-center justify-between'
            >
              <span className='flex flex-grow flex-col'>
                <Switch.Label
                  as='span'
                  className='text-sm font-medium leading-6 text-gray-900'
                  passive
                >
                  Undertaking Required
                </Switch.Label>
                <Switch.Description as='span' className='text-sm text-gray-500'>
                  Agree to the attached terms and conditions.
                </Switch.Description>
              </span>
              <Switch
                checked={enabled}
                onChange={setEnabled}
                className={classNames(
                  enabled ? 'bg-sky-600' : 'bg-gray-200',
                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2'
                )}
              >
                <span
                  aria-hidden='true'
                  className={classNames(
                    enabled ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                  )}
                />
              </Switch>
            </Switch.Group>
            <div className='mt-5 space-y-3 sm:space-y-0 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
              <button
                type='button'
                onClick={() => setUnderTakingModal(false)}
                className='rounded-md bg-white px-3 py-2 text-sm  w-full font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
              >
                Cancel
              </button>
              <button
                type='button'
                onClick={() => {
                  handleStatusChange('ACCEPTED');
                }}
                disabled={mutation.isLoading || !enabled}
                className='rounded-md inline-flex items-center justify-center disabled:bg-slate-400 bg-slate-900 px-3 py-2 text-sm w-full font-semibold text-white shadow-sm hover:bg-slate-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-800'
              >
                {mutation.isLoading && currentStatus === 'ACCEPTED' ? (
                  <Loader className='mr-2' />
                ) : null}
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <EventsList
        isOpen={viewEvents}
        onClose={() => setViewEvents(false)}
        referral={referral}
      />

      <div className='relative isolate '>
        <div>
          <nav className='flex pb-4 mb-10 border-b' aria-label='Breadcrumb'>
            <ol className='flex items-center space-x-4'>
              <li>
                <div>
                  <Link
                    to='/dashboard'
                    className='text-gray-400 hover:text-gray-500'
                  >
                    <HomeIcon
                      className='h-5 w-5 flex-shrink-0'
                      aria-hidden='true'
                    />
                    <span className='sr-only'>Home</span>
                  </Link>
                </div>
              </li>
              {pages.map((page) => (
                <li key={page.name}>
                  <div className='flex items-center'>
                    <svg
                      className='h-5 w-5 flex-shrink-0 text-gray-300'
                      fill='currentColor'
                      viewBox='0 0 20 20'
                      aria-hidden='true'
                    >
                      <path d='M5.555 17.776l8-16 .894.448-8 16-.894-.448z' />
                    </svg>
                    {page.current ? (
                      <div className='ml-4 text-sm font-semibold text-gray-900 hover:text-gray-700'>
                        {page.name}
                      </div>
                    ) : (
                      <Link
                        to={page.href}
                        className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                      >
                        {page.name}
                      </Link>
                    )}
                  </div>
                </li>
              ))}
            </ol>
          </nav>
        </div>
        <div>
          <div className='mx-auto border-gray-100 border-b pb-10 flex flex-col md:flex-row   max-w-7xl items-start  space-y-3 justify-between gap-x-8 lg:mx-0 lg:max-w-none'>
            <div className='flex items-center gap-x-6'>
              <img
                src='https://tailwindui.com/img/logos/48x48/tuple.svg'
                alt=''
                className='h-16 w-16 flex-none rounded-full ring-1 ring-gray-900/10'
              />
              <h1>
                <div className='text-sm leading-6 text-gray-500'>
                  Referral{' '}
                  <span className='text-gray-700'># {referral?.refId}</span>
                </div>
                <span
                  className={classNames(
                    statusStyles[referral?.status],
                    'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium capitalize'
                  )}
                >
                  {referral?.status}
                </span>
              </h1>
            </div>
            {referral?.status === 'PENDING' &&
            referral?.leadTo?._id === user?._id ? (
              <div className='flex items-center gap-x-4 sm:gap-x-6'>
                <button
                  type='button'
                  onClick={() => {
                    setRejectModal(true);
                  }}
                  className=' text-sm flex items-center justify-center font-semibold leading-6 text-gray-900'
                >
                  Decline
                </button>
                <button
                  type='button'
                  onClick={() => {
                    if (!referral?.undertakingRequired) {
                      handleStatusChange('ACCEPTED');
                    } else {
                      setUnderTakingModal(true);
                    }
                  }}
                  disabled={mutation.isLoading}
                  className='rounded-md inline-flex bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600'
                >
                  {mutation.isLoading && currentStatus === 'ACCEPTED' ? (
                    <Loader className='mr-2' />
                  ) : null}
                  Accept
                </button>
              </div>
            ) : referral?.status === 'ACCEPTED' ? (
              <div className='flex items-center gap-x-4 sm:gap-x-6'>
                {referral?.events?.length &&
                (referral?.leadTo?._id === user?._id ||
                  referral?.leadFrom?._id === user?._id) ? (
                  <button
                    type='button'
                    onClick={() => setViewEvents(true)}
                    className='rounded-md inline-flex px-3 py-2 text-sm font-semibold text-black border shadow-sm'
                  >
                    View Events ({referral.events.length})
                  </button>
                ) : null}

                {referral?.leadFrom?._id === user?._id ? (
                  <button
                    type='button'
                    onClick={() => setOpenModal(true)}
                    className='rounded-md inline-flex bg-slate-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-800'
                  >
                    Create Event
                  </button>
                ) : null}
              </div>
            ) : null}
          </div>
          <div className='pt-7'>
            <div>
              <dl className='grid grid-cols-1 sm:grid-cols-2'>
                <div className='px-4 pb-6 sm:col-span-full border-b sm:px-0'>
                  <dt className='text-sm font-medium leading-6 text-gray-900'>
                    Lead for
                  </dt>
                  <Link
                    className={classNames(
                      'w-max',
                      referral?.status === 'ACCEPTED'
                        ? ''
                        : 'pointer-events-none'
                    )}
                    to={`/property/${referral?.listingId?.slug}`}
                  >
                    <dd className='mt-1 text-sm leading-6 text-gray-700 sm:mt-2 flex items-center space-x-2'>
                      <span>{referral?.listingId?.title}</span>
                      {referral?.status === 'ACCEPTED' ? (
                        <ArrowUpRightIcon
                          strokeWidth={2.5}
                          className='h-5 w-5 text-sky-600'
                        />
                      ) : null}
                    </dd>
                  </Link>
                </div>
                <div className='px-4 py-6 sm:col-span-1 sm:px-0'>
                  <dt className='text-sm font-medium leading-6 text-gray-900'>
                    Lead From
                  </dt>
                  <dd className='mt-1 text-sm leading-6 text-gray-700 sm:mt-2'>
                    {referral?.leadFrom?.name ?? referral?.email}
                  </dd>
                </div>
                <div className='px-4 py-6 sm:col-span-1 sm:px-0'>
                  <dt className='text-sm font-medium leading-6 text-gray-900'>
                    Lead To
                  </dt>
                  <dd className='mt-1 text-sm leading-6 text-gray-700 sm:mt-2'>
                    {referral?.leadTo?.firstName} {referral?.leadTo?.lastName}
                  </dd>
                </div>

                <div className='border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0'>
                  <dt className='text-sm font-medium leading-6 text-gray-900'>
                    Email address
                  </dt>
                  <dd className='mt-1 text-sm leading-6 text-gray-700 sm:mt-2'>
                    {referral?.email}
                  </dd>
                </div>
                <div className='border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0'>
                  <dt className='text-sm font-medium leading-6 text-gray-900'>
                    Commission expectation
                  </dt>
                  <dd className='mt-1 text-sm leading-6 text-gray-700 sm:mt-2'>
                    {numberWithCommas(referral?.commission)}
                  </dd>
                </div>
                <div className='border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0'>
                  <dt className='text-sm font-medium leading-6 text-gray-900'>
                    Office Number
                  </dt>
                  <dd className='mt-1 text-sm leading-6 text-gray-700 sm:mt-2'>
                    {referral?.officePhone}
                  </dd>
                </div>
                <div className='border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0'>
                  <dt className='text-sm font-medium leading-6 text-gray-900'>
                    Phone Number
                  </dt>
                  <dd className='mt-1 text-sm leading-6 text-gray-700 sm:mt-2'>
                    {referral?.cellPhone}
                  </dd>
                </div>
                <div className='border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0'>
                  <dt className='text-sm font-medium leading-6 text-gray-900'>
                    Created On
                  </dt>
                  <dd className='mt-1 text-sm leading-6 text-gray-700 sm:mt-2'>
                    {moment.utc(referral?.createdAt).format('MMM DD, YYYY')}{' '}
                  </dd>
                </div>
                <div className='border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0'>
                  <dt className='text-sm font-medium leading-6 text-gray-900'>
                    Expires On
                  </dt>
                  <dd className='mt-1 text-sm leading-6 text-gray-700 sm:mt-2'>
                    {moment.utc(referral?.expiresOn).format('MMM DD, YYYY')}
                  </dd>
                </div>

                <div className='border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0'>
                  <dt className='text-sm font-medium leading-6 text-gray-900'>
                    Organization
                  </dt>
                  <dd className='mt-1 text-sm capitalize leading-6 text-gray-700 sm:mt-2'>
                    {referral?.organization}
                  </dd>
                </div>

                <div className='border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0'>
                  <dt className='text-sm font-medium leading-6 text-gray-900'>
                    Notes
                  </dt>
                  <dd className='mt-1 text-sm leading-6 text-gray-700 sm:mt-2'>
                    {referral?.notes}
                  </dd>
                </div>
                {referral?.rejectNotes ? (
                  <div className='border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0'>
                    <dt className='text-sm font-medium leading-6 text-gray-900'>
                      Rejection Notes
                    </dt>
                    <dd className='mt-1 text-sm leading-6 text-red-500 italic sm:mt-2'>
                      {referral?.rejectNotes}
                    </dd>
                  </div>
                ) : null}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
