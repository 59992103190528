import React, { useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import Footer from '../components/global-components/footer';
import notifications from '../components/notifications';
import classNames from 'classnames';
import { Switch } from '@headlessui/react';
import { Loader } from '../components/Loader';
import { Link } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message';
import Navbar from '../components/global-components/navbar';
import { ScrollToTop } from '../components/ScrollToTop';

export default function ContactForm() {
  const [agreed, setAgreed] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    axios
      .post('/contactus', data)
      .then(() => {
        setLoading(false);
        notifications.success('Success', 'Message Sent Successfully');
        reset();
      })
      .catch((err) => {
        setLoading(false);
        notifications.error('Error', 'Try Again Later');
      });
  };

  return (
    <div>
      <ScrollToTop />
      <Navbar />
      <div className='isolate  bg-white px-6 py-24 sm:py-32 lg:px-8'>
        <div
          className='absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]'
          aria-hidden='true'
        >
          <div
            className='relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]'
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        <div className='mx-auto max-w-2xl text-center'>
          <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
            Get in touch
          </h2>
          <p className='mt-2 text-lg leading-8 text-gray-600'>
            We’re always happy to hear from you. Leave your message here and we
            will be in touch shortly
          </p>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='mx-auto mt-16 max-w-xl sm:mt-20'
        >
          <div className='grid grid-cols-1  gap-y-6 sm:grid-cols-1'>
            <div>
              <label
                htmlFor='first-name'
                className='block text-sm font-semibold leading-6 text-gray-900'
              >
                Name *
              </label>
              <div className='mt-2.5'>
                <input
                  type='text'
                  id='first-name'
                  autoComplete='given-name'
                  name='name'
                  ref={register({ required: true })}
                  className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
                <ErrorMessage
                  errors={errors}
                  name='name'
                  message='Name is required'
                  render={({ message }) => (
                    <p className='text-red-500 mt-2 text-sm'>{message}</p>
                  )}
                />
              </div>
            </div>

            <div className='sm:col-span-2'>
              <label
                htmlFor='email'
                className='block text-sm font-semibold leading-6 text-gray-900'
              >
                Email *
              </label>
              <div className='mt-2.5'>
                <input
                  type='email'
                  name='email'
                  ref={register({ required: true })}
                  id='email'
                  autoComplete='email'
                  className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
                <ErrorMessage
                  errors={errors}
                  name='email'
                  message='Email is required'
                  render={({ message }) => (
                    <p className='text-red-500 mt-2 text-sm'>{message}</p>
                  )}
                />
              </div>
            </div>

            <div className='sm:col-span-2'>
              <label
                htmlFor='phoneNumber'
                className='block text-sm font-semibold leading-6 text-gray-900'
              >
                Phone number *
              </label>
              <div className='mt-2.5'>
                <input
                  type='phoneNumber'
                  name='phoneNumber'
                  ref={register({ required: true })}
                  id='phoneNumber'
                  autoComplete='phoneNumber'
                  className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
                <ErrorMessage
                  errors={errors}
                  name='phoneNumber'
                  message='Phone number is required'
                  render={({ message }) => (
                    <p className='text-red-500 mt-2 text-sm'>{message}</p>
                  )}
                />
              </div>
            </div>

            <div className='sm:col-span-2'>
              <label
                htmlFor='message'
                className='block text-sm font-semibold leading-6 text-gray-900'
              >
                Message *
              </label>
              <div className='mt-2.5'>
                <textarea
                  name='message'
                  id='message'
                  rows={4}
                  ref={register({ required: true })}
                  className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                  defaultValue={''}
                />
                <ErrorMessage
                  errors={errors}
                  name='message'
                  message='Message is required'
                  render={({ message }) => (
                    <p className='text-red-500 mt-2 text-sm'>{message}</p>
                  )}
                />
              </div>
            </div>
            <Switch.Group as='div' className='flex gap-x-4 sm:col-span-2'>
              <div className='flex h-6 items-center'>
                <Switch
                  checked={agreed}
                  onChange={setAgreed}
                  className={classNames(
                    agreed ? 'bg-indigo-600' : 'bg-gray-200',
                    'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  )}
                >
                  <span className='sr-only'>Agree to policies</span>
                  <span
                    aria-hidden='true'
                    className={classNames(
                      agreed ? 'translate-x-3.5' : 'translate-x-0',
                      'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out'
                    )}
                  />
                </Switch>
              </div>
              <Switch.Label className='text-sm leading-6 text-gray-600'>
                By selecting this, you agree to our{' '}
                <Link href='/privacy' className='font-semibold text-indigo-600'>
                  privacy&nbsp;policy
                </Link>
                .
              </Switch.Label>
            </Switch.Group>
          </div>
          <div className='mt-10'>
            <button
              type='submit'
              disabled={!agreed || loading}
              className='items-center disabled:bg-indigo-400 justify-center w-full flex  rounded-md bg-indigo-600 px-3.5 py-3.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            >
              {loading ? <Loader /> : null} Let's talk
            </button>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
}
