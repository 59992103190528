export const getOptionsByValue = (name, options) =>
  options?.find((option) => option.value === name)?.options;

export const getSelectedOption = (options, categoryDefault, optionName) =>
  getOptionsByValue(optionName, options)?.find(
    (category) => category.value === categoryDefault
  );

function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

export const formattedPartners = (partners) =>
  partners?.map((partner) => ({
    ...partner,
    label: `${partner?.firstName} ${partner?.lastName}`,
    value: partner._id,
  })) ?? [];

export const formatOptionLabel = (data) => {
  const { icon, imageUrl, label } = data;

  return (
    <div class='flex items-center'>
      <div className='h-9 w-9 rounded-full bg-gray-50 flex items-center justify-center'>
        <img
          className={`object-cover rounded-full h-7 w-7 `}
          src={icon ?? imageUrl}
          alt=''
        />
      </div>

      <h6 className='text-sm pl-2'>{capitalizeFirstLetter(label)}</h6>
    </div>
  );
};

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

export const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data?.label}</span>
    <span style={groupBadgeStyles}>{data?.options?.length}</span>
  </div>
);

export const GetClassOptions = (options) => {
  const b = getOptionsByValue('class b', options);
  const c = getOptionsByValue('class c', options);
  const e = getOptionsByValue('class e', options);
  const f = getOptionsByValue('class f', options);

  const classTypes = [
    {
      label: 'Class B',
      options: b,
    },
    {
      label: 'Class C',
      options: c,
    },
    {
      label: 'Class E',
      options: e,
    },
    {
      label: 'Class F',
      options: f,
    },
  ];
  return classTypes;
};

export const GetTypeOptions = (options) => {
  const others = getOptionsByValue('others', options);
  const offices = getOptionsByValue('offices', options);
  const retail = getOptionsByValue('retail', options);
  const residential = getOptionsByValue('residential', options);
  const industrial = getOptionsByValue('industrial', options);
  const commercialLand = getOptionsByValue('commercial land', options);
  const licensedAndLeisure = getOptionsByValue('licensed & leisure', options);

  const typeOptions = [
    {
      label: 'Industrial',
      options: industrial,
    },
    {
      label: 'Retail',
      options: retail,
    },
    {
      label: 'Licensed & Leisure',
      options: licensedAndLeisure,
    },
    {
      label: 'Offices',
      options: offices,
    },
    {
      label: 'Commercial Land',
      options: commercialLand,
    },
    {
      label: 'Residential',
      options: residential,
    },
    {
      label: 'Others',
      options: others,
    },
  ];
  return typeOptions;
};
