import React from 'react';
import { MemberSignupForm } from '../../components/MemberSignupForm';
import { Layout } from '../../components/Layout';
import { useGetProfile } from '../../hooks/useGetProfile';

export const MemberProfile = () => {
  const { data, isLoading } = useGetProfile();

  const successAction = () => {};

  return (
    <Layout loading={isLoading}>
      <div className='mx-auto w-full max-w-[500px] lg:w-[600px]'>
        <MemberSignupForm
          isEditing
          defaultValues={data}
          setIsSuccess={successAction}
        />
      </div>
    </Layout>
  );
};
