import React from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox';

import '@reach/combobox/styles.css';
import { useFormContext } from 'react-hook-form';

export const LocationSuggestion = ({
  placeholderValue,
  defaultValue,
  propertyValue,
  inputClassName,
}) => {
  const { setValue: setValueForm } = useFormContext();
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    defaultValue,
  });
  const handleInput = async (e) => {
    setValue(e.target.value);
    setValueForm(propertyValue, e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address);
    setValueForm(propertyValue, address);
  };

  return (
    <Combobox onSelect={handleSelect} aria-labelledby='demo'>
      <ComboboxInput
        value={value}
        placeholder={placeholderValue}
        onChange={handleInput}
        disabled={!ready}
        className={`className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6`}
      />
      <ComboboxPopover>
        <ComboboxList className='divide-y'>
          {status === 'OK' &&
            data.map(({ place_id, description }) => (
              <div
                style={{
                  zIndex: 1000000000,
                  position: 'relative',
                }}
                key={place_id}
                className='bg-white '
              >
                <ComboboxOption
                  className='py-3 font- text-xs font-medium'
                  value={description}
                />
              </div>
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};
