import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import notifications from '../notifications';
import { Loader } from '../Loader';
import { ErrorMessage } from '@hookform/error-message';
import { useState } from 'react';

const users = [
  { id: 'CLUSTER_OWNER', title: 'Cluster Owner' },
  { id: 'PARTNER', title: 'Partner' },
  { id: 'MEMBER', title: 'Member' },
];

export const ForgotPasswordForm = ({ setIsSuccess }) => {
  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: {
      type: 'CLUSTER_OWNER',
    },
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    const origin = window.origin;
    setLoading(true);

    const payload = {
      ...data,
      redirectUrl: `${origin}/reset-password`,
    };
    axios
      .post('/profile/forgot-password', payload)
      .then(() => {
        setLoading(false);
        setIsSuccess(true);
      })
      .catch(() => {
        setLoading(false);
        notifications.error('Error', 'Try Again Later!');
      });
  };

  return (
    <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
      <form className='space-y-6' onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label for='email' class='block text-sm font-medium text-gray-700'>
            Email Address
          </label>
          <div className='mt-2'>
            <input
              type='email'
              name='email'
              placeholder='Enter Email'
              className='block w-full py-2.5 rounded-md border-gray-300 shadow-sm focus:border-sky-400 focus:ring-sky-400 sm:text-sm'
              ref={register({ required: true })}
            />

            <ErrorMessage
              name='email'
              errors={errors}
              message='Email is required'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>
        <div>
          <p className='text-sm text-gray-500'>
            What type of user are you logging in as?
          </p>
          <fieldset className='mt-4'>
            <legend className='sr-only'>Notification method</legend>
            <div className='space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0'>
              {users.map((user) => (
                <div key={user.id} className='flex items-center'>
                  <Controller
                    control={control}
                    name='type'
                    render={(field) => (
                      <input
                        value={user.id}
                        id={user.id}
                        {...field}
                        type='radio'
                        onChange={() => field.onChange(user.id)}
                        defaultChecked={field.value === user.id}
                        className='h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-600'
                      />
                    )}
                  />

                  <label
                    htmlFor={user.id}
                    className='ml-3 block text-sm font-medium leading-6 text-gray-900'
                  >
                    {user.title}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>
        <div>
          <button
            className='inline-flex  justify-center rounded-lg text-sm font-semibold py-2.5 px-4 bg-sky-600 text-white hover:bg-sky-700 focus:ring-2 ring-sky-600 ring-offset-2 w-full'
            type='submit'
            disabled={loading}
          >
            {loading === true ? <Loader /> : null}
            Send Reset Link
          </button>
        </div>
      </form>
    </div>
  );
};
