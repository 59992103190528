import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MessageCard } from '../../components/MessageCard';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import axios from 'axios';
import { loginUser } from '../../features/userAuthentication';
import { Loader } from '../../components/Loader';
import { useGetPartners } from '../../hooks/useGetPartners';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

export const ConfirmPartnerVerfication = () => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { handleSubmit, register, errors } = useForm();

  const { id } = useParams();

  const { isLoading, partners, refetch } = useGetPartners({ userId: id });

  useEffect(() => {
    if (partners?.[0]?.isPasswordSet && !isLoading) {
      axios
        .patch(`/partner/verify/${id}`)
        .then((res) => {
          dispatch(loginUser(res.data));
          refetch().then(() => {
            setLoading(false);
          });
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [dispatch, id, isLoading, partners, refetch]);

  const onSubmit = (data) => {
    setLoading(true);
    const payload = {
      password: data.password,
    };
    axios
      .patch(`/partner/verify/${id}`, payload)
      .then((res) => {
        dispatch(loginUser(res.data));
        refetch().then(() => {
          setLoading(false);
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className='h-screen flex flex-col'>
      <div className=' px-4 py-6 border-b sm:px-6 lg:px-8'>
        <div className='relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8'>
          <div className='flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2'>
            <div className='flex-shrink-0 flex items-center'>
              <Link to='/'>
                <img
                  className='mx-auto h-10 w-auto'
                  alt=''
                  src='https://bdibucket1.s3.eu-west-2.amazonaws.com/Coduq_LOGO_2023.png'
                />
              </Link>
            </div>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className='flex items-center flex-1 flex-col justify-center'>
          <Loader className={'h-16 w-16'} />
        </div>
      ) : (
        <div className='flex flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
            {!partners[0]?.isPasswordSet ? (
              <div>
                <img
                  className='mx-auto h-10 w-auto'
                  src='https://bdibucket1.s3.eu-west-2.amazonaws.com/Coduq_LOGO_2023.png'
                  alt='Your Company'
                />
                <div>
                  <h2 className='my-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
                    Create a password
                  </h2>
                  <p className='mt-2 text-center text-sm text-gray-600'>
                    Please create your own password to get started.
                  </p>

                  <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
                    <form
                      className='space-y-6'
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div>
                        <label
                          for='email'
                          class='block text-sm font-medium text-gray-700'
                        >
                          Email
                        </label>
                        <div className='mt-2'>
                          <input
                            className='block disabled:bg-gray-100 w-full py-2.5 rounded-md border-gray-300 shadow-sm focus:border-sky-400 focus:ring-sky-400 sm:text-sm'
                            type='email'
                            placeholder='Enter Password...'
                            name='email'
                            disabled
                            value={partners[0].email}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          for='email'
                          class='block text-sm font-medium text-gray-700'
                        >
                          Password
                        </label>
                        <div className='mt-2'>
                          <input
                            className='block w-full py-2.5 rounded-md border-gray-300 shadow-sm focus:border-sky-400 focus:ring-sky-400 sm:text-sm'
                            type='password'
                            placeholder='Enter Password...'
                            name='password'
                            ref={register({
                              required: 'You must enter a password',
                              minLength: {
                                value: 8,
                                message:
                                  'Password must have at least 8 characters',
                              },
                            })}
                          />
                          <ErrorMessage
                            name='password'
                            errors={errors}
                            render={({ message }) => (
                              <p className='text-red-500 mt-2 text-sm'>
                                {message}
                              </p>
                            )}
                          />
                        </div>
                      </div>

                      <div>
                        <button
                          className='inline-flex  justify-center rounded-lg text-sm font-semibold py-3.5 px-4 bg-sky-600 text-white hover:bg-sky-700 focus:ring-2 ring-sky-600 ring-offset-2 w-full'
                          type='submit'
                          disabled={loading}
                        >
                          {loading ? <Loader className={'mr-2'} /> : null}
                          Create Password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            ) : (
              <MessageCard
                title='You email has been verified successfully!'
                subTitle='You can now use the start using your account.'
                message={'Go To Dashboard'}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
