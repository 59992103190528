import React from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { AgencyMessagesTable } from '../../components/auth-components/agencyMessages/messagesTable';
import { Layout } from '../../components/Layout';

const AgencyMessages = () => {
  const getAgencyMessages = async () => {
    const res = await axios.get('/contactus');
    return res.data?.messages;
  };

  const { data, isLoading } = useQuery('profileMessages', getAgencyMessages, {
    refetchOnWindowFocus: false,
  });

  return (
    <Layout loading={isLoading}>
      <div className='sm:flex sm:items-center pb-7 border-b mb-10'>
        <div className='sm:flex-auto'>
          <h1 className='text-base font-semibold leading-6 text-gray-900'>
            Enquiries
          </h1>
          <p className='mt-2 text-sm text-gray-700'>
            A list of all the Enquiries in your account.
          </p>
        </div>
      </div>
      <AgencyMessagesTable messages={data} />
    </Layout>
  );
};

export default AgencyMessages;
