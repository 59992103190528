import React from 'react';

import { FormatPrice } from '../../common/FormatPrice';

export const PropertyHeader = ({ details }) => {
  const { title, location, isFeatured, category, DDACompliant } = details || {};

  return (
    <div className='pt-7 flex  justify-between '>
      <div>
        <div className='capitalize my-0 flex items-center'>
          <h1 className='text-3xl font-semibold mb-0'>{title}</h1>
        </div>

        <div className='text-gray-400  text-sm pt-1'>
          <div>{location}</div>

          <div className='flex space-x-3 items-center pt-2'>
            {isFeatured && (
              <span className='bg-indigo-600 font-semibold text-xs px-2 py-1 rounded-md text-white uppercase'>
                Featured
              </span>
            )}
            {DDACompliant && (
              <span className='bg-sky-600 font-semibold text-xs px-2 py-1 rounded-md text-white uppercase'>
                DDA Compliant
              </span>
            )}

            <span className='bg-slate-800 font-semibold text-xs px-2 py-1 rounded-md text-white uppercase'>
              {category?.label}
            </span>
          </div>
        </div>
      </div>
      <div>
        <FormatPrice item={details} />
      </div>
    </div>
  );
};
