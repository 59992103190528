import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import classNames from 'classnames';

import { useGetPartners } from '../../../../hooks/useGetPartners';
import {
  formatOptionLabel,
  formattedPartners,
} from '../../../../utils/optionFormatter';
import { Switch } from '@headlessui/react';

export const PropertySetting = () => {
  const { control } = useFormContext();

  const { user } = useSelector((state) => state.user);

  const { isLoading, partners } = useGetPartners({
    clusterId: user?._id,
  });

  return (
    <div className='divide-y'>
      <div className='flex mb-4 flex-col'>
        <div>
          <h4 className='text-base font-semibold leading-6 text-gray-900'>
            Partners
          </h4>
          <div className='my-2 max-w-4xl text-sm text-gray-500'>
            Select partner associated with this property.
          </div>
        </div>
        <Controller
          className='w-100 mt-2'
          name='partnerId'
          control={control}
          defaultValue={false}
          isLoading={isLoading}
          formatOptionLabel={formatOptionLabel}
          options={formattedPartners(partners)}
          as={<Select />}
        />
      </div>
      <div className='flex  py-4 justify-between'>
        <div>
          <h4 className='text-base font-semibold leading-6 text-gray-900'>
            Featured
          </h4>
          <span className='mt-2 max-w-4xl text-sm text-gray-500'>
            List this property as a Featured Property on the homepage.
          </span>
        </div>
        <Controller
          defaultValue={false}
          render={(field) => (
            <Switch
              checked={field.value}
              onChange={(checked) => field.onChange(checked)}
              className={classNames(
                field.value ? 'bg-sky-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2'
              )}
            >
              <span
                className={classNames(
                  field.value ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
              />
            </Switch>
          )}
          name='isFeatured'
          control={control}
        />
      </div>
      <div className='flex  py-4 justify-between'>
        <div>
          <h4 className='text-base font-semibold leading-6 text-gray-900'>
            DDA Compliant (Disability Discrimination Act)
          </h4>
          <span className='mt-2 max-w-4xl text-sm text-gray-500'>
            All reasonable steps have been taken to provide access for disabled
            people.
          </span>
        </div>
        <Controller
          defaultValue={false}
          render={(field) => (
            <Switch
              checked={field.value}
              onChange={(checked) => field.onChange(checked)}
              className={classNames(
                field.value ? 'bg-sky-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2'
              )}
            >
              <span
                className={classNames(
                  field.value ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
              />
            </Switch>
          )}
          name='DDACompliant'
          control={control}
        />
      </div>
      <div className='flex py-4 justify-between'>
        <div>
          <h4 className='text-base font-semibold leading-6 text-gray-900'>
            Publish
          </h4>
          <span className='mt-2 max-w-4xl text-sm text-gray-500'>
            Make this property listing live.
          </span>
        </div>
        <Controller
          defaultValue={false}
          render={(field) => (
            <Switch
              checked={field.value}
              onChange={(checked) => field.onChange(checked)}
              className={classNames(
                field.value ? 'bg-sky-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2'
              )}
            >
              <span
                className={classNames(
                  field.value ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
              />
            </Switch>
          )}
          name='isActive'
          control={control}
        />
      </div>

      {/* <div className='d-flex   align-items-center py-4 border-bottom justify-content-between w-100'>
        <div>
          <h4 className=' mb-2'>Expiry Date (In-Coming)</h4>
          <span className='text-muted'>
            Date on which this property will be hidden.
          </span>
        </div>
        <Controller
          control={control}
          name='expiryDate'
          as={
            <input
              disabled={true}
              type='date'
              className='rounded-0 form-control w-25 custom-input-padding'
            />
          }
        />
      </div> */}
    </div>
  );
};
