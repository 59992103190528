import React, { useState } from 'react';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import notifications from './notifications';
import { Loader } from './Loader';
import { ErrorMessage } from '@hookform/error-message';
import { DashboardModal, useUppy } from '@uppy/react';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import { useGetProfile } from '../hooks/useGetProfile';

const ProfileManagementForm = ({ profile }) => {
  const { handleSubmit, errors, control, setValue, watch } = useForm({
    defaultValues: profile,
    shouldUnregister: false,
  });

  const { refetch } = useGetProfile();

  const [openModal, setOpenModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const imageUrl = watch('imageUrl');

  const localUrl = 'https://stockappy-backend.herokuapp.com';

  const uppyConfig = useUppy(() => {
    return new Uppy({
      restrictions: {
        maxFileSize: 1000000 * 10,
        allowedFileTypes: ['image/*'],
      },
      autoProceed: true,
    }).use(XHRUpload, {
      endpoint: `${localUrl}/image`,
    });
  });

  uppyConfig
    .off('complete', () => {})
    .on('complete', (result) => {
      const body = result.successful.map((file) => file.response?.body.image);
      setValue('imageUrl', body[0]);
      uppyConfig.cancelAll();
    });

  const onSubmit = (data) => {
    setLoading(true);

    const { name, phoneNumber, website, market, description } = data;

    const payload = {
      name,
      phoneNumber,
      website,
      market,
      description,
      imageUrl,
    };

    axios
      .patch('/profile', payload)
      .then((res) => {
        setLoading(false);
        refetch();
        notifications.success('Success', 'Updated Successfully');
      })
      .catch((err) => {
        setLoading(false);
        notifications.error('Error', 'Try Again Later!');
      });
  };

  return (
    <form className='space-y-4' onSubmit={handleSubmit(onSubmit)}>
      <DashboardModal
        uppy={uppyConfig}
        closeAfterFinish
        note='Add a Partner logo here...'
        closeModalOnClickOutside
        open={openModal}
        onRequestClose={() => {
          setOpenModal(false);
        }}
      />
      <div className='grid grid-cols-6 mt-8 gap-6'>
        <div className='flex col-span-full items-center justify-center'>
          <div className=' flex-col flex items-center space-y-3'>
            {imageUrl ? (
              <img
                alt=''
                src={imageUrl}
                className='h-20 w-20 text-gray-300 object-contain bg-gray-100 rounded-full py-1'
              />
            ) : (
              <UserCircleIcon
                className='h-20 w-20 text-gray-300'
                aria-hidden='true'
              />
            )}

            <div className='mt-1'>
              {imageUrl ? (
                <button
                  onClick={() => setOpenModal(true)}
                  type='button'
                  className='rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
                >
                  Change
                </button>
              ) : (
                <button
                  onClick={() => setOpenModal(true)}
                  type='button'
                  className='rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
                >
                  Upload
                </button>
              )}
            </div>
          </div>
        </div>

        <div className='col-span-6'>
          <label
            className='block text-sm font-medium leading-6 text-gray-900'
            htmlFor='companyName'
          >
            Company Name
          </label>
          <div className='mt-2'>
            <Controller
              placeholder='Company Name'
              rules={{ required: true }}
              id='companyName'
              name='name'
              className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
              control={control}
              as={<input />}
            />
            <ErrorMessage
              errors={errors}
              name='companyName'
              message='Company Name is required'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>

        <div className='col-span-6'>
          <label
            className='block text-sm font-medium leading-6 text-gray-900'
            htmlFor='phoneNumber'
          >
            Phone Number
          </label>
          <div className='mt-2'>
            <Controller
              placeholder='Phone Number'
              rules={{ required: true }}
              id='phoneNumber'
              name='phoneNumber'
              className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
              control={control}
              as={<input />}
            />
            <ErrorMessage
              errors={errors}
              name='phoneNumber'
              message='Phone Name is required'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>

        <div className='col-span-6'>
          <label
            className='block text-sm font-medium leading-6 text-gray-900'
            htmlFor='website'
          >
            Website
          </label>
          <div className='mt-2'>
            <Controller
              placeholder='facebook.com'
              rules={{ required: true }}
              id='website'
              name='website'
              className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
              control={control}
              as={<input />}
            />
            <ErrorMessage
              errors={errors}
              name='website'
              message='Phone Name is required'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>

        <div className='col-span-6'>
          <label
            className='block text-sm font-medium leading-6 text-gray-900'
            htmlFor='description'
          >
            Description
          </label>
          <div className='mt-2'>
            <Controller
              control={control}
              name='description'
              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
              placeholder='Tell us about yourself...'
              as={<textarea rows={6} />}
            />
          </div>
        </div>
      </div>
      <div className='col-span-6'>
        <button
          className='rounded-md w-full flex bg-sky-600 px-3 py-3 text-sm items-center justify-center font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600'
          type='submit'
          disabled={loading}
        >
          {loading && <Loader className='mr-2' size='sm' />}
          Update Profile
        </button>
      </div>
    </form>
  );
};

export default ProfileManagementForm;
