import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useRedirect } from '../../hooks/useRedirect';
import notifications from '../../components/notifications';

const ResendForgotPassEmail = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  useRedirect();
  const resendEmail = () => {
    setLoading(true);
    axios
      .get(`profile/resend/email/${id}`)
      .then(() => {
        setLoading(false);
        notifications.success('Success', 'Email Sent Successfully');
      })
      .catch(() => {
        setLoading(false);
        notifications.error('Error', 'Try Again Later!');
      });
  };

  return (
    <div className='row mt-5 d-flex justify-content-center align-items-center'>
      <div className='col-md-6 mt-5'>
        <div className='section-title text-center mt-5'>
          <h2 className='title'>Email Sent...</h2>
          <p>Check your email to get the link.</p>
          <p> Thank you! </p>
          <p> Real Estate Properties Team</p>
          <button
            onClick={resendEmail}
            type='submit'
            disabled={loading}
            className={`btn btn-primary ${
              loading === true ? 'button-disabled' : null
            }`}
          >
            {loading === true ? (
              <div
                class='spinner-border spinner-border-sm text-light mr-2'
                role='status'
              >
                <span class='sr-only'>Loading...</span>
              </div>
            ) : null}{' '}
            ResendEmail{' '}
          </button>
          <Link className='btn btn-primary ml-4' to='/'>
            {' '}
            Home{' '}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResendForgotPassEmail;
