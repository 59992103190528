import axios from 'axios';
import { useQuery } from 'react-query';

const fetchEvents = async (params) => {
  try {
    const res = await axios.get('/event', {
      params,
    });
    return res.data?.list;
  } catch (err) {
    throw err.response.data;
  }
};

export const useEvents = ({ params, enabled }) => {
  const { data, isLoading, error } = useQuery(
    ['EVENTS', params?.memberId],
    () => fetchEvents(params),
    {
      refetchOnWindowFocus: false,
      enabled,
    }
  );

  return {
    events: data,
    isLoading,
    error,
  };
};
