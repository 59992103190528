import React from 'react';
import Select from 'react-select';
import { useFormContext, Controller } from 'react-hook-form';
import { useOptionsDropdown } from '../../../../hooks/useOptionGroup';
import {
  formatGroupLabel,
  formatOptionLabel,
  GetClassOptions,
  getOptionsByValue,
} from '../../../../utils/optionFormatter';

export const FeaturesList = () => {
  const { isLoading, options } = useOptionsDropdown({
    type: 'category',
  });

  const { control } = useFormContext();

  const feat = getOptionsByValue('features', options);

  const featuresList = feat?.filter(
    (feature) => !feature.value.includes('stockappy')
  );

  return (
    <div className='p-0'>
      <div className='mb-4 p-0'>
        <div>
          <label className='block text-sm font-medium leading-6 text-gray-900'>
            Features
          </label>
          <div className='mt-2'>
            <Controller
              isMulti
              isLoading={isLoading}
              name='features'
              placeholder='Select Features (Multiple)'
              control={control}
              as={
                <Select
                  options={featuresList}
                  formatOptionLabel={formatOptionLabel}
                />
              }
            />
          </div>
        </div>
      </div>

      <div>
        <div className='mb-4'>
          <label className='block text-sm font-medium leading-6 text-gray-900'>
            Use Classes
          </label>
          <div className='mt-2'>
            <Controller
              as={Select}
              options={GetClassOptions(options)}
              isClearable
              isMulti
              placeholder='Select Classes (Multiple)'
              isLoading={isLoading}
              defaultValue=''
              formatGroupLabel={formatGroupLabel}
              name='classes'
              control={control}
            />
          </div>
        </div>
        <div className='mb-4' md={12}>
          <label className='block text-sm font-medium leading-6 text-gray-900'>
            Accessibility
          </label>
          <div className='mt-2'>
            <Controller
              isMulti
              name='accessibility'
              control={control}
              placeholder='Select Accessibility (Multiple)'
              as={Select}
              options={getOptionsByValue('accessibility', options)}
            />
          </div>
        </div>
        <div className='grid grid-cols-2 gap-x-6'>
          <div className='mb-4'>
            <label className='block text-sm font-medium leading-6 text-gray-900'>
              Outdoor Space
            </label>
            <div className='mt-2'>
              <Controller
                as={Select}
                isClearable
                options={getOptionsByValue('outdoor space', options)}
                placeholder='Select Outdoor Space'
                isLoading={isLoading}
                name='outdoorSpace'
                control={control}
              />
            </div>
          </div>
          <div className='mb-4'>
            <label className='block text-sm font-medium leading-6 text-gray-900'>
              Epc Rating
            </label>
            <div className='mt-2'>
              <Controller
                as={Select}
                isClearable
                options={getOptionsByValue('epc rating', options)}
                placeholder='Select Epc Rating'
                isLoading={isLoading}
                name='epcRating'
                control={control}
              />
            </div>
          </div>
        </div>

        <div className='grid-cols-2 grid gap-x-6 mb-4'>
          <div>
            <label className='block text-sm font-medium leading-6 text-gray-900'>
              Available Term
            </label>
            <div className='mt-2'>
              <Controller
                as={Select}
                isClearable
                options={getOptionsByValue('available term', options)}
                placeholder='Select Term'
                isLoading={isLoading}
                name='availableTerm'
                control={control}
              />
            </div>
          </div>
          <div>
            <label className='block text-sm font-medium leading-6 text-gray-900'>
              Tenure
            </label>
            <div className='mt-2'>
              <Controller
                as={Select}
                isClearable
                options={getOptionsByValue('tenure', options)}
                placeholder='Select Tenure'
                isLoading={isLoading}
                name='tenure'
                control={control}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
