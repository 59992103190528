import React from 'react';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';

const animatedComponents = makeAnimated();

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    // height: '40px',
    overflow: 'auto',
    zIndex: 100,
    // padding: '5px 0',
    border: '1px solid #d1d5db',
  }),
  menu: (styles) => {
    return {
      ...styles,
      zIndex: 1000,
    };
  },

  option: (styles) => {
    return {
      ...styles,
    };
  },

  // dropdownIndicator: (styles) => ({ ...styles, display: 'none' }),
  // clearIndicator: (styles) => ({ ...styles, display: 'none' }),
  // indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),

  multiValue: (styles) => {
    return {
      ...styles,
      // backgroundColor: '#D4D859',
      // height: 39,
      // width: 'auto',
      // alignItems: 'center',
      // borderRadius: 3,
      // padding: '0 10px',
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    // color: '#424B5A',
    // fontSize: 17,
    // fontWeight: 700,
    // width: 'auto',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: '#424B5A',

    svg: {
      height: 25,
      width: 25,
    },
    ':hover': {
      backgroundColor: '#D4D859',
      color: '#424B5A',
    },
  }),
};

export const CustomSelect = (rest) => {
  return (
    <>
      {!rest.isCreatable ? (
        <Select
          components={animatedComponents}
          styles={colourStyles}
          {...rest}
        />
      ) : (
        <CreatableSelect
          components={animatedComponents}
          styles={colourStyles}
          {...rest}
        />
      )}
    </>
  );
};
