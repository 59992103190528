import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import { NotFound } from '../NotFound';

const getDashboardActivity = async () => {
  try {
    const res = await axios.get('/activity');
    return res.data?.activities;
  } catch (err) {
    throw err.response;
  }
};

const DashboardActivity = () => {
  const { data, isLoading } = useQuery(
    'dashboardActivity',
    getDashboardActivity
  );

  if (!isLoading && data?.length === 0) {
    return (
      <div className='mt-20'>
        <NotFound
          title={'No Recent Activity'}
          subTitle={`You haven't received any new updates yet!`}
        />
      </div>
    );
  }

  return (
    <div className='mt-7 divide-y'>
      {data?.slice(0, 10)?.map((item) => (
        <div key={item._id} className='flex py-4 items-center h-full'>
          <div className='relative px-1'>
            <div className='flex h-8 w-8 items-center justify-center rounded-full bg-sky-50 ring-8 ring-white'>
              <UserCircleIcon
                className='h-5 w-5 text-sky-600'
                aria-hidden='true'
              />
            </div>
          </div>
          <div className='ml-4'>
            <small>
              <Link
                className='font-medium mx-1 text-gray-900'
                to={`/property/${item?.propertyId?.slug}`}
              >
                {item?.propertyId?.title}
              </Link>
              <span className='text-sm text-gray-500'>{item.message}</span>
            </small>
            {item.type === 'request' ? (
              <Link to={`/p/enquiries/${item?.propertyId?.slug}`}>
                <small className='ml-1 uppercase font-medium text-gray-900'>
                  {item.otherLink}.
                </small>
              </Link>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashboardActivity;
