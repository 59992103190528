import React from 'react';
import Footer from '../components/global-components/footer';
import Navbar from '../components/global-components/navbar';
import { PricingSection } from '../components/Pricing';

const Pricing = () => {
  return (
    <div>
      <Navbar />
      <div className='py-24'>
        <PricingSection />
      </div>
      <Footer />
    </div>
  );
};

export default Pricing;
