import React, { useState } from 'react';
import { truncateString } from '../../../utils/truncateString';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import classNames from 'classnames';
import RequestModal from './ModalRequest';

const statusStyles = {
  REPLIED: 'bg-green-100 text-green-800',
  PENDING: 'bg-gray-100 text-gray-800',
};

export const Request = ({ request }) => {
  const [showReqModal, setShowReqModal] = useState(false);

  return (
    <tr>
      <RequestModal
        showReqModal={showReqModal}
        setShowReqModal={setShowReqModal}
        propertyTitle={request.property.title}
        requestId={request._id}
        requestorName={request.name}
      />

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-900'>
        {request.name}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-900'>
        {request.email}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-900'>
        {truncateString(request.message, 100)}
      </td>

      <td>
        <span
          className={classNames(
            statusStyles[request.isReplied ? 'REPLIED' : 'PENDING'],
            'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium capitalize'
          )}
        >
          {request.isReplied ? 'Replied' : 'Pending'}
        </span>
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-900'>
        {moment.utc(request.createdAt).format('MMM DD, YYYY')}
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-900'>
        <button
          type='button'
          className='relative inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10'
          onClick={() => setShowReqModal(true)}
        >
          <ArrowUturnLeftIcon
            className='-ml-0.5 h-5 w-5 text-gray-400'
            aria-hidden='true'
          />
          Reply
        </button>
      </td>
    </tr>
  );
};
