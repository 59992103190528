import ProfileManagementForm from '../../components/ProfileManagementForm';
import { useGetProfile } from '../../hooks/useGetProfile';
import { Layout } from '../../components/Layout';

const ProfileManagement = () => {
  const { isLoading, data } = useGetProfile();

  return (
    <Layout loading={isLoading}>
      <div className='mx-auto w-full max-w-[500px] lg:w-[600px]'>
        <ProfileManagementForm profile={data} />
      </div>
    </Layout>
  );
};

export default ProfileManagement;
