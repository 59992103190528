import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useRedirect } from '../../hooks/useRedirect';
import Navbar from '../../components/global-components/navbar';
import Verify from '../../components/section-components/confirmEmail';
import { Loader } from '../../components/Loader';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../features/userAuthentication';

const ConfirmVerification = () => {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const { id } = useParams();
  useRedirect();
  useEffect(() => {
    axios
      .get(`profile/verify/${id}`)
      .then((res) => {
        setLoading(false);
        dispatch(loginUser(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch, id]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <Navbar />
      <Verify />
    </div>
  );
};

export default ConfirmVerification;
