import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import notifications from '../notifications';
import { loginUser } from '../../features/userAuthentication';
import { Loader } from '../Loader';
import { ErrorMessage } from '@hookform/error-message';

const users = [
  { id: 'CLUSTER_OWNER', title: 'Cluster Owner' },
  { id: 'PARTNER', title: 'Partner' },
  { id: 'MEMBER', title: 'Member' },
];

const Form = () => {
  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: {
      userType: 'CLUSTER_OWNER',
    },
  });
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    setLoading(true);

    axios
      .post('/signin', data)
      .then((res) => {
        setLoading(false);
        if (res.data.isVerified) {
          dispatch(loginUser(res.data));
        }
      })
      .catch((err) => {
        setLoading(false);
        notifications.error('Error', err?.response?.data?.message);
      });
  };
  return (
    <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-lg'>
      <form className='space-y-6' onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label
            htmlFor='email'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
            Email address
          </label>
          <div className='mt-2'>
            <input
              type='email'
              name='email'
              placeholder='Enter Email'
              className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
              ref={register({ required: true })}
            />
            <ErrorMessage
              errors={errors}
              name='email'
              message='Email is required'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>
        <div className='mt-10'>
          <div className='flex items-center justify-between'>
            <label
              htmlFor='password'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              Password
            </label>
            <div className='text-sm'>
              <Link
                to='/forgot-password'
                className='font-semibold text-sky-600 hover:text-sky-500'
              >
                Forgot password?
              </Link>
            </div>
          </div>
          <div className='mt-2'>
            <input
              type='password'
              placeholder='Enter Password'
              className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
              name='password'
              ref={register({ required: true })}
            />
            <ErrorMessage
              errors={errors}
              name='password'
              message='Password is required'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>

        <div>
          <p className='text-sm text-gray-500'>
            What type of user are you logging in as?
          </p>
          <fieldset className='mt-4'>
            <legend className='sr-only'>Notification method</legend>
            <div className='space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0'>
              {users.map((user) => (
                <div key={user.id} className='flex items-center'>
                  <Controller
                    control={control}
                    name='userType'
                    render={(field) => (
                      <input
                        value={user.id}
                        id={user.id}
                        {...field}
                        type='radio'
                        onChange={() => field.onChange(user.id)}
                        defaultChecked={field.value === user.id}
                        className='h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-600'
                      />
                    )}
                  />

                  <label
                    htmlFor={user.id}
                    className='ml-3 block text-sm font-medium leading-6 text-gray-900'
                  >
                    {user.title}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        <div className='mt-4'>
          <button
            className='flex w-full justify-center rounded-md bg-sky-600 px-3 py-3 text-base font-semibold leading-6 text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600'
            type='submit'
            disabled={loading}
          >
            {loading ? <Loader /> : null}
            Sign in
          </button>
        </div>
      </form>
    </div>
  );
};

export const SignIn = () => {
  return <Form />;
};
