import React, { useState } from 'react';
import classNames from 'classnames';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

export const PropertyDescription = ({ details }) => {
  const { description } = details;
  const [showList, setShowList] = useState(false);
  return (
    <div>
      <div className='font-semibold text-xl pb-2'>Description</div>
      <div
        className={classNames(
          'text-base line-clamp-4 tracking-wide leading-7 text-gray-800',
          showList ? 'line-clamp-none' : 'line-clamp-4'
        )}
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
      <div
        onClick={() => setShowList(!showList)}
        className='relative mt-4 cursor-pointer'
      >
        <div className='absolute inset-0 flex items-center' aria-hidden='true'>
          <div className='w-full border-t border-gray-300' />
        </div>
        <div className='relative flex justify-center'>
          <span className='bg-white px-2 text-sm text-gray-500'>
            {!showList ? 'Show More' : 'Show Less'}
          </span>
        </div>
      </div>
    </div>
  );
};
