import React, { useEffect, useState } from 'react';
import { Layout } from '../../components/Layout';
import { useGroups } from '../../hooks/useGroups';
import { MemberList } from '../../components/MemberDetails/MemberList';
import classNames from 'classnames';

export default function Members() {
  const { groups, isLoading } = useGroups();

  const [currentGroup, setCurrentGroup] = useState('');

  useEffect(() => {
    if (!isLoading && groups?.length) {
      setCurrentGroup(groups[0]);
    }
  }, [groups, isLoading]);

  return (
    <Layout loading={isLoading}>
      <div>
        <div className='sm:block'>
          <div className='border-b border-gray-200'>
            <nav
              className='-mb-px flex space-x-8 overflow-x-scroll'
              aria-label='Tabs'
            >
              {groups?.map((group) => (
                <button
                  onClick={() => setCurrentGroup(group)}
                  key={group?._id}
                  className={classNames(
                    currentGroup?.name === group?.name
                      ? 'border-sky-500 text-sky-600'
                      : 'border-transparent text-gray-500  hover:border-gray-300 hover:text-gray-700',
                    'group inline-flex items-center whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                  )}
                >
                  <span>{group.name}</span>

                  {/* <span
                    className={classNames(
                      currentGroup?.name === group?.name
                        ? 'bg-sky-100 text-sky-600'
                        : 'bg-gray-100 text-gray-900',
                      'ml-3  rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                    )}
                  >
                    {group?.memberId?.length}
                  </span> */}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className='bg-white py-16 sm:py-16'>
        <MemberList groupId={currentGroup?._id} />
      </div>
    </Layout>
  );
}
