import React from 'react';
import { Link } from 'react-router-dom';
import { Loader } from '../../Loader';
import { useSelector } from 'react-redux';

export const Save = ({ loading, type }) => {
  const { user } = useSelector((state) => state.user);
  const isClusterOwner = user?.userType === 'CLUSTER_OWNER';
  return (
    <div className='pt-6 border-t flex items-center justify-end gap-x-6'>
      <Link
        to='/listings'
        className='text-sm font-semibold leading-6 text-gray-900'
      >
        Cancel
      </Link>
      <button
        type='submit'
        disabled={loading}
        className='rounded-md flex bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600'
      >
        {loading && <Loader />}
        {type === 'edit' ? 'Update' : !isClusterOwner ? 'Send Request' : 'Save'}
      </button>
    </div>
  );
};
