import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

export const MemberDetails = ({ open, onClose, member }) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-[1000]' onClose={onClose}>
        <div className='fixed inset-0' />

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <Dialog.Panel className='pointer-events-auto w-screen max-w-md'>
                  <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl'>
                    <div className='px-4 py-6 sm:px-6'>
                      <div className='flex items-start justify-between'>
                        <h2
                          id='slide-over-heading'
                          className='text-base font-semibold leading-6 text-gray-900'
                        >
                          Profile
                        </h2>
                        <div className='ml-3 flex h-7 items-center'>
                          <button
                            type='button'
                            className='relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500'
                            onClick={onClose}
                          >
                            <span className='absolute -inset-2.5' />
                            <span className='sr-only'>Close panel</span>
                            <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Main */}
                    <div>
                      <div className='pb-1 sm:pb-6'>
                        <div>
                          <div className='relative h-40 border-y sm:h-56'>
                            <img
                              className='absolute h-full w-full object-cover'
                              src={
                                member.imageUrl ??
                                'https://vercel.com/api/www/avatar/R7I3GPI1W8B4bTuz6urVv00F?&s=50'
                              }
                              alt=''
                            />
                          </div>
                          <div className='mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6'>
                            <div className='sm:flex-1'>
                              <div>
                                <div className='flex items-center'>
                                  <h3 className='text-xl font-bold text-gray-900 sm:text-2xl'>
                                    {member.firstName} {member.lastName}
                                  </h3>
                                  {/* <span className='ml-2.5 inline-block h-2 w-2 flex-shrink-0 rounded-full bg-green-400'>
                                    <span className='sr-only'>Online</span>
                                  </span> */}
                                </div>
                                <span className='mt-1 inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
                                  Verified
                                </span>
                              </div>
                              <div className='mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0'>
                                <Link
                                  to={`/create-referrals?refId=${member._id}`}
                                  className='inline-flex w-full flex-shrink-0 items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:flex-1'
                                >
                                  Refer Listings
                                </Link>
                                {/* <button
                                  type='button'
                                  className='inline-flex w-full flex-1 items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                                >
                                  Call
                                </button> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='px-4 pb-5 pt-5 sm:px-0 sm:pt-0'>
                        <dl className='space-y-8 px-4 sm:space-y-6 sm:px-6'>
                          <div>
                            <dt className='text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0'>
                              Bio
                            </dt>
                            <dd className='mt-1 text-sm text-gray-900 sm:col-span-2'>
                              <p>{member.bio}</p>
                            </dd>
                          </div>
                          <div>
                            <dt className='text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0'>
                              Phone
                            </dt>
                            <dd className='mt-1 text-sm text-gray-900 sm:col-span-2'>
                              {member.phoneNumber}
                            </dd>
                          </div>
                          <div>
                            <dt className='text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0'>
                              Website
                            </dt>
                            <dd className='mt-1 text-sm text-gray-900 sm:col-span-2'>
                              {member.website}
                            </dd>
                          </div>
                          <div>
                            <dt className='text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0'>
                              Email
                            </dt>
                            <dd className='mt-1 text-sm text-gray-900 sm:col-span-2'>
                              {member.email}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
