import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom';
import App from './App';
import store from './app/store';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import axios from 'axios';
import { ReactQueryDevtools } from 'react-query/devtools';
import './index.css';
import '@uppy/core/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/progress-bar/dist/style.css';
import '@uppy/dashboard/dist/style.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const localUrl = 'https://stockappy-backend.herokuapp.com';
//export const baseURL = 'https://stockappy-backend.herokuapp.com/';

axios.defaults.baseURL = localUrl;

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={true} />
      </QueryClientProvider>
    </Provider>
  </Router>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
