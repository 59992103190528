import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Modal } from '../../components/Modal';
import { Dialog } from '@headlessui/react';
import { Loader } from '../../components/Loader';
import { ErrorMessage } from '@hookform/error-message';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import { DashboardModal, useUppy } from '@uppy/react';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { useUpdatePartner } from '../../hooks/useUpdatePartner';
import { useSignupPartner } from '../../hooks/useSignupPartner';
import className from 'classnames';

export const AddPartnerForm = ({
  setOpen,
  defaultValues,
  isEditing = false,
}) => {
  const { control, handleSubmit, errors, setValue, watch } = useForm({
    shouldUnregister: false,
    defaultValues,
  });
  const [openModal, setOpenModal] = useState(false);
  const { mutation } = useSignupPartner({ invalidate: true });
  const { mutation: updatePartner } = useUpdatePartner();

  const imageUrl = watch('imageUrl');

  const localUrl = 'https://stockappy-backend.herokuapp.com';

  const uppyConfig = useUppy(() => {
    return new Uppy({
      restrictions: {
        maxFileSize: 1000000 * 10,
        allowedFileTypes: ['image/*'],
      },
      autoProceed: true,
    }).use(XHRUpload, {
      endpoint: `${localUrl}/image`,
    });
  });

  uppyConfig
    .off('complete', () => {})
    .on('complete', (result) => {
      const body = result.successful.map((file) => file.response?.body.image);
      setValue('imageUrl', body[0]);
      uppyConfig.cancelAll();
    });

  const onSubmit = (data) => {
    const origin = window.origin;
    const payload = {
      ...data,
      clusterId: '61818869c729d2843791b95a',
    };

    if (isEditing) {
      updatePartner.mutateAsync(payload, {
        onSuccess: () => {
          if (setOpen) {
            setOpen(false);
          }
        },
        onError: (error) => {
          console.log(error);
        },
      });
    } else {
      const createPayload = {
        ...payload,
        redirectUrl: `${origin}/verify/partner`,
      };
      mutation.mutateAsync(createPayload, {
        onSuccess: () => setOpen(false),
        onError: (error) => {
          console.log(error);
        },
      });
    }
  };

  return (
    <div>
      <DashboardModal
        uppy={uppyConfig}
        closeAfterFinish
        note='Add a Partner logo here...'
        closeModalOnClickOutside
        open={openModal}
        onRequestClose={() => {
          setOpenModal(false);
        }}
      />
      <form className='space-y-4' onSubmit={handleSubmit(onSubmit)}>
        <div className='flex items-center justify-center'>
          <div className=' flex-col flex items-center space-y-3 mt-3'>
            {imageUrl ? (
              <img
                alt=''
                src={imageUrl}
                className='h-20 w-20 text-gray-300 object-contain bg-gray-200 rounded-full py-1'
              />
            ) : (
              <UserCircleIcon
                className='h-20 w-20 text-gray-300'
                aria-hidden='true'
              />
            )}

            <div className='mt-1'>
              {imageUrl ? (
                <button
                  onClick={() => setOpenModal(true)}
                  type='button'
                  className='rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
                >
                  Change
                </button>
              ) : (
                <button
                  onClick={() => setOpenModal(true)}
                  type='button'
                  className='rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
                >
                  Upload
                </button>
              )}
            </div>
          </div>
        </div>

        <div>
          <label
            className='block text-sm font-medium leading-6 text-gray-900'
            htmlFor='companyName'
          >
            Company Name
          </label>
          <div className='mt-2'>
            <Controller
              placeholder='Company Name'
              rules={{ required: true }}
              id='companyName'
              name='companyName'
              className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
              control={control}
              as={<input />}
            />
            <ErrorMessage
              errors={errors}
              name='companyName'
              message='Company name is required'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>

        <div>
          <label
            className='block text-sm font-medium leading-6 text-gray-900'
            htmlFor='firstname'
          >
            First Name
          </label>
          <div className='mt-2'>
            <Controller
              placeholder='First Name'
              rules={{ required: true }}
              id='firstname'
              name='firstName'
              className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
              control={control}
              as={<input />}
            />
            <ErrorMessage
              errors={errors}
              name='firstName'
              message='Firstname is required'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>

        <div>
          <label
            className='block text-sm font-medium leading-6 text-gray-900'
            htmlFor='lname'
          >
            Last Name
          </label>
          <div className='mt-2'>
            <Controller
              placeholder='Last Name'
              rules={{ required: true }}
              id='name'
              name='lastName'
              className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
              control={control}
              as={<input />}
            />
            <ErrorMessage
              errors={errors}
              name='lastName'
              message='Lastname is required'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>
        <div>
          <label
            className='block text-sm font-medium leading-6 text-gray-900'
            htmlFor='email'
          >
            Email Address
          </label>
          <div className='mt-2'>
            <Controller
              id='email'
              name='email'
              type='email'
              disabled={isEditing}
              rules={{ required: true }}
              placeholder='johndoe@gmail.com'
              className='block w-full disabled:bg-gray-100 rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
              control={control}
              as={<input />}
            />
            <ErrorMessage
              errors={errors}
              name='email'
              message='Email is required'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>

        <div>
          <label
            className='block text-sm font-medium leading-6 text-gray-900'
            htmlFor='tel'
          >
            Phone Number
          </label>
          <div className='mt-2'>
            <Controller
              id='tel'
              name='phoneNumber'
              type='tel'
              rules={{ required: false }}
              placeholder='+1 (555) 987-6543'
              className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
              control={control}
              as={<input />}
            />
            <ErrorMessage
              errors={errors}
              name='phoneNumber'
              message='Phonenumber is required'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>

        <div>
          <label
            className='block text-sm font-medium leading-6 text-gray-900'
            htmlFor='name'
          >
            Website
          </label>
          <div className='mt-2'>
            <Controller
              placeholder='facebook.com'
              id='name'
              name='website'
              className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
              control={control}
              as={<input />}
            />
            <ErrorMessage
              errors={errors}
              name='website'
              message='Website is required'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>
        <div
          className={className(
            'sm:grid sm:grid-flow-row-dense sm:gap-3',
            isEditing ? 'sm:grid-cols-1' : 'sm:grid-cols-2'
          )}
        >
          {!isEditing ? (
            <button
              type='button'
              onClick={() => setOpen(false)}
              className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0'
            >
              Cancel
            </button>
          ) : null}

          <button
            className='md:mt-0 mt-3 inline-flex w-full justify-center rounded-md bg-sky-600 px-3 py-3.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600'
            disabled={mutation.isLoading || updatePartner.isLoading}
            type='submit'
          >
            {mutation.isLoading || updatePartner.isLoading ? <Loader /> : null}

            {!isEditing ? 'Add' : 'Update'}
          </button>
        </div>
      </form>
    </div>
  );
};

export const AddPartnerModal = () => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div className='sm:flex sm:items-center pb-7 border-b mb-10'>
        <div className='sm:flex-auto'>
          <h1 className='text-base font-semibold leading-6 text-gray-900'>
            Partners
          </h1>
          <p className='mt-2 text-sm text-gray-700'>
            A list of all the Partners in your account.
          </p>
        </div>
        <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'>
          <button
            onClick={() => setOpen(true)}
            className='block rounded-md bg-sky-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600'
          >
            Add Partner
          </button>
        </div>
      </div>

      <Modal isOpen={open} onClose={() => setOpen(false)}>
        <div className='flex flex-col'>
          <Dialog.Title
            as='h3'
            className='text-base text-center font-semibold leading-6 text-gray-900'
          >
            Create a New Partner
          </Dialog.Title>
          <div className='mt-0 text-center'>
            <p className='text-sm text-gray-500'>
              Get Started by creating a new partner.
            </p>
          </div>

          <AddPartnerForm setOpen={setOpen} />
        </div>
      </Modal>
    </div>
  );
};
