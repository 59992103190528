import React from 'react';
import { SignIn } from '../../components/auth-components/signIn';
import { Link } from 'react-router-dom';

import { useRedirect } from '../../hooks/useRedirect';

const Login = () => {
  useRedirect();
  return (
    <div className='h-screen flex flex-col relative isolate overflow-hidden'>
      <svg
        className='absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]'
        aria-hidden='true'
      >
        <defs>
          <pattern
            id='0787a7c5-978c-4f66-83c7-11c213f99cb7'
            width={200}
            height={200}
            x='50%'
            y={-1}
            patternUnits='userSpaceOnUse'
          >
            <path d='M.5 200V.5H200' fill='none' />
          </pattern>
        </defs>
        <rect
          width='100%'
          height='100%'
          strokeWidth={0}
          fill='url(#0787a7c5-978c-4f66-83c7-11c213f99cb7)'
        />
      </svg>
      <div className=' px-4 py-6 border-b sm:px-6 lg:px-8'>
        <div className='relative flex justify-between items-center flex-1'>
          <div className='flex'>
            <div className='flex-shrink-0 hidden md:flex items-center'>
              <Link to='/'>
                <img
                  className='mx-auto h-10 w-auto'
                  alt='Workflow'
                  src='https://bdibucket1.s3.eu-west-2.amazonaws.com/Coduq_LOGO_2023.png'
                />
              </Link>
            </div>
          </div>
          <div className='flex space-x-4'>
            <div>
              <Link
                to='/sign-up/partner'
                className='inline-block rounded-lg py-2 px-2 text-sm text-slate-600 bg-slate-100 hover:bg-slate-200 hover:text-slate-900'
              >
                Become a Partner
              </Link>
            </div>

            <div>
              <Link
                to='/sign-up/member'
                className=' group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-sky-600 text-white hover:text-slate-100 hover:bg-sky-500 active:bg-sky-800 active:text-sky-100 focus-visible:outline-sky-600'
              >
                Become a Member
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
          <h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
            Sign in to your account
          </h2>
        </div>
        <SignIn />
      </div>
    </div>
  );
};

export default Login;
