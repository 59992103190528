import React from 'react';
import { Layout } from '../../components/Layout';
import classNames from 'classnames';
import { ArrowDownLeftIcon } from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
import { useReferrals } from '../../hooks/useReferrals';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { NotFound } from '../../components/NotFound';

const statusStyles = {
  ACCEPTED: 'bg-green-100 text-green-800',
  PENDING: 'bg-yellow-100 text-yellow-800',
  EXPIRED: 'bg-gray-100 text-gray-800',
  REJECTED: 'bg-red-100 text-red-800',
};

export const Opportunities = () => {
  const { user } = useSelector((state) => state.user);

  const { isLoading, referrals } = useReferrals({ leadTo: user?._id });
  return (
    <Layout loading={isLoading}>
      <div className='sm:flex sm:items-center pb-7 border-b mb-10'>
        <div className='sm:flex-auto'>
          <h1 className='text-base font-semibold leading-6 text-gray-900'>
            Opportunities
          </h1>
          <p className='mt-2 text-sm text-gray-700'>
            A list of all the opportunities you have received.
          </p>
        </div>
      </div>
      {referrals?.length === 0 ? (
        <NotFound
          title='No Opportunities'
          subTitle={`You haven't Received any opportunities yet...`}
        />
      ) : (
        <div className='mt-10 sm:block'>
          <div>
            <div className='mt-2 flex flex-col'>
              <div className='min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-200'>
                  <thead>
                    <tr>
                      <th
                        className='bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900'
                        scope='col'
                      >
                        Opportunity
                      </th>
                      <th
                        className='bg-gray-50 whitespace-nowrap px-6 py-3 text-right text-sm font-semibold text-gray-900'
                        scope='col'
                      >
                        Organization
                      </th>
                      <th
                        className='bg-gray-50 whitespace-nowrap px-6 py-3 text-right text-sm font-semibold text-gray-900'
                        scope='col'
                      >
                        Lead From
                      </th>
                      <th
                        className='hidden bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900 md:block'
                        scope='col'
                      >
                        Status
                      </th>
                      <th
                        className='bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900'
                        scope='col'
                      >
                        Date
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {referrals?.map((transaction) => (
                      <tr key={transaction.id} className='bg-white'>
                        <td className='w-full max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900'>
                          <Link
                            to={`/opportunity/${transaction._id}`}
                            className='flex space-x-3'
                          >
                            <div className='bg-gray-100 rounded-full flex flex-shrink-0 items-center justify-center h-8 w-8'>
                              <ArrowDownLeftIcon
                                strokeWidth={2.5}
                                className='h-5 w-5 flex-shrink-0 text-sky-500 group-hover:text-gray-500'
                                aria-hidden='true'
                              />
                            </div>
                            <div className='group flex-col flex  truncate text-sm'>
                              <p className='truncate text-gray-500 group-hover:text-gray-900'>
                                {transaction.subject}
                              </p>
                              <p className='truncate text-gray-500 group-hover:text-gray-900'>
                                {transaction.email}
                              </p>
                            </div>
                          </Link>
                        </td>
                        <td className='whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500'>
                          <span className='font-medium text-gray-900'>
                            {transaction.organization}
                          </span>
                        </td>
                        <td className='whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500'>
                          <span className='font-medium text-gray-900'>
                            {transaction?.leadTo?.firstName}
                          </span>
                        </td>
                        <td className=' whitespace-nowrap px-6 py-4 text-sm text-gray-500 '>
                          <span
                            className={classNames(
                              statusStyles[transaction.status],
                              'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium capitalize'
                            )}
                          >
                            {transaction.status}
                          </span>
                        </td>
                        <td className='whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500'>
                          <time
                            dateTime={moment
                              .utc(transaction.createdDate)
                              .format('MMM DD, YYYY')}
                          >
                            {moment
                              .utc(transaction.createdDate)
                              .format('MMM DD, YYYY')}
                          </time>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Pagination */}
                {/* <nav
                className='flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6'
                aria-label='Pagination'
              >
                <div className='hidden sm:block'>
                  <p className='text-sm text-gray-700'>
                    Showing <span className='font-medium'>1</span> to{' '}
                    <span className='font-medium'>10</span> of{' '}
                    <span className='font-medium'>20</span> results
                  </p>
                </div>
                <div className='flex flex-1 justify-between gap-x-3 sm:justify-end'>
                  <a
                    href='#'
                    className='relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400'
                  >
                    Previous
                  </a>
                  <a
                    href='#'
                    className='relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400'
                  >
                    Next
                  </a>
                </div>
              </nav> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};
