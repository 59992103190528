import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MessageCard } from '../../components/MessageCard';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import axios from 'axios';
import { loginUser } from '../../features/userAuthentication';
import { Loader } from '../../components/Loader';

export const ConfirmMemberVerfication = () => {
  const [loading, setLoading] = useState(true);

  //   useRedirect();
  const dispatch = useDispatch();

  const { id } = useParams();
  useEffect(() => {
    axios
      .patch(`/member/verify/${id}`)
      .then((res) => {
        setLoading(false);
        dispatch(loginUser(res.data));
      })
      .catch(() => {
        setLoading(false);
      });
  }, [dispatch, id]);

  return (
    <div className='h-screen flex flex-col'>
      <div className=' px-4 py-6 border-b sm:px-6 lg:px-8'>
        <div className='relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8'>
          <div className='flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2'>
            <div className='flex-shrink-0 flex items-center'>
              <Link to='/'>
                <img
                  className='mx-auto h-10 w-auto'
                  alt=''
                  src='https://bdibucket1.s3.eu-west-2.amazonaws.com/Coduq_LOGO_2023.png'
                />
              </Link>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <div className='flex items-center flex-1 flex-col justify-center'>
          <Loader className={'h-16 w-16'} />
        </div>
      ) : (
        <div className='flex flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
            <MessageCard
              title='You email has been verified successfully!'
              subTitle='You can now use the start using your account.'
              message={'Go To Dashboard'}
            />
          </div>
        </div>
      )}
    </div>
  );
};
