import React from 'react';
import { PropertyForm } from '../../components/properties-components/addPropertyForm/PropertyForm';
import { useCreateProperty } from './hooks/useCreateProperty';
import { Layout } from '../../components/Layout';

export const AddPropertyPage = () => {
  const { mutation } = useCreateProperty();

  return (
    <Layout>
      <PropertyForm type='add' mutation={mutation} />
    </Layout>
  );
};
