import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Loader } from '../../components/Loader';
import { ErrorMessage } from '@hookform/error-message';

import { useSignupMember } from '../../hooks/useSignupMember';
import notifications from '../notifications';
import ReactSelect from 'react-select';
import { useGroups } from '../../hooks/useGroups';
import { DashboardModal, useUppy } from '@uppy/react';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { useState } from 'react';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import { useUpdateMember } from '../../hooks/useUpdateMember';

export const formatOptionLabel = (property) => {
  return (
    <div className='flex items-center cursor-pointer'>
      <span className='relative  inline-block flex-shrink-0'>
        <img
          className='lg:h-7 hidden md:block lg:w-7 h-7 w-7 border rounded-md object-cover'
          src={property?.imageUrl}
          alt=''
        />
      </span>
      <div className='ml-3'>
        <div className='flex flex-col'>
          <span className='font-medium hidden md:block capitalize text-gray-900'>
            {property.label}{' '}
          </span>
        </div>
      </div>
    </div>
  );
};

export const MemberSignupForm = ({
  defaultValues,
  setIsSuccess,
  isEditing,
}) => {
  const { control, handleSubmit, errors, setValue, watch } = useForm({
    defaultValues: isEditing ? defaultValues : {},
    shouldUnregister: false,
  });

  const [openModal, setOpenModal] = useState(false);

  const imageUrl = watch('imageUrl', '');

  const localUrl = 'https://stockappy-backend.herokuapp.com';

  const uppyConfig = useUppy(() => {
    return new Uppy({
      restrictions: {
        maxFileSize: 1000000 * 10,
        allowedFileTypes: ['image/*'],
      },
      autoProceed: true,
    }).use(XHRUpload, {
      endpoint: `${localUrl}/image`,
    });
  });

  uppyConfig
    .off('complete', () => {})
    .on('complete', (result) => {
      const body = result.successful.map((file) => file.response?.body.image);
      setValue('imageUrl', body[0]);
    });

  const { user } = useSelector((state) => state.user);
  const { mutation } = useSignupMember();

  const { mutation: updateMemberProfile } = useUpdateMember();

  const { groups } = useGroups();

  const onSubmit = (data) => {
    const origin = window.origin;

    const payload = {
      ...data,
      clusterId: '61818869c729d2843791b95a',
    };

    if (isEditing) {
      updateMemberProfile.mutate(payload, {
        onSuccess: () => {
          notifications.success(
            'Profile Updated',
            'Your Profile has been updated'
          );
        },
        onError: (error) => {
          notifications.error('Cannot Signup', error.message);
        },
      });
    } else {
      const createPayload = {
        ...data,
        redirectUrl: `${origin}/verify/member`,
        clusterId: '61818869c729d2843791b95a',
        groupId: data.groupId.value,
      };

      mutation.mutate(createPayload, {
        onSuccess: () => {
          setIsSuccess(true);
        },
        onError: (error) => {
          notifications.error('Cannot Signup', error.message);
        },
      });
    }
  };

  return (
    <div>
      <DashboardModal
        uppy={uppyConfig}
        closeAfterFinish
        note='Add a Member Profile Picture here...'
        closeModalOnClickOutside
        open={openModal}
        onRequestClose={() => {
          setOpenModal(false);
        }}
      />
      <form className='space-y-4' onSubmit={handleSubmit(onSubmit)}>
        <div className='grid grid-cols-6 mt-8 gap-6'>
          {isEditing ? (
            <div className='flex col-span-full items-center justify-center'>
              <div className=' flex-col flex items-center space-y-3'>
                {imageUrl ? (
                  <img
                    alt=''
                    src={imageUrl}
                    className='h-20 w-20 text-gray-300 object-contain bg-gray-200 rounded-full py-1'
                  />
                ) : (
                  <UserCircleIcon
                    className='h-20 w-20 text-gray-300'
                    aria-hidden='true'
                  />
                )}

                <div className='mt-1'>
                  {imageUrl ? (
                    <button
                      onClick={() => setOpenModal(true)}
                      type='button'
                      className='rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
                    >
                      Change
                    </button>
                  ) : (
                    <button
                      onClick={() => setOpenModal(true)}
                      type='button'
                      className='rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
                    >
                      Upload
                    </button>
                  )}
                </div>
              </div>
            </div>
          ) : null}

          <div className='col-span-3'>
            <label
              className='block text-sm font-medium leading-6 text-gray-900'
              htmlFor='firstname'
            >
              First Name
            </label>
            <div className='mt-2'>
              <Controller
                placeholder='First Name'
                rules={{ required: true }}
                id='firstname'
                name='firstName'
                className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                control={control}
                as={<input />}
              />
              <ErrorMessage
                errors={errors}
                name='firstName'
                message='Firstname is required'
                render={({ message }) => (
                  <p className='text-red-500 mt-2 text-sm'>{message}</p>
                )}
              />
            </div>
          </div>

          <div className='col-span-3'>
            <label
              className='block text-sm font-medium leading-6 text-gray-900'
              htmlFor='lname'
            >
              Last Name
            </label>
            <div className='mt-2'>
              <Controller
                placeholder='Last Name'
                rules={{ required: true }}
                id='lname'
                name='lastName'
                className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                control={control}
                as={<input />}
              />
              <ErrorMessage
                errors={errors}
                name='lastName'
                message='Lastname is required'
                render={({ message }) => (
                  <p className='text-red-500 mt-2 text-sm'>{message}</p>
                )}
              />
            </div>
          </div>
        </div>

        {isEditing ? (
          <div>
            <label
              className='block text-sm font-medium leading-6 text-gray-900'
              htmlFor='website'
            >
              Bio
            </label>
            <div className='mt-2'>
              <Controller
                control={control}
                rules={{ required: false }}
                name='bio'
                as={
                  <textarea
                    rows={5}
                    className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                    placeholder='Tell us about yourself...'
                  />
                }
              />
              <ErrorMessage
                name='bio'
                errors={errors}
                message='Please enter a reply'
                render={({ message }) => (
                  <p className='text-red-500 mt-2 text-sm'>{message}</p>
                )}
              />
            </div>
          </div>
        ) : null}

        <div>
          <label
            className='block text-sm font-medium leading-6 text-gray-900'
            htmlFor='website'
          >
            Website
          </label>
          <div className='mt-2'>
            <Controller
              id='website'
              name='website'
              type='text'
              rules={{ required: false }}
              placeholder='facebook.com'
              className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
              control={control}
              as={<input />}
            />
            <ErrorMessage
              errors={errors}
              name='website'
              message='Website is required'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>

        {!isEditing ? (
          <div>
            <label
              className='block text-sm font-medium leading-6 text-gray-900'
              htmlFor='groupId'
            >
              Group
            </label>
            <div className='mt-2'>
              <Controller
                id='groupId'
                name='groupId'
                rules={{ required: true }}
                placeholder='Select A Group'
                control={control}
                as={
                  <ReactSelect
                    formatOptionLabel={formatOptionLabel}
                    options={groups?.map((g) => ({
                      ...g,
                      label: g.name,
                      value: g._id,
                    }))}
                  />
                }
              />
              <ErrorMessage
                errors={errors}
                name='groupId'
                message='Group is required'
                render={({ message }) => (
                  <p className='text-red-500 mt-2 text-sm'>{message}</p>
                )}
              />
            </div>
          </div>
        ) : null}

        <div>
          <label
            className='block text-sm font-medium leading-6 text-gray-900'
            htmlFor='tel'
          >
            Phone Number
          </label>
          <div className='mt-2'>
            <Controller
              id='tel'
              name='phoneNumber'
              type='tel'
              rules={{ required: false }}
              placeholder='+1 (555) 987-6543'
              className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
              control={control}
              as={<input />}
            />
            <ErrorMessage
              errors={errors}
              name='phoneNumber'
              message='Phonenumber is required'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>

        <div>
          <label
            className='block text-sm font-medium leading-6 text-gray-900'
            htmlFor='email'
          >
            Email Address
          </label>
          <div className='mt-2'>
            <Controller
              id='email'
              name='email'
              type='email'
              disabled={isEditing}
              rules={{ required: true }}
              placeholder='johndoe@gmail.com'
              className='block w-full disabled:bg-gray-200 rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
              control={control}
              as={<input />}
            />
            <ErrorMessage
              errors={errors}
              name='email'
              message='Email is required'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>

        {!isEditing ? (
          <div>
            <label
              className='block text-sm font-medium leading-6 text-gray-900'
              htmlFor='password'
            >
              Password
            </label>
            <div className='mt-2'>
              <Controller
                placeholder='Enter Password'
                rules={{ required: true }}
                id='password'
                name='password'
                type='password'
                className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                control={control}
                as={<input />}
              />
              <ErrorMessage
                errors={errors}
                name='password'
                message='Password is required'
                render={({ message }) => (
                  <p className='text-red-500 mt-2 text-sm'>{message}</p>
                )}
              />
            </div>
          </div>
        ) : null}

        <button
          className='md:mt-0 mt-3 inline-flex w-full justify-center rounded-md bg-sky-600 px-3 py-3.5 text-center text-base font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600'
          disabled={mutation.isLoading || updateMemberProfile.isLoading}
          type='submit'
        >
          {mutation.isLoading || updateMemberProfile.isLoading ? (
            <Loader />
          ) : null}
          {!isEditing ? 'Become a Member' : 'Update'}
        </button>
      </form>
    </div>
  );
};
