import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { ConfirmDeleteModal } from './confirmDeleteModal';
import classNames from 'classnames';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';

const statusStyles = {
  isActive: 'bg-green-100 text-green-800',
  inActive: 'bg-yellow-100 text-yellow-800',
};

export const PropertyItem = ({ property }) => {
  const [showReqModal, setShowReqModal] = useState(false);

  return (
    <tr className='bg-white'>
      <ConfirmDeleteModal
        showReqModal={showReqModal}
        setShowReqModal={setShowReqModal}
        property={property}
      />

      <td className=' whitespace-nowrap px-6 py-4 text-sm text-gray-900'>
        <div className='flex items-center cursor-pointer'>
          <span className='relative  inline-block flex-shrink-0'>
            <img
              className='lg:h-14 hidden md:block lg:w-14 h-14 w-14 border rounded-md object-cover'
              src={property?.coverImage ?? property?.images[0]}
              alt=''
            />
          </span>
          <Link className='sm:ml-4' to={`/property/${property.slug}`}>
            <div className='flex flex-col'>
              <p className='truncate text-gray-900 group-hover:text-gray-500'>
                {property.title}
              </p>
              <span className='font-medium hidden md:block capitalize text-gray-400'>
                {property.location}{' '}
              </span>
            </div>
          </Link>
        </div>
      </td>

      <td className='whitespace-nowrap capitalize  px-6 py-4 text-right text-sm text-gray-500'>
        {property?.category?.value}
      </td>
      <td className='  whitespace-nowrap px-6 py-4 text-sm text-gray-500 '>
        <span
          className={classNames(
            statusStyles[property.isActive ? 'isActive' : 'inActive'],
            'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium capitalize'
          )}
        >
          {property.isActive ? 'Published' : `Pending Approval `}
        </span>
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500'>
        {property?.partnerId
          ? `Partner: ${property?.partnerId?.firstName} ${property?.partnerId?.lastName}`
          : property?.memberId
          ? `Member: ${property?.memberId?.firstName} ${property?.memberId?.lastName}`
          : property?.userId?.name}
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500'>
        <time dateTime={property.createdAt}>
          {moment.utc(property.createdAt).format('MMM DD, YYYY')}
        </time>
      </td>
      <td className='whitespace-nowrap cursor-pointer capitalize  px-6 py-4 text-right text-sm text-gray-500'>
        <Link className='ml-4' to={`/p/enquiries/${property.slug}`}>
          {property?.requestsCount}{' '}
          {property?.requestsCount > 1 ? 'Enquiries' : 'Enquiry'}
        </Link>
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500'>
        <span className='flex space-x-3'>
          <Link to={`/property/edit/${property.slug}`}>
            <PencilIcon className='h-6 w-6' />
          </Link>
          <button type='button' onClick={() => setShowReqModal(true)}>
            <TrashIcon className='h-6 w-6' />
          </button>
        </span>
      </td>
    </tr>
  );
};
