import axios from 'axios';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

const getProfileInfo = async () => {
  try {
    const res = await axios.get('/profile');
    return res.data.profile;
  } catch (err) {
    throw err.response;
  }
};

export const useGetProfile = () => {
  const { isAuthenticated } = useSelector((state) => state.user);

  const { data, isLoading, refetch } = useQuery(['profile'], getProfileInfo, {
    enabled: isAuthenticated,
  });

  return {
    data,
    isLoading,
    refetch,
  };
};
