import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
// import { useSelector } from 'react-redux';

const statusChange = async (data) => {
  const { id } = data;
  try {
    const res = await axios.patch(`/referral/status/${id}`, data);
    return res.data;
  } catch (err) {
    throw err.response;
  }
};

export const useStatusChange = () => {
  const queryClient = useQueryClient();
  // const { user } = useSelector((state) => state.user);
  const mutation = useMutation(statusChange, {
    onSuccess: (_, variables) => {
      setTimeout(() => {
        queryClient.invalidateQueries(['REFERRAL', variables.id]);
      }, 2000);
      queryClient.invalidateQueries('REFERRALS');
    },
  });

  return {
    mutation,
  };
};
