import React from 'react';
import { MessageItem } from './messageItem';
import { NotFound } from '../../NotFound';

export const AgencyMessagesTable = ({ messages }) => {
  if (messages?.length === 0) {
    return <NotFound title='No Enquiries' subTitle='No Enquiries Found.' />;
  }

  return (
    <div className='mt-2 flex flex-col'>
      <div className='min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg'>
        <table className='min-w-full divide-y divide-gray-200'>
          <thead>
            <th className='bg-gray-50 whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-900'>
              Enquiry From
            </th>
            <th className='bg-gray-50 px-6 py-3 whitespace-nowrap text-left text-sm font-semibold text-gray-900'>
              Email
            </th>
            <th className='bg-gray-50 px-6 py-3 whitespace-nowrap text-left text-sm font-semibold text-gray-900'>
              Message
            </th>
            <th className='bg-gray-50 px-6 py-3 whitespace-nowrap text-left text-sm font-semibold text-gray-900'>
              Phone number
            </th>
            <th className='bg-gray-50 px-6 py-3 whitespace-nowrap text-left text-sm font-semibold text-gray-900'>
              Enquiry Date
            </th>
          </thead>
          <tbody className='divide-y divide-gray-200 bg-white'>
            {messages.map((message) => (
              <MessageItem key={message._id} message={message} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
