import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

const signupMember = async (data) => {
  try {
    const res = await axios.post('/member/signup', data);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const useSignupMember = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(signupMember, {
    // onSuccess: () => {
    //   queryClient.invalidateQueries(['partners']);
    // },
  });

  return {
    mutation,
  };
};
