import React from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../../components/global-components/navbar';
import PropertyDetailsSection from '../../components/properties-components/property-details';

import Footer from '../../components/global-components/footer';
import { useFetchPropertyBySlug } from '../../hooks/useFetchPropertyBySlug';
import { Loader } from '../../components/Loader';

const PropertyDetail = () => {
  const { id } = useParams();

  const { isLoading, data: property } = useFetchPropertyBySlug({
    slug: id,
  });

  if (isLoading) {
    return (
      <div className='flex items-center justify-center h-screen'>
        <Loader />
      </div>
    );
  }

  return (
    <div>
      {/* <Navbar /> */}
      <PropertyDetailsSection property={property} />
      <Footer />
    </div>
  );
};

export default PropertyDetail;
