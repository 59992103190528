import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LightBox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export const PropertyDesktopGallery = ({ property }) => {
  const { coverImage, images } = property;
  const publicUrl = process.env.PUBLIC_URL + '/';

  const [photoIndex, setPhotoIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const handleOpenGallery = (index) => {
    setPhotoIndex(index);
    setOpen(true);
  };

  const filterImages =
    coverImage && images?.length
      ? [...images].filter((i) => i !== coverImage)
      : [...images] ?? [];

  return (
    <div className='pt-5 grid sm:grid-cols-2 grid-cols-1 gap-2'>
      {open && images?.length && (
        <LightBox
          onCloseRequest={() => setOpen(false)}
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
          animationOnKeyInput
        />
      )}
      <div>
        <div onClick={() => handleOpenGallery(0)} className='h-full block'>
          <div
            className='w-full h-full bg-cover bg-no-repeat rounded-md'
            style={{
              backgroundImage: `url(${coverImage ?? images[0]})`,
              minHeight: '390px',
            }}
          />
        </div>
      </div>
      <div>
        <div className='grid grid-cols-2 gap-2'>
          {filterImages?.map((value, index) => (
            <>
              {index < 4 ? (
                <div
                  key={value}
                  onClick={() => handleOpenGallery(index)}
                  className='relative'
                  style={{ height: 200 }}
                >
                  <LazyLoadImage
                    alt=''
                    placeholderSrc={publicUrl + 'assets/img/placeholder.png'}
                    className='w-full h-full rounded-md aspect-video object-cover'
                    src={value}
                    useIntersectionObserver={true}
                    wrapperClassName='w-full h-full '
                  />
                  {index === 3 && images.length > index + 2 ? (
                    <span className='absolute inset-0  font-weight-bold text-sm font-semibold top-20 h-12 w-12 left-28 bottom-20 bg-white flex items-center justify-center rounded-full'>
                      {images.length > index + 2 ? (
                        <>+{images.length - (index + 2)}</>
                      ) : null}
                    </span>
                  ) : null}
                </div>
              ) : null}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};
