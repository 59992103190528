import axios from 'axios';
import { useQuery } from 'react-query';

const getUserPartners = async (params) => {
  try {
    const res = await axios.get('/partner', {
      params,
    });

    return res.data?.partnerList;
  } catch (err) {
    throw err.response;
  }
};

export const useGetPartners = ({ userId, clusterId, isVerified }) => {
  const {
    isLoading,
    data: partners,
    refetch,
    error,
  } = useQuery(['partners', userId, clusterId], () =>
    getUserPartners({
      userId,
      clusterId,
      isVerified,
    })
  );

  return {
    isLoading,
    partners,
    refetch,
    error,
  };
};
