import React from 'react';

import { useFormContext, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { useOptionsDropdown } from '../../../../hooks/useOptionGroup';
import {
  getOptionsByValue,
  GetTypeOptions,
  formatGroupLabel,
} from '../../../../utils/optionFormatter';
import { LocationSuggestion } from '../../../LocationSuggestion';
import { CustomSelect } from '../../../section-components/CustomSelect';

export const Intro = () => {
  const { errors, control, getValues } = useFormContext();

  const { isLoading, options } = useOptionsDropdown({
    type: 'category',
  });

  const defaultLocation = getValues('location');

  return (
    <div>
      <div className='grid grid-cols-2 gap-x-6 gap-y-8'>
        <div className='mb-4'>
          <label className='block text-sm font-medium leading-6 text-gray-900'>
            Title
          </label>
          <div className='mt-2'>
            <Controller
              control={control}
              name='title'
              rules={{ required: true }}
              placeholder='Property Name / Number'
              as={
                <input className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6' />
              }
            />

            <ErrorMessage
              errors={errors}
              name='title'
              message='This field is required.'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>
        <div>
          <label className='block text-sm font-medium leading-6 text-gray-900'>
            Location
          </label>
          <div className='mt-2'>
            <LocationSuggestion
              propertyValue={'location'}
              defaultValue={defaultLocation}
              placeholderValue='Enter a location'
            />
          </div>
        </div>
      </div>

      <div className='grid grid-cols-2 gap-x-6 gap-y-8'>
        <div>
          <label className='block text-sm font-medium leading-6 text-gray-900'>
            Property Type
          </label>
          <div className='mt-2'>
            <Controller
              as={CustomSelect}
              options={GetTypeOptions(options)}
              isClearable
              rules={{ required: true }}
              placeholder='Select type'
              isLoading={isLoading}
              formatGroupLabel={formatGroupLabel}
              name='type'
              control={control}
            />

            <ErrorMessage
              errors={errors}
              name='type'
              message='This field is required.'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>
        <div className='mb-4' md={6} sm={12}>
          <label className='block text-sm font-medium leading-6 text-gray-900'>
            Category
          </label>
          <div className='mt-2'>
            <Controller
              as={CustomSelect}
              isClearable
              options={getOptionsByValue('category', options)}
              rules={{ required: true }}
              placeholder='Select Category'
              isLoading={isLoading}
              name='category'
              control={control}
            />

            <ErrorMessage
              errors={errors}
              name='type'
              message='This field is required.'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
