export const PropertyFeatures = ({ features }) => {
  if (!features?.length) {
    return null;
  }
  return (
    <div>
      <div className='py-5 capitalize font-semibold text-xl'>Amenities</div>
      <div className='grid grid-cols-2 md:grid-cols-3 gap-y-2 lg-grid-cols-3 text-black-50'>
        {features?.map((value) => (
          <div key={value._id} className='flex space-x-2 items-center mb-3'>
            <div className='bg-gray-100 flex items-center justify-center h-10 w-10 rounded-full'>
              <img
                alt={value.label}
                style={{
                  height: 32,
                  width: 32,
                }}
                src={value.icon}
              />
            </div>

            <div className='text-sm text-gray-500'>{value.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
