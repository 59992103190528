import { Layout } from '../../components/Layout';
import { Link } from 'react-router-dom';
import { ArrowUpRightIcon, ScaleIcon } from '@heroicons/react/24/outline';
import { useReferrals } from '../../hooks/useReferrals';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { NotFound } from '../../components/NotFound';
import classNames from 'classnames';

// const cards = [
//   { name: 'Account balance', href: '#', icon: ScaleIcon, amount: '$30,659.45' },
//   // More items...
// ];

const statusStyles = {
  ACCEPTED: 'bg-green-100 text-green-800',
  PENDING: 'bg-yellow-100 text-yellow-800',
  EXPIRED: 'bg-gray-100 text-gray-800',
  REJECTED: 'bg-red-100 text-red-800',
};

export default function Refferals() {
  const { user } = useSelector((state) => state.user);

  const { isLoading, referrals } = useReferrals({ leadFrom: user?._id });
  return (
    <Layout loading={isLoading}>
      <div className='sm:flex sm:items-center pb-7 border-b mb-10'>
        <div className='sm:flex-auto'>
          <h1 className='text-base font-semibold leading-6 text-gray-900'>
            Referrals
          </h1>
          <p className='mt-2 text-sm text-gray-700'>
            A list of all the Referrals in your account.
          </p>
        </div>
        <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'>
          <Link
            to='/create-referrals'
            className=' rounded-md bg-sky-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600'
          >
            Add Referrals
          </Link>
        </div>
      </div>
      {/* <div>
        <div className='mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3'>
          {cards.map((card) => (
            <div
              key={card.name}
              className='overflow-hidden rounded-lg bg-white shadow'
            >
              <div className='p-5'>
                <div className='flex items-center'>
                  <div className='flex-shrink-0'>
                    <card.icon
                      className='h-6 w-6 text-gray-400'
                      aria-hidden='true'
                    />
                  </div>
                  <div className='ml-5 w-0 flex-1'>
                    <dl>
                      <dt className='truncate text-sm font-medium text-gray-500'>
                        {card.name}
                      </dt>
                      <dd>
                        <div className='text-lg font-medium text-gray-900'>
                          {card.amount}
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div className='bg-gray-50 px-5 py-3'>
                <div className='text-sm'>
                  <a
                    href={card.href}
                    className='font-medium text-cyan-700 hover:text-cyan-900'
                  >
                    View all
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div> */}

      {referrals?.length === 0 ? (
        <NotFound
          title='No Referrals'
          subTitle={`You haven't Added any opportunities yet...`}
        />
      ) : (
        <div className='mt-10 sm:block'>
          <div>
            <div className='mt-2 flex flex-col'>
              <div className='min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-200'>
                  <thead>
                    <tr>
                      <th
                        className='bg-gray-50 whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-900'
                        scope='col'
                      >
                        Referrals Info
                      </th>
                      <th
                        className='bg-gray-50 whitespace-nowrap px-6 py-3 text-right text-sm font-semibold text-gray-900'
                        scope='col'
                      >
                        Organization
                      </th>
                      <th
                        className='bg-gray-50 whitespace-nowrap px-6 py-3 text-right text-sm font-semibold text-gray-900'
                        scope='col'
                      >
                        Lead To
                      </th>
                      <th
                        className=' bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900 md:block'
                        scope='col'
                      >
                        Status
                      </th>
                      <th
                        className='bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900'
                        scope='col'
                      >
                        Date
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {referrals?.map((referral) => (
                      <tr key={referral?._id} className='bg-white'>
                        <td className='w-full max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900'>
                          <Link
                            to={`/referrals/${referral?._id}`}
                            className='flex space-x-3'
                          >
                            <div className='bg-gray-100 hidden rounded-full md:flex flex-shrink-0 items-center justify-center h-8 w-8'>
                              <ArrowUpRightIcon
                                strokeWidth={2.5}
                                className='h-5 w-5 flex-shrink-0 text-sky-500 group-hover:text-gray-500'
                                aria-hidden='true'
                              />
                            </div>
                            <div className='group flex-col flex  truncate text-sm'>
                              <p className='truncate capitalize text-gray-900 group-hover:text-gray-800'>
                                {referral?.refId}
                              </p>
                              <div className='truncate flex space-x-1 items-center text-gray-500 group-hover:text-gray-900'>
                                <svg
                                  className='h-2 w-2 fill-sky-500'
                                  viewBox='0 0 6 6'
                                  aria-hidden='true'
                                >
                                  <circle cx={3} cy={3} r={3} />
                                </svg>{' '}
                                <div> {referral?.listingId?.title}</div>
                              </div>
                            </div>
                          </Link>
                        </td>
                        <td className='whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500'>
                          <span className='font-medium capitalize text-gray-900'>
                            {referral?.organization}
                          </span>
                        </td>
                        <td className='whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500'>
                          <span className='font-medium capitalize text-gray-900'>
                            {referral?.leadTo?.firstName}{' '}
                            {referral?.leadTo?.lastName}
                          </span>
                        </td>
                        <td className=' whitespace-nowrap px-6 py-4 text-sm text-gray-500 '>
                          <span
                            className={classNames(
                              statusStyles[referral?.status],
                              'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium capitalize'
                            )}
                          >
                            {referral?.status}
                          </span>
                        </td>
                        <td className='whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500'>
                          <time
                            dateTime={moment
                              .utc(referral?.createdDate)
                              .format('MMM DD, YYYY')}
                          >
                            {moment
                              .utc(referral?.createdDate)
                              .format('MMM DD, YYYY')}
                          </time>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Pagination */}
                {/* <nav
                className='flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6'
                aria-label='Pagination'
              >
                <div className='hidden sm:block'>
                  <p className='text-sm text-gray-700'>
                    Showing <span className='font-medium'>1</span> to{' '}
                    <span className='font-medium'>10</span> of{' '}
                    <span className='font-medium'>20</span> results
                  </p>
                </div>
                <div className='flex flex-1 justify-between gap-x-3 sm:justify-end'>
                  <a
                    href='#'
                    className='relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400'
                  >
                    Previous
                  </a>
                  <a
                    href='#'
                    className='relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400'
                  >
                    Next
                  </a>
                </div>
              </nav> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
