import { Switch } from '@headlessui/react';
import { DocumentArrowUpIcon } from '@heroicons/react/24/outline';
import Uppy from '@uppy/core';
import { DashboardModal, useUppy } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';

export const PropertyGallery = () => {
  const { setValue, watch, getValues } = useFormContext();

  const [open, setOpen] = useState(false);

  const coverImage = watch('coverImage');

  const fields = getValues('images');

  const localUrl = 'https://stockappy-backend.herokuapp.com';

  const uppyConfig = useUppy(() => {
    return new Uppy({
      restrictions: {
        maxNumberOfFiles: 20,
        maxFileSize: 1000000 * 10,
        allowedFileTypes: ['image/*'],
      },
      autoProceed: false,
    }).use(XHRUpload, {
      endpoint: `${localUrl}/image`,
    });
  });

  uppyConfig
    .off('complete', () => {})
    .on('complete', (result) => {
      const body = result.successful.map((file) => file.response?.body.image);

      const images = fields?.length ? [...fields, ...body] : body;

      setValue('images', images);
    });

  return (
    <section>
      <div>
        <div className='sm:grid sm:grid-cols-3 pb-5 sm:items-start sm:gap-4 sm:pt-5'>
          <label
            htmlFor='username'
            className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
          >
            Upload Images
          </label>
          <div className='mt-1 sm:col-span-2 sm:mt-0'>
            <button
              onClick={() => setOpen(true)}
              type='button'
              className='inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
            >
              <DocumentArrowUpIcon
                className='-ml-0.5 mr-2 h-4 w-4'
                aria-hidden='true'
              />
              Upload
            </button>
          </div>
        </div>
      </div>

      <DashboardModal
        uppy={uppyConfig}
        closeAfterFinish
        note='Add a agency logo here...'
        closeModalOnClickOutside
        open={open}
        onRequestClose={() => {
          setOpen(false);
        }}
      />

      {fields?.length > 0 ? (
        <div className='mt-8 flex flex-col'>
          <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Name
                      </th>

                      <th
                        scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                      >
                        Cover Image
                      </th>

                      {/* <th
                        scope='col'
                        className='relative py-3.5 pl-3 pr-4 sm:pr-6'
                      >
                        <span className='sr-only'>Edit</span>
                      </th> */}
                    </tr>
                  </thead>

                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {fields.map((file) => (
                      <tr key={file}>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='h-10 w-10 flex-shrink-0'>
                              <img
                                height={300}
                                width={300}
                                className='h-10 w-10 rounded-md'
                                src={file}
                                alt=''
                              />
                            </div>
                            <div className='ml-4'>
                              <div className='font-medium text-gray-900'>
                                {file.name}
                              </div>
                              <div className='text-gray-500'>{file.type}</div>
                            </div>
                          </div>
                        </td>

                        <td>
                          <Switch.Group as='div' className='flex items-center'>
                            <Switch
                              onChange={() => {
                                setValue('coverImage', file);
                              }}
                              className={classNames(
                                file === coverImage
                                  ? 'bg-sky-600'
                                  : 'bg-gray-200',
                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2'
                              )}
                            >
                              <span
                                aria-hidden='true'
                                className={classNames(
                                  file === coverImage
                                    ? 'ltr:translate-x-5 translate-x-5 rtl:-translate-x-5'
                                    : 'translate-x-0',
                                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                )}
                              />
                            </Switch>
                            <Switch.Label as='span' className='mx-3'>
                              <span className='text-sm font-medium text-gray-900'>
                                Make Cover Image
                              </span>
                            </Switch.Label>
                          </Switch.Group>
                        </td>
                        {/* <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                          <div className='flex space-x-3 items-center'>
                            <button
                            //   onClick={() => {
                            //     remove(index);
                            //   }}
                            >
                              <TrashIcon className='h-6 w-6' />
                            </button>
                          </div>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </section>
  );
};
