import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

// Pages
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import Verification from './pages/Auth/Verification';
import ForgotPassword from './pages/Auth/ForgotPassword';
import ResendForgotPassEmail from './pages/Auth/ResendForgotPassEmail';
import ResetPassword from './pages/Auth/ResetPassword';
import ServiceWorkerRegister from './pages/Auth/ServiceWorkerRegister';
import ConfirmVerification from './pages/Auth/ConfirmVerification';
import ContactUs from './pages/ContactUs';
import Team from './pages/team';
import Home from './pages/Home';
import PropertyDetail from './pages/Property/PropertyDetail';
import UserProperties from './pages/UserProperties';
import Pricing from './pages/Pricing';

import RecentProperties from './pages/RecentProperties';
import FeaturedProperties from './pages/Property/FeaturedProperties';
import UserAds from './pages/MyAds';
import ProfileManagement from './pages/Auth/ProfileManagement';
import Error from './pages/Error';
import ProtectedRoutes from './HOC/ProtectedRoutes';
import Request from './pages/Request';
import PostAds from './pages/PostAds';
import EditAd from './pages/EditServiceAd';
import { Terms } from './pages/Terms';
import { Acceptable } from './pages/Acceptable';
import { Safety } from './pages/Safety';
// import { Cookies } from './pages/Cookies';
import { Privacy } from './pages/Privacy';
import Dashboard from './pages/Dashboard';
import RealtorPage from './pages/RealtorPage';
import AgencyMessages from './pages/Auth/agencyMessages';
import { AddPropertyPage } from './pages/Add-Property-Page';
import { EditPropertyPage } from './pages/Edit-Property-Page';
import { PartnersPage } from './pages/Partners/PartnersPage';
import { useDispatch } from 'react-redux';
import { isLoggedIn } from './features/userAuthentication';
import Referrals from './pages/Referrals';
import Referral from './pages/Referral';
import CreateReferal from './pages/Create-Referral';
import Members from './pages/Members';
import { Events } from './pages/Events';
import { CreateEvent } from './pages/Create-Event';
import { Opportunities } from './pages/Opportunities';
import { Member } from './pages/Signup/Member';
import { Partner } from './pages/Signup/Partner';
import { ConfirmMemberVerfication } from './pages/ConfirmMemberVerification.jsx';
import { ConfirmPartnerVerfication } from './pages/ConfirmPartnerVerification';
import { MemberProfile } from './pages/MemberProfile';
import { PartnerProfile } from './pages/PartnerProfile';
import { Networking } from './pages/Networking';
import { About } from './pages/About';

const Routes = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(isLoggedIn());
  }, [dispatch]);

  return (
    <Switch>
      <Route exact path='/' component={Home} />
      <Route exact path='/sign-up/partner' component={Partner} />
      <Route exact path='/sign-up/member' component={Member} />
      <Route exact path='/terms' component={Terms} />
      <Route exact path='/acceptable' component={Acceptable} />
      <Route exact path='/safety' component={Safety} />
      <Route exact path='/privacy' component={Privacy} />

      <Route exact path='/agency/:id' component={RealtorPage} />
      <Route exact path='/sign-in' component={Login} />
      <Route exact path='/register' component={Register} />
      <Route
        exact
        path='/register/service-provider'
        component={ServiceWorkerRegister}
      />
      <Route exact path='/verify' component={Verification} />
      <Route
        exact
        path='/verify/partner/:id'
        component={ConfirmPartnerVerfication}
      />
      <Route
        exact
        path='/verify/member/:id'
        component={ConfirmMemberVerfication}
      />
      <Route exact path='/reset-password/:id' component={ResetPassword} />
      <Route exact path='/confirm/:id' component={ConfirmVerification} />
      <Route exact path='/forgot-password' component={ForgotPassword} />
      <Route
        exact
        path='/forgot-password/resend/:id'
        component={ResendForgotPassEmail}
      />
      <Route exact path='/team' component={Team} />
      <Route exact path='/pricing' component={Pricing} />
      <Route exact path='/contact-us' component={ContactUs} />
      <Route exact path='/property/:id' component={PropertyDetail} />
      <Route exact path='/recent-properties' component={RecentProperties} />
      <Route exact path='/featured-properties' component={FeaturedProperties} />
      <Route exact path='/networking' component={Networking} />
      <Route exact path='/about' component={About} />
      <ProtectedRoutes>
        <Route exact path='/create-event' component={CreateEvent} />
        <Route exact path='/events' component={Events} />
        <Route exact path='/members' component={Members} />
        <Route exact path='/create-referrals' component={CreateReferal} />
        <Route exact path='/referrals/:id' component={Referral} />
        <Route exact path='/opportunity/:id' component={Referral} />
        <Route exact path='/referrals' component={Referrals} />
        <Route exact path='/opportunities' component={Opportunities} />
        <Route exact path='/member/profile' component={MemberProfile} />
        <Route exact path='/partner/profile' component={PartnerProfile} />
        <Route exact path='/listings' component={UserProperties} />
        <Route exact path='/partners' component={PartnersPage} />
        <Route exact path='/dashboard' component={Dashboard} />
        <Route exact path='/service/add' component={PostAds} />
        <Route exact path='/my-services' component={UserAds} />
        <Route exact path='/property/edit/:id' component={EditPropertyPage} />
        <Route exact path='/service/edit/:id' component={EditAd} />
        <Route exact path='/create-listing' component={AddPropertyPage} />
        <Route exact path='/profile' component={ProfileManagement} />
        <Route exact path='/p/enquiries/:id' component={Request} />
        <Route exact path='/enquiries' component={AgencyMessages} />
      </ProtectedRoutes>
      <Route path='*' component={Error} />
    </Switch>
  );
};

export default Routes;
