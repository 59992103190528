import React from 'react';
import Banner from '../components/properties-components/home/banner';
import Footer from '../components/global-components/footer';
import { PricingSection } from '../components/Pricing';
import Navbar from '../components/global-components/navbar';

function CTA() {
  return (
    <div className='bg-indigo-700'>
      <div className='px-6 py-24 sm:px-6 sm:py-32 lg:px-8'>
        <div className='mx-auto max-w-2xl text-center'>
          <h2 className='text-3xl font-bold tracking-tight text-white sm:text-4xl'>
            Boost your productivity.
            <br />
            Start using our app today.
          </h2>
          <p className='mx-auto mt-6 max-w-xl text-lg leading-8 text-indigo-200'>
            Incididunt sint fugiat pariatur cupidatat consectetur sit cillum
            anim id veniam aliqua proident excepteur commodo do ea.
          </p>
          <div className='mt-10 flex items-center justify-center gap-x-6'>
            <a
              href='#'
              className='rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white'
            >
              Get started
            </a>
            <a href='#' className='text-sm font-semibold leading-6 text-white'>
              Learn more <span aria-hidden='true'>→</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

const Home = () => {
  return (
    <section>
      <Navbar />
      <Banner />
      <CTA />
      <PricingSection />
      <Footer />
    </section>
  );
};

export default Home;
