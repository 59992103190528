import React, { useState } from 'react';
import { useGetPartners } from '../../hooks/useGetPartners';
import { AddPartnerForm, AddPartnerModal } from './AddPartnerForm';
import { Layout } from '../../components/Layout';
import classNames from 'classnames';
import { PencilIcon } from '@heroicons/react/24/outline';
import { Modal } from '../../components/Modal';
import { Dialog } from '@headlessui/react';
import { useGetProfile } from '../../hooks/useGetProfile';
import { NotFound } from '../../components/NotFound';

const statusStyles = {
  ACTIVE: 'bg-green-100 text-green-800',
  PENDING: 'bg-yellow-100 text-yellow-800',
  failed: 'bg-gray-100 text-gray-800',
};

const Partner = ({ partner }) => {
  const [open, setOpen] = useState(false);
  return (
    <tr className='bg-white'>
      <Modal isOpen={open} onClose={() => setOpen(false)}>
        <div className='flex flex-col'>
          <Dialog.Title
            as='h3'
            className='text-base text-center font-semibold leading-6 text-gray-900'
          >
            Update {partner.companyName}
          </Dialog.Title>
          <div className='mt-0 text-center'>
            <p className='text-sm text-gray-500'>
              You can update the data from {partner.companyName} here
            </p>
          </div>

          <AddPartnerForm isEditing defaultValues={partner} setOpen={setOpen} />
        </div>
      </Modal>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-900'>
        <div className='flex items-center cursor-pointer'>
          <span className='relative  inline-block flex-shrink-0'>
            <img
              className='lg:h-14 hidden md:block lg:w-14 h-14 w-14 border rounded-md object-cover'
              src={partner.imageUrl}
              alt=''
            />
          </span>
          <div className='md:ml-4'>
            <div className='flex flex-col'>
              <span className='font-medium hidden md:block capitalize text-gray-900'>
                {partner?.firstName} {partner?.lastName}
              </span>
              <p className='truncate text-gray-500 group-hover:text-gray-900'>
                {partner?.companyName}
              </p>
            </div>
          </div>
        </div>
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500'>
        <p className='truncate text-gray-500 group-hover:text-gray-900'>
          {partner.phoneNumber}
        </p>
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500'>
        <span className='font-medium text-gray-900'>{partner.website} </span>
      </td>
      <td className='whitespace-nowrap px-6 py-4  text-sm text-gray-500'>
        <span className='font-medium text-gray-900'>{partner.email} </span>
      </td>
      <td className=' whitespace-nowrap px-6 py-4 text-sm text-gray-500 '>
        <span
          className={classNames(
            statusStyles[partner.accessStatus],
            'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium capitalize'
          )}
        >
          {partner.accessStatus}
        </span>
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500'>
        <button onClick={() => setOpen(true)}>
          <PencilIcon className='h-6 w-6' />
        </button>
      </td>
    </tr>
  );
};

export const PartnersPage = () => {
  const { data } = useGetProfile();

  const { isLoading, partners } = useGetPartners({
    userId: data?.profile?._id,
  });

  return (
    <Layout loading={isLoading}>
      <AddPartnerModal />

      <div className='mt-10 block'>
        {partners?.length === 0 ? (
          <NotFound
            title='No Partners Found'
            subTitle='Get started by adding a new partner'
          />
        ) : (
          <div>
            <div className='mt-2 flex flex-col'>
              <div className='min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-200'>
                  <thead>
                    <tr>
                      <th
                        className='bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900'
                        scope='col'
                      >
                        Partner
                      </th>
                      <th
                        className='bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900'
                        scope='col'
                      >
                        Contact Info
                      </th>
                      <th
                        className='bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900'
                        scope='col'
                      >
                        Website
                      </th>
                      <th
                        className='bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900 '
                        scope='col'
                      >
                        Email
                      </th>
                      <th
                        className='bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900 '
                        scope='col'
                      >
                        Status
                      </th>
                      <th
                        className='bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900'
                        scope='col'
                      />
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {partners?.map((partner) => (
                      <Partner key={partner._id} partner={partner} />
                    ))}
                  </tbody>
                </table>

                {/* <nav
                className='flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6'
                aria-label='Pagination'
              >
                <div className='hidden sm:block'>
                  <p className='text-sm text-gray-700'>
                    Showing <span className='font-medium'>1</span> to{' '}
                    <span className='font-medium'>10</span> of{' '}
                    <span className='font-medium'>20</span> results
                  </p>
                </div>
                <div className='flex flex-1 justify-between gap-x-3 sm:justify-end'>
                  <div className='relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400'>
                    Previous
                  </div>
                  <div className='relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400'>
                    Next
                  </div>
                </div>
              </nav> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};
