import React from 'react';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient, useMutation } from 'react-query';
import notifications from '../../notifications';
import { Modal } from '../../Modal';
import { Loader } from '../../Loader';
import { ErrorMessage } from '@hookform/error-message';
import { Dialog } from '@headlessui/react';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { useParams } from 'react-router';

const RequestModal = ({
  setShowReqModal,
  showReqModal,
  propertyTitle,
  requestId,
  requestorName,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    shouldUnregister: false,
  });
  const queryClient = useQueryClient();

  const { id } = useParams();

  const sendReply = async (data) => {
    try {
      const res = await axios.post(`/property/request/${requestId}`, data);
      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  };

  const mutation = useMutation(sendReply, {
    onSuccess: () => {
      reset();
      setShowReqModal(false);
      notifications.success('Success', 'Replied Successfully');
      // queryClient.invalidateQueries('propertiesByCityCount');
      queryClient.invalidateQueries([`requests`, id]);
    },
    onError: () => {
      notifications.error('Error', 'Try Again Later!');
    },
  });

  const onSubmit = (data) => {
    const payload = {
      response: data.response,
      propertyTitle,
    };
    mutation.mutate(payload);
  };

  return (
    <Modal isOpen={showReqModal} onClose={() => setShowReqModal(!showReqModal)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-sky-100'>
          <ArrowUturnLeftIcon
            className='h-6 w-6 text-sky-600'
            aria-hidden='true'
          />
        </div>
        <Dialog.Title
          as='h3'
          className='text-base mt-3 text-center font-semibold leading-6 text-gray-900'
        >
          Reply For {requestorName}
        </Dialog.Title>
        <div className='mt-1 text-center'>
          <p className='text-sm text-gray-500'>
            Write a reply to {requestorName} for the {propertyTitle}.
          </p>
        </div>

        <div>
          <div className='mt-6'>
            <Controller
              control={control}
              rules={{ required: true }}
              name='response'
              as={
                <textarea
                  rows={5}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                  placeholder='Reply To Request...'
                />
              }
            />
            <ErrorMessage
              name='response'
              errors={errors}
              message='Please enter a reply'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>

        <div className='mt-5 space-y-3 sm:space-y-0 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
          <button
            className='mt-3  inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto'
            type='button'
            onClick={() => setShowReqModal(false)}
          >
            Cancel
          </button>
          <button
            className='inline-flex  w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 sm:ml-3 sm:w-auto'
            disabled={mutation.isLoading}
          >
            {mutation.isLoading && <Loader className='mr-2' size='sm' />}
            Send Request
          </button>
        </div>
      </form>
    </Modal>
  );
};
export default RequestModal;
