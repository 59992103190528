import axios from 'axios';
import { useQuery } from 'react-query';

const getPropertyRequests = async (propertyId) => {
  try {
    const res = await axios.get(`property/${propertyId}/requests`);
    return res.data.requests;
  } catch (err) {
    throw err.response;
  }
};
export const useGetPropertyRequests = ({ id }) => {
  const { data, isLoading } = useQuery(
    ['requests', id],
    () => getPropertyRequests(id),
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );
  return {
    requests: data,
    isLoading,
  };
};
