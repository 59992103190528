import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from '../../Modal';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid';
import { Dialog } from '@headlessui/react';
import { Loader } from '../../Loader';
import { ErrorMessage } from '@hookform/error-message';

const ContactAgencyModal = ({ property, isOpen, onClose }) => {
  const { register, handleSubmit, errors, reset } = useForm();

  const { _id, title, userId } = property || {};
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    data.agencyName = userId?.name;
    data.agencyEmail = userId?.email;
    data.propertyTitle = title;
    axios
      .patch(`/property/${_id}/request`, data)
      .then(() => {
        setLoading(false);
        reset();
        onClose();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div>
        <div className='mt-3 px-3'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-sky-100'>
              <EnvelopeIcon
                className='h-6 w-6 text-sky-600'
                aria-hidden='true'
              />
            </div>
            <Dialog.Title
              as='h3'
              className='text-base mt-3 text-center font-semibold leading-6 text-gray-900'
            >
              Contact {title}
            </Dialog.Title>
            <div className='mt-1 text-center'>
              <p className='text-sm text-gray-500'>
                Enter your contact details, and we'll let the listing manager
                know you want to submit an application. If they're interested,
                they'll contact you with next steps.
              </p>
            </div>

            <div className='mt-6'>
              <input
                type='text'
                name='name'
                className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                ref={register({ required: true })}
                placeholder='Name'
              />
              <ErrorMessage
                name='name'
                errors={errors}
                message='Please enter your name'
                render={({ message }) => (
                  <p className='text-red-500 mt-2 text-sm'>{message}</p>
                )}
              />
            </div>
            <div className='mt-4'>
              <input
                type='number'
                name='phoneNumber'
                className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                ref={register({ required: true })}
                placeholder='Phone'
              />

              <ErrorMessage
                name='phoneNumber'
                errors={errors}
                message='Please enter your Phonenumber'
                render={({ message }) => (
                  <p className='text-red-500 mt-2 text-sm'>{message}</p>
                )}
              />
            </div>
            <div className='mt-4'>
              <input
                type='email'
                name='email'
                className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                ref={register({ required: true })}
                placeholder='Email'
              />
              <ErrorMessage
                name='message'
                errors={errors}
                message='Please enter your email'
                render={({ message }) => (
                  <p className='text-red-500 mt-2 text-sm'>{message}</p>
                )}
              />
            </div>
            <div className='mt-4'>
              <textarea
                rows='4'
                name='message'
                ref={register({ required: true })}
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                placeholder='Why would you like to apply for this property?'
              />
              <ErrorMessage
                name='message'
                errors={errors}
                message='Please enter a message'
                render={({ message }) => (
                  <p className='text-red-500 mt-2 text-sm'>{message}</p>
                )}
              />
            </div>
            <div className='mt-4 space-y-3 sm:space-y-0 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
              <button
                className='mt-3  inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto'
                type='button'
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className='inline-flex  w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 sm:ml-3 sm:w-auto'
                disabled={loading}
              >
                {loading && <Loader className='mr-2' size='sm' />}
                Send Request
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export const AgencyDetails = ({ details }) => {
  const [openModal, setOpenModal] = useState(false);

  const { userId, landLordPhone, partnerId, memberId } = details;

  const onClose = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <ContactAgencyModal
        isOpen={openModal}
        property={details}
        onClose={onClose}
      />
      <div className='col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow'>
        <div className='flex w-full items-center justify-between space-x-6 p-6'>
          <div className='flex w-full justify-between'>
            <div>
              <p className='text-sm text-gray-500'>Listing Managed By</p>
              <p className='text-sm font-semibold text-gray-900'>
                {partnerId
                  ? `${partnerId?.firstName} ${partnerId?.lastName}`
                  : memberId
                  ? `${memberId?.firstName} ${memberId.lastName}`
                  : userId?.name}
              </p>
            </div>
            <div className='flex-shrink-0'>
              <img
                className='h-10 w-10 object-cover rounded-full'
                src={
                  partnerId?.imageUrl ?? memberId?.imageUrl ?? userId?.imageUrl
                }
                alt=''
              />
            </div>
          </div>
        </div>

        <div className='-mt-px flex divide-x divide-gray-200'>
          <div className='flex w-0 flex-1'>
            <button
              type='button'
              onClick={() => setOpenModal(true)}
              className='relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900'
            >
              <EnvelopeIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
              Message
            </button>
          </div>
          <div className='-ml-px flex w-0 flex-1'>
            <a
              href={`tel:${userId?.phoneNumber ?? landLordPhone}`}
              className='relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900'
            >
              <PhoneIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
              Call
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
