import axios from 'axios';
import { useQuery } from 'react-query';

const fetchSingleReferral = async (id) => {
  try {
    const res = await axios.get(`/referral/${id}`);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const useSingleReferral = ({ id }) => {
  const { data, isLoading, error } = useQuery(
    ['REFERRAL', id],
    () => fetchSingleReferral(id),
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );

  return {
    referral: data,
    isLoading,
    error,
  };
};
