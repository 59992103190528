import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const DashboardCardLoader = () => {
  return (
    <div className='bg-white shadow-sm rounded-lg px-4 py-5 row mx-0'>
      <div className='icon-box bg-icon mr-4'>
        <Skeleton count={1} />
      </div>
      <div className='mb-0 col px-0 mt-2 text-capitalize'>
        <h3 className='mb-0'>
          <Skeleton count={1} />
        </h3>
        <h5 className='text-body font-weight-bold'>
          <Skeleton count={1} />
        </h5>
      </div>
    </div>
  );
};
