import React, { useState } from 'react';
// import { ReplyModal } from './replyModal';
import moment from 'moment';

export const MessageItem = ({ message }) => {
  const [showReqModal, setShowReqModal] = useState(false);

  return (
    <tr className='bg-white'>
      {/* {showReqModal ? (
        <ReplyModal
          showReqModal={showReqModal}
          setShowReqModal={setShowReqModal}
          senderEmail={msg.senderEmail}
          requestId={msg._id}
          propertyTitle={`Response To Croydon Commercial`}
        />
      ) : null}
     */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-900'>
        {message?.name}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-900'>
        {message?.email}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-900'>
        {message?.message}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-900'>
        {message?.phoneNumber}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-900'>
        {moment.utc(message?.createdDate).format('MMM DD, YYYY')}
      </td>
      {/* <td className='text-center'>
        <button
          className='btn btn-secondary py-1'
          onClick={() => setShowReqModal(true)}
        >
          Reply
        </button>
      </td> */}
    </tr>
  );
};
