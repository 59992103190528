import axios from 'axios';
import { useQuery } from 'react-query';

const fetchReferrals = async ({ leadFrom, leadTo }) => {
  try {
    const res = await axios.get('/referral', {
      params: {
        leadFrom,
        leadTo,
      },
    });
    return res.data.referral;
  } catch (err) {
    throw err.response.data;
  }
};

export const useReferrals = ({ leadFrom, leadTo } = {}) => {
  const { data, isLoading, error } = useQuery(
    ['REFERRALS', leadFrom, leadTo],
    () => fetchReferrals({ leadFrom, leadTo }),
    {
      refetchOnWindowFocus: false,
      enabled: !!leadFrom || !!leadTo,
    }
  );

  return {
    referrals: data,
    isLoading,
    error,
  };
};
