import { useMembers } from '../../hooks/useMembers';
import { Loader } from '../Loader';
import { NotFound } from '../NotFound';
import { Member } from './Member';

export const MemberList = ({ groupId }) => {
  const { members, isLoading } = useMembers({ groupId });

  if (isLoading) {
    return (
      <div className='flex mt-4 items-center justify-center'>
        <Loader className={'h-20 w-20'} />
      </div>
    );
  }

  if (members?.length === 0 && !isLoading) {
    return (
      <NotFound title='No Members' subTitle='No Members Found in this group.' />
    );
  }

  return (
    <div>
      <ul className='grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3'>
        {members?.map((member) => (
          <Member key={member._id} member={member} />
        ))}
      </ul>
    </div>
  );
};
