import { useState } from 'react';
import { PartnerSignupForm } from '../../../components/PartnerSignupForm';
import { Link } from 'react-router-dom';
import { MessageCard } from '../../../components/MessageCard';
export const Partner = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  return (
    <div className='h-screen flex flex-col'>
      <div className=' px-4 py-6 border-b sm:px-6 lg:px-8'>
        <div className='relative flex justify-between items-center flex-1'>
          <div className='flex'>
            <div className='flex-shrink-0 hidden md:flex items-center'>
              <Link to='/'>
                <img
                  className='mx-auto h-10 w-auto'
                  alt='Workflow'
                  src='https://bdibucket1.s3.eu-west-2.amazonaws.com/Coduq_LOGO_2023.png'
                />
              </Link>
            </div>
          </div>
          <div className='flex space-x-4'>
            <div>
              <Link
                to='/sign-in'
                className='inline-block rounded-lg py-2 px-2 text-sm text-slate-600 hover:bg-slate-100 hover:text-slate-900'
              >
                Sign in
              </Link>
            </div>
            <Link
              to='/sign-up/member'
              className=' group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-sky-600 text-white hover:text-slate-100 hover:bg-sky-500 active:bg-sky-800 active:text-sky-100 focus-visible:outline-sky-600'
            >
              Become a Member
            </Link>
          </div>
        </div>
      </div>
      <div className='flex flex-1 flex-col justify-center px-4 py-12 sm:px-6  lg:px-20 xl:px-24'>
        <div className='mx-auto w-full max-w-[500px] lg:w-[600px]'>
          {isSuccess ? (
            <MessageCard
              title={'Account Created Successfully!'}
              subTitle={
                'Please check your email to confirm your account Verification'
              }
            />
          ) : (
            <div>
              <div className='text-center'>
                <h2 className='mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900'>
                  Become a Partner
                </h2>
                <p className='mt-2 text-sm leading-6 text-gray-500'>
                  Create your account for free.
                </p>
              </div>

              <PartnerSignupForm
                isEditing={false}
                setIsSuccess={setIsSuccess}
              />
              <p className='mt-4 text-sm text-center leading-6 text-gray-500'>
                By submitting this form, I agree to the{' '}
                <Link
                  className='font-medium hover:underline text-sky-600'
                  to='/terms'
                >
                  Terms and conditions
                </Link>
                .
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
