import React, { useState } from 'react';
import { CalendarDaysIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { EventForm } from '../EventForm';
import { Modal } from '../Modal';
import { useDeleteEvent } from '../../hooks/useDeleteEvent';
import { Loader } from '../Loader';
import moment from 'moment';

export const EventItem = ({ referral, event }) => {
  const [open, setOpen] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const { mutation } = useDeleteEvent();

  const onCloseDelete = () => {
    setDeleteModal(false);
  };

  const handleDelete = () => {
    mutation.mutateAsync(
      { id: event._id },
      {
        onSuccess: () => {
          onCloseDelete();
        },
      }
    );
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <li>
      <Modal isOpen={deleteModal} onClose={onCloseDelete}>
        <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100'>
          <TrashIcon className='h-6 w-6 text-red-600' aria-hidden='true' />
        </div>
        <Dialog.Title
          as='h3'
          className='text-base mt-3 text-center font-semibold leading-6 text-gray-900'
        >
          {event.subject}
        </Dialog.Title>
        <div className='mt-1 text-center'>
          <p className='text-sm text-gray-500'>
            Are you sure you want to delete this event?
          </p>
        </div>

        <div className='mt-5 space-y-3 md:space-y-0 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
          <button
            className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto'
            type='button'
            onClick={onCloseDelete}
          >
            Cancel
          </button>
          <button
            onClick={handleDelete}
            className='inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto'
            disabled={mutation.isLoading}
          >
            {mutation.isLoading && <Loader className='mr-2' size='sm' />}
            Delete Event
          </button>
        </div>
      </Modal>
      <Modal isOpen={open} onClose={onClose}>
        <EventForm lead={referral} isEditing event={event} onClose={onClose} />
      </Modal>
      <div className='group relative flex items-center px-5 py-6'>
        <a
          rel='noreferrer'
          target='_blank'
          href={event.link}
          className='-m-1 block flex-1 p-1'
        >
          <div
            className='absolute inset-0 group-hover:bg-gray-50'
            aria-hidden='true'
          />
          <div className='relative flex min-w-0 flex-1 items-center'>
            <span className='relative inline-block flex-shrink-0'>
              <div className='h-10 w-10 flex items-center justify-center rounded-full bg-sky-500'>
                <CalendarDaysIcon className='h-6 w-6 text-white' />
              </div>
            </span>
            <div className='ml-4 truncate'>
              <p className='truncate uppercase text-sm font-medium text-gray-900'>
                {event.subject}
              </p>
              <p className='truncate text-sm text-gray-500'>
                Due On:{' '}
                {moment.utc(event.eventDate).format('MMM Do, YYYY hh:mm:a')}
              </p>
            </div>
          </div>
        </a>
        <Menu
          as='div'
          className='relative ml-2 inline-block flex-shrink-0 text-left'
        >
          <Menu.Button className='group relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
            <span className='absolute -inset-1.5' />
            <span className='sr-only'>Open options menu</span>
            <span className='flex h-full w-full items-center justify-center rounded-full'>
              <EllipsisVerticalIcon
                className='h-5 w-5 text-gray-400 group-hover:text-gray-500'
                aria-hidden='true'
              />
            </span>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items className='absolute right-9 top-0 z-10 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
              <div className='py-1'>
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => setOpen(true)}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      Edit
                    </div>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => setDeleteModal(true)}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      Delete
                    </div>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </li>
  );
};
