import React from 'react';
import { Layout } from '../../components/Layout';
import { useGetProfile } from '../../hooks/useGetProfile';
import { AddPartnerForm } from '../Partners/AddPartnerForm';

export const PartnerProfile = () => {
  const { data, isLoading } = useGetProfile();

  const successAction = () => {};

  return (
    <Layout loading={isLoading}>
      <div className='mx-auto w-full max-w-[500px] lg:w-[600px]'>
        <AddPartnerForm
          isEditing
          defaultValues={data}
          setIsSuccess={successAction}
        />
      </div>
    </Layout>
  );
};
