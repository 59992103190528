import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

export const MessageCard = ({ title, subTitle, message }) => {
  return (
    <div>
      <main className='mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8'>
        <div className='flex flex-shrink-0 justify-center'>
          <div className='inline-flex'>
            <CheckCircleIcon
              strokeWidth={1}
              className='h-40 w-40 text-green-500'
            />
          </div>
        </div>
        <div className='py-4'>
          <div className='text-center'>
            <p className='text-base font-semibold text-black'>{title}</p>

            <p className='mt-2 text-base text-gray-500'>{subTitle}</p>
            <div className='mt-6'>
              <Link
                to={message ? '/' : '/sign-in'}
                className='text-base font-medium text-gray-500 hover:text-gray-400'
              >
                {!message ? <span aria-hidden='true'>&larr; </span> : null}

                {message ?? 'Back to Sign in'}
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
