import React from 'react';
import { numFormatter } from '../../utils/numFormatter';
import { numberWithCommas } from '../../utils/numberWithCommas';

export const FormatPrice = ({ item }) => {
  const { category, priceWeek, priceType, price } = item || {};

  return (
    <div>
      {category?.value === 'to rent' &&
      priceType?.value !== 'price on application (poa)' ? (
        <div className='flex flex-col text-right'>
          <div className='text-3xl font-semibold'>
            £ {numberWithCommas(price)} /mo
          </div>
          <div className='text-gray-500 text-sm'>
            £ {numberWithCommas(priceWeek)} /wk
          </div>
        </div>
      ) : (
        <>
          {priceType?.value === 'price on application (poa)' ? (
            <div className='font-semibold text-3xl'>POA</div>
          ) : priceType?.value === 'offers in excess of (oieo)' ? (
            <div className='text-2xl font-semibold '>
              OIEO £ {numFormatter(price)}
            </div>
          ) : priceType?.value === 'price per sq ft / sq m' ? (
            <div className='text-2xl font-semibold uppercase'>
              £ {numFormatter(price)}{' '}
              {item?.unit?.value === 'square feet (sq. ft)' ? 'psf' : 'psm'}
            </div>
          ) : (
            <div className='text-2xl font-semibold '>
              £ {numberWithCommas(price)}
            </div>
          )}
        </>
      )}
    </div>
  );
};
