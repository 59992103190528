import React, { useState } from 'react';
import { ResetPasswordForm } from '../../components/auth-components/ResetPasswordForm';
import { useRedirect } from '../../hooks/useRedirect';
import { MessageCard } from '../../components/MessageCard';
import { Link } from 'react-router-dom';

const ResetPassword = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  useRedirect();

  return (
    <div className='h-screen flex flex-col'>
      <div className=' px-4 py-6 border-b sm:px-6 lg:px-8'>
        <div className='relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8'>
          <div className='flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2'>
            <div className='flex-shrink-0 flex items-center'>
              <Link to='/'>
                <img
                  className='mx-auto h-10 w-auto'
                  alt=''
                  src='https://bdibucket1.s3.eu-west-2.amazonaws.com/Coduq_LOGO_2023.png'
                />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
        {isSuccess ? (
          <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
            <MessageCard
              title='Password Updated Successfully!'
              subTitle='You can navigate back to the main screen to login to your account'
            />
          </div>
        ) : (
          <>
            <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
              <img
                className='mx-auto h-10 w-auto'
                src='https://bdibucket1.s3.eu-west-2.amazonaws.com/Coduq_LOGO_2023.png'
                alt='Your Company'
              />
              <div>
                <h2 className='my-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
                  Enter your new password
                </h2>
                <p className='mt-2 text-center text-sm text-gray-600'>
                  {`Please enter a new password to reset your password.`}
                </p>
              </div>
            </div>
            <ResetPasswordForm setIsSuccess={setIsSuccess} />

            <div className='mt-6 flex items-center justify-center'>
              <Link
                className='text-base font-medium text-gray-500 hover:text-gray-500 ltr:flex-row rtl:flex-row-reverse'
                to='/sign-in'
              >
                <span aria-hidden='true'>← </span>
                <span>Back to sign-in</span>
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
