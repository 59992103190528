import React from 'react';
import { getDistanceFromLatLonInKm } from '../../../utils/distanceBetweenTwoCoord';
import { ReactComponent as HouseIcon } from '../../../images/icons/house.svg';
import { ReactComponent as HeartIcon } from '../../../images/icons/heartbeat.svg';
import { ReactComponent as CapIcon } from '../../../images/icons/graduate-cap.svg';

export const NearbyPlaces = ({ details }) => {
  const { lat, lng, nearSchools, nearRestaurants, nearHospitals } = details;

  const nearbyArr = [
    {
      title: 'Education',
      icon: <CapIcon />,
      nearby: nearSchools,
    },
    {
      title: 'Health & Medical',
      icon: <HeartIcon />,
      nearby: nearHospitals,
    },
    {
      title: 'Hotels',
      icon: <HouseIcon />,
      nearby: nearRestaurants,
    },
  ];

  return (
    <div>
      <h3 className='py-5 capitalize font-semibold text-xl'>
        Areas of Interest
      </h3>
      <div>
        {nearbyArr?.map((value) => (
          <div key={value.title} className='mb-4'>
            <div className='flex items-center space-x-3'>
              <div className='bg-gray-100 h-6 w-6 md:h-8 md:w-8 p-1 rounded-full flex items-center justify-center'>
                <div className='h-4 w-4 md:h-5 md:w-5'>{value.icon}</div>
              </div>
              <div className='text-base'>{value.title}</div>
            </div>
            {value.nearby.map((item) => (
              <div key={item._id} className=' ml-11'>
                <div className='py-1.5 flex items-center justify-between text-sm text-gray-500 tracking-wide'>
                  <div className='line-clamp-1'>{item.name}</div>
                  <div className='text-black font-semibold'>
                    {getDistanceFromLatLonInKm(
                      lat,
                      lng,
                      item.geometry.location.lat,
                      item.geometry.location.lng
                    ).toFixed(2)}
                    km
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
