import React from 'react';
import { Request } from './Request';

export const RequestTable = ({ requests }) => {
  return (
    <div className='mt-2 flex flex-col'>
      <div className='min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg'>
        <table className='min-w-full divide-y divide-gray-200'>
          <thead>
            <th className='bg-gray-50 whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-900'>
              Lead From
            </th>
            <th className='bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900'>
              Email
            </th>

            <th className='bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900'>
              Message
            </th>
            <th className='bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900'>
              Status
            </th>
            <th className='bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900'>
              Lead On
            </th>
            <th className='bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900'></th>
          </thead>
          <tbody className='divide-y divide-gray-200 bg-white'>
            {requests.map((request) => (
              <Request key={request._id} request={request} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
