import axios from 'axios';
import { useQuery } from 'react-query';

const fetchMembers = async (groupId, isClusterId = false) => {
  const params = isClusterId
    ? {
        clusterId: groupId,
      }
    : {
        groupId,
      };
  try {
    const res = await axios.get('/member', {
      params,
    });
    return res.data.list;
  } catch (err) {
    throw err.response.data;
  }
};

export const useMembers = ({ groupId, isClusterId = false }) => {
  const { data, isLoading, error } = useQuery(
    ['MEMBERS', groupId],
    () => fetchMembers(groupId, isClusterId),
    {
      refetchOnWindowFocus: !!groupId,
    }
  );

  return {
    members: data,
    isLoading,
    error,
  };
};
