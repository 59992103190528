import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const deleteEvent = async (data) => {
  try {
    const res = await axios.delete(`/event/${data.id}`);
    return res.data;
  } catch (err) {
    throw err.response;
  }
};

export const useDeleteEvent = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const mutation = useMutation(deleteEvent, {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(['REFERRAL', id]);
      }, 1000);
    },
  });

  return {
    mutation,
  };
};
