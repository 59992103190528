import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Select from 'react-select';
import { useOptionsDropdown } from '../../../../hooks/useOptionGroup';
import { getOptionsByValue } from '../../../../utils/optionFormatter';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export const Details = ({ editorState, setEditorState }) => {
  const { isLoading, options } = useOptionsDropdown({
    type: 'category',
  });

  const { errors, register, control, watch } = useFormContext();

  const type = watch('type')?.value;

  const category = watch('category')?.value;

  const priceType = watch('priceType')?.value;

  // const defaultCurrency = {
  //   label: 'GBP',
  //   value: 'gbp',
  //   _id: '654b5e9643edaf001888c3bd',
  //   optionId: '654b5e9643edaf001888c3bd',
  // };

  return (
    <div>
      <div className='grid grid-cols-2 mt-4 gap-x-6 gap-y-8'>
        <div className='mb-4'>
          <label className='block text-sm font-medium leading-6 text-gray-900'>
            Price Type
          </label>
          <div className='mt-2'>
            <Controller
              as={Select}
              isClearable
              options={getOptionsByValue('price', options)}
              rules={{ required: true }}
              placeholder='Select Price Type'
              isLoading={isLoading}
              name='priceType'
              defaultValue={''}
              control={control}
            />
            <ErrorMessage
              errors={errors}
              name='priceType'
              message='This field is required.'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>

        <div>
          <label className='block text-sm font-medium leading-6 text-gray-900'>
            {category === 'to rent' ? 'Monthly Rent' : 'Price'}
          </label>
          <div className='relative mt-[7px] rounded-md shadow-sm'>
            <Controller
              control={control}
              rules={{ required: true }}
              name='price'
              min={1}
              type='number'
              placeholder='0.00'
              as={
                <input
                  id='price'
                  disabled={priceType === 'price on application (poa)'}
                  className='block w-full disabled:bg-gray-100 rounded-md border-0 py-[7px] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                />
              }
            />
          </div>
          <ErrorMessage
            errors={errors}
            name='price'
            message='Price is required.'
            render={({ message }) => (
              <p className='text-red-500 mt-2 text-sm'>{message}</p>
            )}
          />
        </div>

        {/* <div>
          <div>
            <label
              className='block text-sm font-medium leading-6 text-gray-900'
              htmlFor='currency'
            >
              Currency
            </label>
            <div className='mt-2'>
              <Controller
                control={control}
                defaultValue={defaultCurrency}
                name='currency'
                placeholder='Select Currency'
                options={getOptionsByValue('currency', options)}
                as={<Select />}
              />
            </div>
          </div>
        </div> */}
      </div>
      <div className='grid grid-cols-2 mt-4 gap-x-6 gap-y-8'>
        <div>
          <label className='block text-sm font-medium leading-6 text-gray-900'>
            Minimum No of People
          </label>
          <div className='mt-2'>
            <Controller
              control={control}
              name='numberOfMinimumPeople'
              placeholder='Minimum'
              min={1}
              type='number'
              as={
                <input className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6' />
              }
            />
            <ErrorMessage
              errors={errors}
              name='numberOfMinimumPeople'
              message='This field is required.'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>
        <div>
          <label className='block text-sm font-medium leading-6 text-gray-900'>
            Maximum No of People
          </label>
          <div className='mt-2'>
            <Controller
              control={control}
              name='numberOfMaximumPeople'
              placeholder='Minimum'
              min={1}
              type='number'
              className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
              as={<input />}
            />
            <ErrorMessage
              errors={errors}
              name='numberOfMaximumPeople'
              message='This field is required.'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>
      </div>

      <div className='mt-4'>
        <div className='grid grid-cols-2 mt-4 gap-x-6 gap-y-8'>
          {category === 'to rent' &&
          priceType !== 'price on application (poa)' ? (
            <div className='mb-4'>
              <label className='block text-sm font-medium leading-6 text-gray-900'>
                Weekly Rent
              </label>
              <div className='mt-2'>
                <input
                  name='priceWeek'
                  placeholder='£'
                  min={1}
                  type='number'
                  ref={register({ required: true })}
                  className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                />
                <ErrorMessage
                  errors={errors}
                  name='priceWeek'
                  message='This field is required.'
                  render={({ message }) => (
                    <p className='text-red-500 mt-2 text-sm'>{message}</p>
                  )}
                />
              </div>
            </div>
          ) : null}
        </div>
        {type === 'house' || type === 'flat' ? (
          <div className='grid mt-4 grid-cols-3 gap-x-6 gap-y-8'>
            <div className='mb-4'>
              <label className='block text-sm font-medium leading-6 text-gray-900'>
                Bath Rooms
              </label>
              <div className='mt-2'>
                <Controller
                  as={<input min={1} type='number' />}
                  rules={{ required: true }}
                  name='bathrooms'
                  placeholder='Bathrooms'
                  className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                  control={control}
                />

                <ErrorMessage
                  errors={errors}
                  name='bathrooms'
                  message='This field is required.'
                  render={({ message }) => (
                    <p className='text-red-500 mt-2 text-sm'>{message}</p>
                  )}
                />
              </div>
            </div>

            {type !== 'commercial' ? (
              <div className='mb-4'>
                <label className='block text-sm font-medium leading-6 text-gray-900'>
                  Bedrooms
                </label>
                <div className='mt-2'>
                  <Controller
                    as={<input min={1} type='number' />}
                    rules={{ required: true }}
                    name='bedrooms'
                    placeholder='Bedrooms'
                    className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                    control={control}
                  />

                  <ErrorMessage
                    errors={errors}
                    name='bedrooms'
                    message='This field is required.'
                    render={({ message }) => (
                      <p className='text-red-500 mt-2 text-sm'>{message}</p>
                    )}
                  />
                </div>
              </div>
            ) : null}

            <div className='mb-4'>
              <label className='block text-sm font-medium leading-6 text-gray-900'>
                Receptions
              </label>
              <div className='mt-2'>
                <Controller
                  as={<input min={1} type='number' />}
                  rules={{ required: true }}
                  placeholder='Receptions'
                  className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                  name='receptions'
                  control={control}
                />
              </div>

              <ErrorMessage
                errors={errors}
                name='receptions'
                message='This field is required.'
                render={({ message }) => (
                  <p className='text-red-500 mt-2 text-sm'>{message}</p>
                )}
              />
            </div>
          </div>
        ) : null}

        <div className='grid mt-4 grid-cols-2 gap-x-6 gap-y-8'>
          <div className='mb-4'>
            <label className='block text-sm font-medium leading-6 text-gray-900'>
              Minimum Size
            </label>
            <div className='mt-2'>
              <Controller
                control={control}
                rules={{ required: true }}
                name='availableSpaceMinimum'
                placeholder='Minimum size'
                min={1}
                type='number'
                className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                as={<input />}
              />
              <ErrorMessage
                errors={errors}
                name='availableSpaceMinimum'
                message='This field is required.'
                render={({ message }) => (
                  <p className='text-red-500 mt-2 text-sm'>{message}</p>
                )}
              />
            </div>
          </div>
          <div className='mb-4'>
            <label className='block text-sm font-medium leading-6 text-gray-900'>
              Maximum Size
            </label>
            <div className='mt-2'>
              <Controller
                control={control}
                rules={{ required: true }}
                name='availableSpaceMaximum'
                placeholder='Maximum Size'
                min={1}
                type='number'
                className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                as={<input />}
              />
              <ErrorMessage
                errors={errors}
                name='availableSpaceMaximum'
                message='This field is required.'
                render={({ message }) => (
                  <p className='text-red-500 mt-2 text-sm'>{message}</p>
                )}
              />
            </div>
          </div>
        </div>

        {/* <Col className='mb-4 ' md={12}>
          <label className='text-uppercase mb-2'>Total Property Size</label>
          <div className=' mb-0'>
            <Controller
              rules={{ required: true }}
              control={control}
              placeholder='Area'
              name='landArea'
              as={<Input type='number' min={1} className='rounded-0 ' />}
            />
            <ErrorMessage
              errors={errors}
              name='landArea'
              message='This field is required.'
              render={({ message }) => <p className='text-danger'>{message}</p>}
            />
          </div>
        </Col> */}

        <div className='align-items-center'>
          <label className='block text-sm font-medium leading-6 text-gray-900'>
            Unit
          </label>
          <div className='mt-2'>
            <Controller
              as={Select}
              options={getOptionsByValue('unit', options)}
              rules={{ required: true }}
              placeholder='Select Unit'
              isLoading={isLoading}
              name='unit'
              control={control}
            />

            <ErrorMessage
              errors={errors}
              name='unit'
              message='This field is required.'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>

        <div className='mt-10'>
          <div className='border p-2 mt-2 h-72 rounded-md overflow-scroll'>
            <Controller
              control={control}
              name='description'
              as={
                <Editor
                  placeholder='Enter Description'
                  toolbar={{
                    options: [
                      'blockType',
                      'inline',
                      'list',
                      'textAlign',
                      'link',
                    ],
                    fontSize: {
                      options: [16, 18, 24, 32, 36, 48],
                    },
                    link: {
                      inDropdown: true,

                      showOpenOptionOnHover: true,
                      defaultTargetOption: '_blank',
                      options: ['link', 'unlink'],
                    },
                    inline: {
                      options: ['bold', 'italic', 'underline', 'strikethrough'],
                    },
                    textAlign: {
                      options: ['left', 'center', 'right'],
                    },
                    list: {
                      options: ['unordered', 'ordered'],
                    },
                  }}
                  handlePastedText={() => false}
                  editorState={editorState}
                  toolbarClassName='toolbarClassName'
                  wrapperClassName='wrapperClassName'
                  editorClassName='editorClassName'
                  onEditorStateChange={setEditorState}
                />
              }
            />

            <ErrorMessage
              errors={errors}
              name='description'
              message='This field is required.'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
