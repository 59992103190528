import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import notifications from '../notifications';
import { Loader } from '../Loader';
import { ErrorMessage } from '@hookform/error-message';

function useQueryParams() {
  return new URLSearchParams(window.location.search);
}

export const ResetPasswordForm = ({ setIsSuccess }) => {
  const { id } = useParams();

  const queryParams = useQueryParams();

  const type = queryParams.get('type');

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const password = useRef();
  password.current = watch('password', '');
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    const payload = {
      ...data,
      type,
    };

    setLoading(true);
    axios
      .patch(`/profile/reset-password/${id}`, payload)
      .then(() => {
        setLoading(false);
        setIsSuccess(true);
      })
      .catch(() => {
        setLoading(false);
        notifications.error('Reset Form Error', 'Try Again Later!');
      });
  };

  return (
    <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
      <form className='space-y-6' onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label for='email' class='block text-sm font-medium text-gray-700'>
            Password
          </label>
          <div className='mt-2'>
            <input
              className='block w-full py-2.5 rounded-md border-gray-300 shadow-sm focus:border-sky-400 focus:ring-sky-400 sm:text-sm'
              type='password'
              placeholder='Enter Password...'
              name='password'
              ref={register({
                required: 'You must enter a password',
              })}
            />
            <ErrorMessage
              name='password'
              errors={errors}
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>
        <div>
          <label for='email' class='block text-sm font-medium text-gray-700'>
            Confirm Password
          </label>
          <div className='mt-2'>
            <input
              type='password'
              className='block w-full py-2.5 rounded-md border-gray-300 shadow-sm focus:border-sky-400 focus:ring-sky-400 sm:text-sm'
              placeholder='Confirm Password'
              name='confirmPassword'
              ref={register({
                validate: (value) =>
                  value === password.current || 'The password did not match',
              })}
            />

            <ErrorMessage
              name='confirmPassword'
              errors={errors}
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>
        </div>

        <div>
          <button
            className='inline-flex  justify-center rounded-lg text-sm font-semibold py-3.5 px-4 bg-sky-600 text-white hover:bg-sky-700 focus:ring-2 ring-sky-600 ring-offset-2 w-full'
            type='submit'
            disabled={loading}
          >
            {loading ? <Loader className={'mr-2'} /> : null}
            Reset Password
          </button>
        </div>
      </form>
    </div>
  );
};
