import { Controller, useForm } from 'react-hook-form';
import { Layout } from '../../components/Layout';
import { useListing } from '../../hooks/useListing';
import ReactSelect from 'react-select';

import { DocumentArrowUpIcon } from '@heroicons/react/24/outline';
import Uppy from '@uppy/core';
import { DashboardModal, useUppy } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import React, { useEffect, useState } from 'react';
import { PaperClipIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { Switch } from '@headlessui/react';
import { useMembers } from '../../hooks/useMembers';
import { useSelector } from 'react-redux';
import { useCreateReferral } from '../../hooks/useCreateReferral';
import { Loader } from '../../components/Loader';
import { useHistory, useLocation } from 'react-router-dom';

function parseQueryString(queryString) {
  const params = new URLSearchParams(queryString);
  return params.get('refId');
}

export const formatOptionLabelLeadTo = (data) => {
  const { imageUrl, label } = data;

  return (
    <div class='flex items-center'>
      <div className='h-9 w-9 rounded-full bg-gray-50 flex items-center justify-center'>
        <img
          className={`object-cover rounded-full h-7 w-7 `}
          src={
            imageUrl ??
            'https://vercel.com/api/www/avatar/R7I3GPI1W8B4bTuz6urVv00F?&s=50'
          }
          alt=''
        />
      </div>

      <h6 className='text-sm pl-2 capitalize'>{label}</h6>
    </div>
  );
};

export const formatOptionLabel = (property) => {
  return (
    <div className='flex items-center cursor-pointer'>
      <span className='relative  inline-block flex-shrink-0'>
        <img
          className='lg:h-8 hidden md:block lg:w-8 h-8 w-8 border rounded-md object-cover'
          src={property?.coverImage ?? property?.images[0]}
          alt=''
        />
      </span>
      <div className='ml-4'>
        <div className='flex flex-col'>
          <span className='font-medium hidden md:block capitalize text-gray-900'>
            {property.title}{' '}
          </span>
        </div>
      </div>
    </div>
  );
};

export default function CreateReferal() {
  const params = useLocation();

  const refId = parseQueryString(params.search);

  const { user } = useSelector((state) => state.user);

  const { members, isLoading: isLoadingMembers } = useMembers({
    isClusterId: true,
    groupId: user?._id,
  });

  const { control, handleSubmit, setValue, watch, reset } = useForm({
    shouldUnregister: false,
    defaultValues: {
      referralType: 'SEND',
      type: 'REFERRAL',
      attachment: [],
      undertakingRequired: false,
    },
  });

  useEffect(() => {
    if (!isLoadingMembers && members && refId) {
      const currentMember = members.find((member) => member._id === refId);
      reset({
        leadTo: {
          ...currentMember,
          label: `${currentMember.firstName} ${currentMember.lastName}`,
          value: currentMember._id,
        },
      });
    }
  }, [isLoadingMembers, members, refId, reset]);

  const { mutation } = useCreateReferral();

  const history = useHistory();

  const attachments = watch('attachment');

  const undertakingRequired = watch('undertakingRequired');

  const [open, setOpen] = useState(false);

  const localUrl = 'https://stockappy-backend.herokuapp.com';

  const uppyConfig = useUppy(() => {
    return new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        maxFileSize: 1000000 * 10,
        allowedFileTypes: ['application/pdf'],
      },
      autoProceed: false,
    }).use(XHRUpload, {
      endpoint: `${localUrl}/image`,
    });
  });

  uppyConfig
    .off('complete', () => {})
    .on('complete', (result) => {
      const body = result.successful.map((file) => file.response?.body.image);
      setValue('attachment', body);
    });

  const { data, isLoading } = useListing();

  const listing =
    !isLoading && data ? data?.filter((data) => data.isActive) : [];

  const onSubmit = (data) => {
    const payload = {
      ...data,
      leadFrom: user._id,
      leadTo: data?.leadTo?.value,
      listingId: data?.listingId?.value,
    };

    mutation.mutate(payload, {
      onSuccess: () => {
        history.push('/referrals');
      },
    });
  };
  return (
    <Layout>
      <DashboardModal
        uppy={uppyConfig}
        closeAfterFinish
        note='Add a Attachment File Here'
        closeModalOnClickOutside
        open={open}
        onRequestClose={() => {
          setOpen(false);
        }}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='space-y-12 max-w-3xl mx-auto'>
          <div className='border-b border-gray-900/10 pb-12'>
            <h2 className='text-base font-semibold leading-7 text-gray-900'>
              Create Referral
            </h2>
            <p className='mt-1 text-sm leading-6 text-gray-600'>
              Get Started by filling out information to create a new referral.
            </p>

            <div className='mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
              <div className='sm:col-span-3'>
                <label
                  htmlFor='subject'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Subject
                </label>
                <div className='mt-2'>
                  <Controller
                    control={control}
                    name='subject'
                    as={
                      <input
                        id='subject'
                        type='text'
                        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                      />
                    }
                  />
                </div>
              </div>

              <div className='sm:col-span-3'>
                <label
                  htmlFor='country'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Lead To
                </label>

                <div className='mt-2'>
                  <Controller
                    control={control}
                    name='leadTo'
                    as={
                      <ReactSelect
                        isLoading={isLoadingMembers}
                        isDisabled={isLoadingMembers}
                        placeholder=''
                        formatOptionLabel={formatOptionLabelLeadTo}
                        options={members?.map((d) => ({
                          ...d,
                          label: `${d.firstName} ${d.lastName}`,
                          value: d._id,
                        }))}
                      />
                    }
                  />
                </div>
              </div>

              <div className='sm:col-span-3'>
                <label
                  htmlFor='status'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Send Status
                </label>
                <div className='mt-2'>
                  <Controller
                    name='referralType'
                    control={control}
                    render={(field) => (
                      <select
                        id='status'
                        {...field}
                        onChange={(e) => field.onChange(e.target.value)}
                        autoComplete='country-name'
                        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:max-w-xs sm:text-sm sm:leading-6'
                      >
                        <option value={'SEND'}>Send</option>
                        <option value={'DRAFT'}>Draft</option>
                      </select>
                    )}
                  />
                </div>
              </div>

              <div className='sm:col-span-3'>
                <label
                  htmlFor='country'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Type
                </label>
                <div className='mt-2'>
                  <Controller
                    name='type'
                    control={control}
                    render={(field) => (
                      <select
                        id='country'
                        {...field}
                        onChange={(e) => field.onChange(e.target.value)}
                        autoComplete='country-name'
                        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:max-w-xs sm:text-sm sm:leading-6'
                      >
                        <option value={'REFERRAL'}>Lead</option>
                        <option value={'INQUIRY'}>Inquiry</option>
                      </select>
                    )}
                  />
                </div>
              </div>

              <div className='col-span-full'>
                <label
                  htmlFor='notes'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Notes
                </label>
                <div className='mt-2'>
                  <Controller
                    control={control}
                    name='notes'
                    as={
                      <textarea
                        id='notes'
                        name='notes'
                        rows={3}
                        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                        defaultValue={''}
                      />
                    }
                  />
                </div>
                <p className='mt-3 text-sm leading-6 text-gray-600'>
                  Write a few sentences about yourself.
                </p>
              </div>
              <div className='sm:col-span-3'>
                <label
                  htmlFor='organizationName'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Organization Name
                </label>
                <div className='mt-2'>
                  <Controller
                    control={control}
                    name='organization'
                    as={
                      <input
                        type='text'
                        id='organizationName'
                        autoComplete='address-level1'
                        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                      />
                    }
                  />
                </div>
              </div>
              <div className='sm:col-span-3'>
                <label
                  htmlFor='commission'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Commission
                </label>
                <div className='mt-2'>
                  <Controller
                    control={control}
                    name='commission'
                    as={
                      <input
                        type='text'
                        id='commission'
                        autoComplete='address-level1'
                        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='border-b border-gray-900/10 pb-12'>
            <h2 className='text-base font-semibold leading-7 text-gray-900'>
              Listing
            </h2>
            <p className='mt-1 text-sm leading-6 text-gray-600'>
              Select the listing you want to send the Referral for.
            </p>

            <div className='mt-4 '>
              <Controller
                control={control}
                name='listingId'
                as={
                  <ReactSelect
                    isLoading={isLoading}
                    isDisabled={isLoading}
                    placeholder='Select Listing'
                    formatOptionLabel={formatOptionLabel}
                    options={listing?.map((d) => ({
                      ...d,
                      label: d.title,
                      value: d._id,
                    }))}
                  />
                }
              />
            </div>
          </div>
          <div className='border-b border-gray-900/10 pb-12'>
            <h2 className='text-base font-semibold leading-7 text-gray-900'>
              Personal Information
            </h2>
            <p className='mt-1 text-sm leading-6 text-gray-600'>
              Use a permanent address where you can receive mail.
            </p>

            <div className='mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
              <div className='sm:col-span-3'>
                <label
                  htmlFor='first-name'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  First name
                </label>
                <div className='mt-2'>
                  <Controller
                    control={control}
                    name='firstName'
                    as={
                      <input
                        type='text'
                        id='first-name'
                        autoComplete='given-name'
                        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                      />
                    }
                  />
                </div>
              </div>

              <div className='sm:col-span-3'>
                <label
                  htmlFor='last-name'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Last name
                </label>
                <div className='mt-2'>
                  <Controller
                    control={control}
                    name='lastName'
                    as={
                      <input
                        type='text'
                        id='last-name'
                        autoComplete='family-name'
                        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                      />
                    }
                  />
                </div>
              </div>

              <div className='sm:col-span-full'>
                <label
                  htmlFor='address'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Address
                </label>
                <div className='mt-2'>
                  <Controller
                    control={control}
                    name='address'
                    as={
                      <input
                        id='address'
                        type='text'
                        autoComplete='address'
                        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                      />
                    }
                  />
                </div>
              </div>

              <div className='sm:col-span-2'>
                <label
                  htmlFor='email'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Email address
                </label>
                <div className='mt-2'>
                  <Controller
                    control={control}
                    name='email'
                    as={
                      <input
                        id='email'
                        type='email'
                        autoComplete='email'
                        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                      />
                    }
                  />
                </div>
              </div>

              <div className='sm:col-span-2 '>
                <label
                  htmlFor='Office Phone'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Office Phone
                </label>
                <div className='mt-2'>
                  <Controller
                    control={control}
                    name='officePhone'
                    as={
                      <input
                        type='text'
                        id='Office Phone'
                        autoComplete='address-level2'
                        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                      />
                    }
                  />
                </div>
              </div>

              <div className='sm:col-span-2'>
                <label
                  htmlFor='cellPhone'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Cell Phone
                </label>
                <div className='mt-2'>
                  <Controller
                    control={control}
                    name='cellPhone'
                    as={
                      <input
                        type='text'
                        id='cellPhone'
                        autoComplete='address-level1'
                        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='border-b border-gray-900/10 pb-12'>
            <h2 className='text-base font-semibold leading-7 text-gray-900'>
              Expiry Date
            </h2>
            <p className='mt-1 text-sm leading-6 text-gray-600'>
              Select the Expiry date you want the Referral to expire on.
            </p>

            <div className='mt-4 sm:col-span-2'>
              <Controller
                control={control}
                name='expiresOn'
                className='block w-full rounded-md border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                as={<input type='date' />}
              />
            </div>
          </div>
          <div className='border-b pb-12 border-gray-900/10 sm:col-span-full'>
            {attachments?.length > 0 ? (
              <dd className=' text-sm text-gray-900 sm:col-span-full sm:mt-0'>
                <div>
                  <h2 className='text-base font-semibold leading-7 text-gray-900'>
                    Attachments
                  </h2>
                  <p className='mt-1 text-sm leading-6 text-gray-600'>
                    Upload Attachment here (pdf only).
                  </p>
                </div>
                <ul className='divide-y mt-4 divide-gray-100 rounded-md border border-gray-200'>
                  {attachments?.map((attachment) => (
                    <li
                      key={attachment}
                      className='flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6'
                    >
                      <div className='flex w-0 flex-1 items-center'>
                        <PaperClipIcon
                          className='h-5 w-5 flex-shrink-0 text-gray-400'
                          aria-hidden='true'
                        />
                        <div className='ml-4 flex min-w-0 flex-1 gap-2'>
                          <span className='truncate font-medium'>
                            attachment.pdf
                          </span>
                          {/* <span className='flex-shrink-0 text-gray-400'>
                          2.4mb
                        </span> */}
                        </div>
                      </div>
                      <div className='ml-4 flex-shrink-0'>
                        <button
                          type='button'
                          onClick={() => setValue('attachment', [])}
                          className='font-medium text-indigo-600 hover:text-indigo-500'
                        >
                          Remove
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              </dd>
            ) : (
              <div className='sm:flex justify-between w-full'>
                <div>
                  <h2 className='text-base font-semibold leading-7 text-gray-900'>
                    Attachments
                  </h2>
                  <p className='mt-1 text-sm leading-6 text-gray-600'>
                    Upload Attachment here (pdf only).
                  </p>
                </div>
                <div>
                  <div>
                    <div className='mt-3 sm:col-span-2 sm:mt-0'>
                      <button
                        onClick={() => setOpen(true)}
                        type='button'
                        className='inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
                      >
                        <DocumentArrowUpIcon
                          className='-ml-0.5 mr-2 h-4 w-4'
                          aria-hidden='true'
                        />
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className='border-t pt-8 mt-5'>
              <Switch.Group
                as='div'
                className='sm:flex items-center justify-between'
              >
                <span className='flex  flex-grow flex-col'>
                  <Switch.Label
                    as='span'
                    className='text-sm font-medium leading-6 text-gray-900'
                    passive
                  >
                    Action required to accept this referral
                  </Switch.Label>
                  <Switch.Description
                    as='span'
                    className='text-sm text-gray-500'
                  >
                    Please accept the terms and conditions contained in the
                    attached documents
                  </Switch.Description>
                </span>
                <Switch
                  checked={undertakingRequired}
                  onChange={() =>
                    setValue('undertakingRequired', !undertakingRequired)
                  }
                  className={classNames(
                    undertakingRequired ? 'bg-sky-600' : 'bg-gray-200',
                    'relative inline-flex mt-4 sm:mt-0 h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2'
                  )}
                >
                  <span
                    aria-hidden='true'
                    className={classNames(
                      undertakingRequired ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>
          </div>
        </div>

        <div className='mt-6 flex items-center justify-end gap-x-6'>
          <button
            type='button'
            className='text-sm font-semibold leading-6 text-gray-900'
          >
            Cancel
          </button>
          <button
            type='submit'
            disabled={mutation.isLoading}
            className='rounded-md inline-flex bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600'
          >
            {mutation.isLoading && <Loader className='mr-2' />}
            Save
          </button>
        </div>
      </form>
    </Layout>
  );
}
