import { PropertyHeader } from './property-details/PropertyHeader';
// import { PropertyInfo } from './property-details/PropertyInfo';
import { NearbyPlaces } from './property-details/NearbyPlaces';
import { AgencyDetails } from './property-details/AgencyDetails';
import { PropertyFeatures } from './property-details/PropertyFeatures';
import { PropertyDescription } from './property-details/PropertyDescription';
import { PropertyDesktopGallery } from './property-details/PropertyDesktopGallery';
import { PropertyEcpDocuments } from './property-details/PropertyEcpDocuments';
import { PropertyThreeSixty } from './property-details/PropertyThreeSixty';
import { PropertyCgi } from './property-details/PropertyCgi';
import { SimpleMap } from '../map/propDetailMap';
import { Link } from 'react-router-dom';
import { HomeIcon } from '@heroicons/react/20/solid';

export const EpcClasses = ({ classes }) => {
  if (!classes?.length) {
    return null;
  }
  return (
    <div className='border-y py-10'>
      <div className='capitalize font-semibold text-xl'>Classes</div>
      <div className='pt-3 grid grid-cols-2 md:grid-cols-12 gap-2 lg-grid-cols-7 text-black-50'>
        {classes?.map((value) => (
          <span
            key={value._id}
            className='inline-flex justify-center items-center rounded-md bg-sky-50 px-2 py-1 text-sm font-medium text-sky-700 ring-1 ring-inset ring-sky-700/10'
          >
            {value.label}
          </span>
        ))}
      </div>
    </div>
  );
};

const PropertyDetails = ({ property }) => {
  const { floorImages } = property || {};

  const pages = [
    { name: 'Listings', href: '/listings', current: false },
    { name: property.title, current: true },
  ];

  return (
    <section className='mx-auto border-t max-w-7xl px-6 lg:px-8'>
      <div>
        <nav className='flex pb-4 pt-10 border-b' aria-label='Breadcrumb'>
          <ol className='flex items-center space-x-4'>
            <li>
              <div>
                <Link
                  to='/dashboard'
                  className='text-gray-400 hover:text-gray-500'
                >
                  <HomeIcon
                    className='h-5 w-5 flex-shrink-0'
                    aria-hidden='true'
                  />
                  <span className='sr-only'>Home</span>
                </Link>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name}>
                <div className='flex items-center'>
                  <svg
                    className='h-5 w-5 flex-shrink-0 text-gray-300'
                    fill='currentColor'
                    viewBox='0 0 20 20'
                    aria-hidden='true'
                  >
                    <path d='M5.555 17.776l8-16 .894.448-8 16-.894-.448z' />
                  </svg>
                  {page.current ? (
                    <div className='ml-4 text-sm font-semibold text-gray-900 hover:text-gray-700'>
                      {page.name}
                    </div>
                  ) : (
                    <Link
                      to={page.href}
                      className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                    >
                      {page.name}
                    </Link>
                  )}
                </div>
              </li>
            ))}
          </ol>
        </nav>
        <PropertyHeader details={property} />
        <PropertyDesktopGallery property={property} />
        {/* <PropertyInfo details={property} /> */}
      </div>
      <div className='grid grid-cols-6 gap-x-7 pt-5'>
        <div className='sm:col-span-4 col-span-full'>
          <PropertyDescription details={property} />
          <PropertyFeatures features={property?.features} />
          <EpcClasses classes={property?.classes} />
          <NearbyPlaces details={property} />
          <PropertyEcpDocuments details={property} />
          <PropertyCgi details={property} />
          <PropertyThreeSixty details={property} />
        </div>
        <div className='col-span-2 space-y-5 hidden md:block'>
          {property?.isActive ? <AgencyDetails details={property} /> : null}

          <SimpleMap property={property} />
          <PropertyEcpDocuments details={property} />

          <div className='grid grid-cols-2 gap-x-2 shadow rounded-md'>
            {floorImages?.map((value) => (
              <img
                alt=''
                className='h-44 w-44 p-2 object-contain'
                src={value}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PropertyDetails;
