import React from 'react';
import { useCreateEvent } from '../../hooks/useCreateEvent';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Loader } from '../Loader';
import { useUpdateEvent } from '../../hooks/useUpdateEvent';
import moment from 'moment';

export const EventForm = ({ isEditing, lead, onClose, event }) => {
  const { control, errors, handleSubmit } = useForm({
    shouldUnregister: false,
    defaultValues: isEditing
      ? {
          ...event,
          eventDate: moment.utc(event.eventDate).format('YYYY-MM-DDTHH:mm:ss'),
        }
      : {},
  });

  const { mutation: createEvent } = useCreateEvent();

  const { mutation: updateEvent } = useUpdateEvent();

  const onSubmit = (data) => {
    const payload = {
      ...data,
      referalId: lead._id,
      leadTo: lead.leadTo._id,
      listingId: lead.listingId._id,
      leadFrom: lead.leadFrom._id,
    };

    if (isEditing) {
      updateEvent.mutateAsync(payload, {
        onSuccess: () => {
          onClose();
        },
      });
    } else {
      createEvent.mutateAsync(payload, {
        onSuccess: () => {
          onClose();
        },
      });
    }
  };
  return (
    <div>
      {' '}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='space-y-6'>
          <div>
            <h1 className='text-lg font-medium capitalize leading-6 text-gray-900'>
              {isEditing ? 'Update' : 'Edit'} Event For {lead.subject}
            </h1>
            <p className='mt-1 text-sm text-gray-500'>
              Let’s get started by filling in the information below to create
              your new Event.
            </p>
          </div>

          <div>
            <label
              htmlFor='event-subject'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              Event Subject
            </label>
            <div className='mt-2'>
              <Controller
                control={control}
                rules={{ required: true }}
                name='subject'
                as={
                  <input
                    type='text'
                    id='event-subject'
                    className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6'
                  />
                }
              />
              <ErrorMessage
                errors={errors}
                name='subject'
                message='Subject is required.'
                render={({ message }) => (
                  <p className='text-red-500 mt-2 text-sm'>{message}</p>
                )}
              />
            </div>
          </div>

          <div>
            <label
              htmlFor='link'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              Meeting Link
            </label>
            <Controller
              control={control}
              name='link'
              rules={{ required: true }}
              as={
                <input
                  type='text'
                  id='link'
                  className='mt-2 block w-full rounded-md border-0 py-2.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6'
                />
              }
            />
            <ErrorMessage
              errors={errors}
              name='link'
              message='Meeting link is required.'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>

          <div>
            <label
              htmlFor='description'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              Notes
            </label>
            <div className='mt-2'>
              <Controller
                control={control}
                name='description'
                as={
                  <textarea
                    id='description'
                    rows={3}
                    className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6'
                    defaultValue={''}
                  />
                }
              />
            </div>
          </div>

          <div>
            <label
              htmlFor='date'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              Event Date
            </label>
            <Controller
              control={control}
              rules={{ required: true }}
              name='eventDate'
              as={
                <input
                  type='datetime-local'
                  id='date'
                  className='mt-2 block w-full rounded-md border-0 py-2.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6'
                />
              }
            />
            <ErrorMessage
              errors={errors}
              name='eventDate'
              message='Event date is required.'
              render={({ message }) => (
                <p className='text-red-500 mt-2 text-sm'>{message}</p>
              )}
            />
          </div>

          <div className='mt-5 space-y-3 sm:space-y-0 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
            <button
              type='button'
              onClick={onClose}
              className='rounded-md bg-white px-3 py-2 text-sm  w-full font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
            >
              Cancel
            </button>
            <button
              type='submit'
              disabled={createEvent.isLoading || updateEvent.isLoading}
              className='rounded-md inline-flex items-center justify-center bg-slate-900 px-3 py-2 text-sm w-full font-semibold text-white shadow-sm hover:bg-slate-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-800'
            >
              {createEvent.isLoading || updateEvent.isLoading ? (
                <Loader className='mr-2' />
              ) : null}
              {isEditing ? 'Update' : 'Save'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
