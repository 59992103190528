import React from 'react';
import Navbar from '../components/global-components/navbar';
import Footer from '../components/global-components/footer';
const values = [
  {
    name: 'CODUQ - Where Opportunities Connect',
    description: `At CODUQ, we redefine business networking with our innovative SaaS platform, tailored for dynamic professionals seeking growth and connection. Our platform serves as
      a unique hub where members can register and share their business listings, fostering a collaborative environment for growth and expansion.`,
  },
  {
    name: 'Empowering Businesses with Seamless Networking',
    description: `Our members enjoy the privilege of uploading their listings, which undergo a meticulous approval process. Once approved, these listings are published selectively to
      other CODUQ members to ensure a targeted referral for your opportunity.`,
  },
  {
    name: 'Decisions Tailored to Your Business Needs',
    description: `CODUQ respects the autonomy of its members. Upon receiving referrals, members can evaluate these opportunities against their unique business objectives and choose
      to either accept or decline them. This flexibility ensures that every action taken is aligned with their strategic business goals`,
  },
  {
    name: 'Actioning Opportunities Through Events',
    description: `At the core of CODUQ, we believe in moving beyond connections to concrete action. Our platform facilitates this through organized events, where members can action
     the opportunities they’ve accepted, leading to tangible outcomes and strengthened business relationships.`,
  },
  {
    name: 'Guided by Terms, Driven by Opportunities',
    description: `Our terms and conditions are designed to maintain a fair, respectful and beneficial environment for all members. As a member of CODUQ, you join a community
      committed to mutual growth, opportunity sharing, and ethical business practices.`,
  },
  {
    name: 'Join CODUQ Today',
    description: `Become a part of a network where opportunities don’t just arise; they are created, shared, and actioned. Join CODUQ today, and take the first step towards transforming
      your business connections into valuable, actionable opportunities. If you’re not yet a member and you would like to list and publicise some of your opportunities you may
      also join us as a partner. Partners enjoy the benefit of access to our qualified and vetted membership to realise successful business outcomes.`,
  },
];
export const About = () => {
  return (
    <div>
      <div className='isolate bg-white'>
        <div
          className='absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]'
          aria-hidden='true'
        >
          <div
            className='relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]'
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
      <Navbar />
      <div className='mx-auto my-32 max-w-7xl px-6 sm:mt-40 lg:px-8'>
        <div className='mx-auto max-w-7xl text-center lg:mx-0'>
          <h2 className='text-3xl  font-bold tracking-tight text-gray-900 sm:text-4xl'>
            ABOUT US{' '}
          </h2>
          <p className='mt-6 text-lg leading-8 text-gray-600'>
            A place where partners and members meet to exchange leads via a
            referral system.
          </p>
        </div>
        <dl className='mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-1'>
          {values.map((value) => (
            <div key={value.name}>
              <dt className='font-semibold text-gray-900'>{value.name}</dt>
              <dd className='mt-1 text-gray-600'>{value.description}</dd>
            </div>
          ))}
        </dl>
      </div>
      <Footer />
    </div>
  );
};
