import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

export const Links = () => {
  const { control } = useFormContext();

  return (
    <section>
      <div>
        <div className='space-y-3 mb-4'>
          <div>
            <label className='block text-sm font-medium leading-6 text-gray-900'>
              Video Link
            </label>
            <div className='mt-2'>
              <Controller
                name='videoUrl'
                placeholder='Iframe embed link'
                control={control}
                className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                as={<input />}
              />
            </div>
          </div>
          <div>
            <label className='block text-sm font-medium leading-6 text-gray-900'>
              CGI Walkthrough
            </label>
            <div className='mt-2'>
              <Controller
                control={control}
                name='cgiLink'
                placeholder='Iframe embed link'
                className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                as={<input />}
              />
            </div>
          </div>
          <div>
            <label className='block text-sm font-medium leading-6 text-gray-900'>
              360 View
            </label>
            <div className='mt-2'>
              <Controller
                name='threeSixtyView'
                placeholder='Iframe Embed Link'
                control={control}
                className='block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6'
                as={<input />}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
