import React, { useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Intro } from './Intro/Intro';
import { Details } from './Details/details';
import { useForm, FormProvider } from 'react-hook-form';
import { FeaturesList } from './Features/features';
import { Save } from './Save';
import { PropertySetting } from './Settings/PropertySetting';
import { Links } from './Media/Links';
import { convertToRaw } from 'draft-js';
import { DocumentUploads } from './Media/DocumentUploads';
import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { PropertyGallery } from './Media/PropertyGallery';
import { useGetProfile } from '../../../hooks/useGetProfile';

export const PropertyForm = ({ defaultValues, type, mutation }) => {
  const [activeTab, setActiveTab] = useState('Main');

  const { data: profile } = useGetProfile();

  const tabs =
    profile.userType === 'CLUSTER_OWNER'
      ? [
          { name: 'Main' },
          { name: 'Features' },
          { name: 'Links / Docs' },
          { name: 'Gallery' },
          { name: 'Settings' },
        ]
      : [
          { name: 'Main' },
          { name: 'Features' },
          { name: 'Links / Docs' },
          { name: 'Gallery' },
        ];

  const [editorState, setEditorState] = useState(
    type === 'edit'
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(defaultValues.description)
          )
        )
      : EditorState.createEmpty()
  );

  const methods = useForm({
    defaultValues: {
      ...defaultValues,
      partnerId: defaultValues?.partnerId
        ? {
            imageUrl: defaultValues.partnerId.imageUrl,
            label: `${defaultValues.partnerId.firstName} ${defaultValues.partnerId.lastName}`,
            value: defaultValues.partnerId._id,
          }
        : '',
      expiryDate: moment
        .utc(defaultValues?.expiryDate)
        .format('yyyy-MM-DD')
        .toString(),
    },
    shouldUnregister: false,
  });

  const onSubmit = (data) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    data.description = markup;
    data.numberOfStaff = data.numberOfStaff
      ? parseInt(data.numberOfStaff)
      : null;
    data.availableSpaceMaximum = data.availableSpaceMaximum
      ? parseInt(data.availableSpaceMaximum)
      : null;
    data.availableSpaceMinimum = data.availableSpaceMinimum
      ? parseInt(data.availableSpaceMinimum)
      : null;
    data.userId = '61818869c729d2843791b95a';
    const partnerId =
      profile?.userType === 'PARTNER'
        ? profile?._id
        : data?.partnerId?.value || undefined;

    data.partnerId = partnerId;

    data.memberId = profile.userType === 'MEMBER' ? profile?._id : undefined;

    mutation.mutate(data);
  };
  return (
    <section>
      <div className='mt-7'>
        <div className='sm:hidden'>
          <label htmlFor='tabs' className='sr-only'>
            Select a tab
          </label>

          <select
            id='tabs'
            name='tabs'
            className='block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
            defaultValue={'Main'}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className='hidden sm:block'>
          <div className='border-b border-gray-200'>
            <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
              {tabs.map((tab) => (
                <div
                  key={tab.name}
                  onClick={() => setActiveTab(tab.name)}
                  className={classNames(
                    tab.name === activeTab
                      ? 'border-sky-500 text-sky-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap cursor-pointer border-b-2 py-4 px-1 text-sm font-medium'
                  )}
                >
                  {tab.name}
                </div>
              ))}
            </nav>
          </div>
        </div>
      </div>

      <div className='mt-5'>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className='py-4'>
              {activeTab === 'Main' ? (
                <div className='mb-7'>
                  <Intro />
                  <Details
                    editorState={editorState}
                    setEditorState={setEditorState}
                  />
                </div>
              ) : activeTab === 'Features' ? (
                <FeaturesList />
              ) : activeTab === 'Links / Docs' ? (
                <div>
                  {/* <DocumentUploads /> */}
                  <Links />
                </div>
              ) : activeTab === 'Gallery' ? (
                <PropertyGallery />
              ) : (
                <PropertySetting />
              )}

              <Save loading={mutation?.isLoading} type={type} />
            </div>
          </form>
        </FormProvider>
      </div>
    </section>
  );
};
