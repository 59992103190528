import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

const updateMemberProfile = async (data) => {
  try {
    const res = await axios.patch(`/member/${data._id}`, data);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const useUpdateMember = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(updateMemberProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries(['profile']);
    },
  });

  return {
    mutation,
  };
};
