import React from 'react';
import { EventForm } from '../../components/EventForm';

export const CreateEvent = ({ lead, onClose }) => {
  return (
    <main className='mx-auto max-w-2xl px-4 py-4'>
      <EventForm isEditing={false} lead={lead} onClose={onClose} />
    </main>
  );
};
