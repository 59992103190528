import axios from 'axios';
import { useQuery } from 'react-query';

const fetchGroups = async () => {
  try {
    const res = await axios.get('/group');
    return res.data.group;
  } catch (err) {
    throw err.response.data;
  }
};

export const useGroups = () => {
  const { data, isLoading, error } = useQuery(['GROUPS'], fetchGroups, {
    refetchOnWindowFocus: false,
  });

  return {
    groups: data,
    isLoading,
    error,
  };
};
