import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Loader } from '../Loader';
import { useStatusChange } from '../../hooks/useStatusChange';

export const RejectNotes = ({ lead, onClose }) => {
  const { handleSubmit, control } = useForm();

  const { mutation } = useStatusChange();

  const onSubmit = (data) => {
    const payload = {
      id: lead._id,
      status: 'REJECTED',
      rejectNotes: data?.rejectNotes,
    };

    mutation.mutateAsync(payload, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <main className='mx-auto max-w-2xl px-4 py-2'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='space-y-6'>
          <div>
            <h1 className='text-lg text-center font-medium capitalize leading-6 text-gray-900'>
              Reject Opportunity For {lead.subject}
            </h1>
            <p className='mt-1 text-sm text-center text-gray-500'>
              Are you sure you want to reject this opportunity?
            </p>
          </div>

          <div>
            <label
              htmlFor='description'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              Reason
            </label>
            <div className='mt-2'>
              <Controller
                control={control}
                name='rejectNotes'
                as={
                  <textarea
                    id='description'
                    rows={5}
                    className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6'
                    defaultValue={''}
                  />
                }
              />
            </div>
          </div>

          <div className='mt-5 space-y-3 sm:space-y-0 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
            <button
              type='button'
              className='rounded-md bg-white px-3 py-2 text-sm  w-full font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
            >
              Cancel
            </button>
            <button
              type='submit'
              disabled={mutation.isLoading}
              className='rounded-md inline-flex items-center justify-center bg-red-500 px-3 py-2 text-sm w-full font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-400'
            >
              {mutation.isLoading && <Loader className='mr-2' />}
              Decline
            </button>
          </div>
        </div>
      </form>
    </main>
  );
};
