import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

const signupPartner = async (data) => {
  try {
    const res = await axios.post('/partner/signup', data);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const useSignupPartner = ({ invalidate = false }) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(signupPartner, {
    onSuccess: () => {
      if (invalidate) {
        queryClient.invalidateQueries(['partners']);
      }
    },
  });

  return {
    mutation,
  };
};
