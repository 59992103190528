import React from 'react';
import Navbar from '../components/global-components/navbar';

export const Safety = () => {
  return (
    <div>
      <Navbar />
      <main className='mx-auto mt-10 max-w-7xl px-4 sm:px-6 lg:px-8'>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <section id='policy0' className='mb-5'>
                <h4 className='mb-4 font-weight-bold'>Safety and security</h4>
                <p>
                  Moveappy is not an estate agents and does not buy or sell
                  property. Moveappy only allows registered UK estate agents,
                  verified UK landlords and/or registered brokers to advertise
                  their properties on the moveappy platform.
                </p>

                <p>
                  Moveappy also allows verified registered service providers to
                  advertise their services on the moveappy platform. Service
                  providers are only allowed to upload advertisements that are
                  relevant to the property sector and/or are local to where a
                  particular property is listed. Prior to uploading a service
                  advertisement, service providers are asked specific questions
                  in respect of their services and if moveappy is not satisfied,
                  the advertisements will not qualify and/or will be taken down.
                </p>
                <p>
                  Moveappy is committed not to allow malicious, misleading or
                  poor quality advertisements to be published on its platform
                  and service providers risk suspension or removal from the
                  platform.
                </p>
                <p>
                  Please do not follow any specific or general instructions from
                  any individual or any organisation to hand over any money or
                  provide your personal details. Contact Moveappy if you are
                  suspicious about any activities from any individual who claims
                  to be from Moveappy.
                </p>
                <p>
                  <b className='text-body'>
                    Please be careful when you share your personal data online
                    or on our online platform.
                  </b>
                </p>
                <p>
                  We are here to advise you on how to navigate the moveappy
                  site, to help with marketing your home for selling and renting
                  purposes. If you are in the market to buy or rent a property
                  get in touch with us directly to request help or assistance
                  with our website navigation.
                </p>
                <h5 className='text-black-50 mb-3'>
                  Communication through our platform with potential agents,
                  buyers, sellers, brokers or service providers
                </h5>
                <p>
                  Check before you respond to any advertisements with any of the
                  above parties, best practice is to research them thoroughly
                  online first. Check all of their online details independently.
                  Start with their website (make sure they have a padlock sign /
                  https://), social media handles, any available reports (credit
                  checks, registration details, financial ombudsman check,
                  industry reports, Companies House register, director checks
                  and debt reports)
                </p>
                <p>
                  When you decide to communicate with any parties through our
                  website make sure you have checked their official address and
                  telephone numbers, be extra careful before you pass your
                  personal telephone numbers, home address, correspondence
                  address etc.
                </p>
                <p>
                  We at Moveappy will never ask you for any personal, sensitive
                  or financial information which includes bank details or ask
                  you for any deposit or money transfer.
                </p>
                <p>
                  <b className='text-body'>
                    Stay safe, avoid fraud and report any suspicious online
                    activity immediately by contacting us here.
                  </b>
                </p>
              </section>
              <section id='things-to-remember' className='mb-5'>
                <h4 className='mb-4 font-weight-bold'>Things to remember </h4>
                <ul
                  className='nav flex-column'
                  style={{ fontFamily: 'sans-serif', fontSize: 13 }}
                >
                  <li className='nav-item mb-2'>
                    1. MOVEAPPY does not buy or sell property
                  </li>
                  <li className='nav-item mb-2'>
                    2. MOVEAPPY does not provide financial advice
                  </li>
                  <li className='nav-item mb-2'>
                    3. MOVEAPPY does not ask for personal, sensitive or
                    financial information
                  </li>
                  <li className='nav-item mb-2'>
                    4. MOVEAPPY provides dedicated contact address/ telephone
                    number for you to get in touch if you believe anything is
                    suspicious
                  </li>
                  <li className='nav-item mb-2'>
                    5. MOVEAPPY does not currently allow opportunities outside
                    the UK
                  </li>
                </ul>
                <p>
                  Moveappy is providing here below a list of useful
                  organisations that may provide useful and specialist help to
                  avoid dangerous fraud and scams.
                </p>
                <ul className='flex-column'>
                  <li className='nav-item mb-2'>Action Fraud</li>
                  <li className='nav-item mb-2'>Citizens Advice</li>
                  <li className='nav-item mb-2'>Police</li>
                  <li className='nav-item mb-2'>Get safe online</li>
                </ul>
              </section>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
