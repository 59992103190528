import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

const updateEvent = async (data) => {
  try {
    const res = await axios.patch(`/event/${data._id}`, data);
    return res.data;
  } catch (err) {
    throw err.response;
  }
};

export const useUpdateEvent = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(updateEvent, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(['REFERRAL', variables.referalId]);
    },
  });

  return {
    mutation,
  };
};
