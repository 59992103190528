import React from 'react';

import { useQueryClient, useMutation } from 'react-query';
import axios from 'axios';
import notifications from '../../notifications';
import { Modal } from '../../Modal';
import { Loader } from '../../Loader';
import { Dialog } from '@headlessui/react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';

export const ConfirmDeleteModal = ({
  setShowReqModal,
  showReqModal,
  property,
}) => {
  const { _id, title } = property;

  const queryClient = useQueryClient();

  const { user } = useSelector((state) => state.user);

  const userId = user?._id;

  const handleDeleteProperty = async () => {
    try {
      const res = await axios.delete(`/property/${_id}`);
      return res;
    } catch (err) {
      throw err.response.data;
    }
  };

  const mutation = useMutation(handleDeleteProperty, {
    onSuccess: () => {
      setShowReqModal(false);
      notifications.success('Success', 'Deleted Successfully');
      queryClient.invalidateQueries(['LISTING', userId]);
    },
    onError: () => {
      notifications.error('Error', 'Try Again Later!');
    },
  });

  return (
    <Modal isOpen={showReqModal} onClose={() => setShowReqModal(!showReqModal)}>
      <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100'>
        <TrashIcon className='h-6 w-6 text-red-600' aria-hidden='true' />
      </div>
      <Dialog.Title
        as='h3'
        className='text-base mt-3 text-center font-semibold leading-6 text-gray-900'
      >
        {title}
      </Dialog.Title>
      <div className='mt-1 text-center'>
        <p className='text-sm text-gray-500'>
          Are you sure you want to deactivate your account? All of your data
          will be permanently removed from our servers forever. This action
          cannot be undone.
        </p>
      </div>

      <div className='mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
        <button
          className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto'
          type='button'
          onClick={() => setShowReqModal(false)}
        >
          Cancel
        </button>
        <button
          onClick={() => {
            mutation.mutate();
          }}
          className='inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto'
          disabled={mutation.isLoading}
        >
          {mutation.isLoading && <Loader className='mr-2' size='sm' />}
          Delete Property
        </button>
      </div>
    </Modal>
  );
};
