import { MapIcon, MapPinIcon } from '@heroicons/react/20/solid';
import { HomeIcon } from '@heroicons/react/24/solid';
import React from 'react';

const statuses = {
  Completed: 'text-green-400 bg-green-400/10',
  Error: 'text-rose-400 bg-rose-400/10',
};

export const MarkerComponent = ({ type }) => {
  return <MapPinIcon className='h-10 w-10 drop-shadow-xl text-blue-800' />;
};
