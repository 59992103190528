import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

const updatePartner = async (data) => {
  try {
    const res = await axios.patch(`/partner/${data._id}`, data);
    return res.data;
  } catch (err) {
    throw err.response;
  }
};

export const useUpdatePartner = () => {
  const queryClient = useQueryClient();
  const { user } = useSelector((state) => state.user);
  const isClusterOwner = user?.userType === 'CLUSTER_OWNER';
  const mutation = useMutation(updatePartner, {
    onSuccess: () => {
      if (isClusterOwner) {
        queryClient.invalidateQueries(['partners']);
      } else {
        queryClient.invalidateQueries(['profile']);
      }
    },
  });

  return {
    mutation,
  };
};
