import { EventItem } from './EventItem';
import SlideOver from '../SlideOver';

export const EventsList = ({ referral, isOpen, onClose }) => {
  return (
    <SlideOver title='Events' isOpen={isOpen} onClose={onClose}>
      <ul className='flex-1 divide-y divide-gray-200 overflow-y-auto'>
        {referral?.events?.map((event) => (
          <EventItem referral={referral} event={event} key={event._id} />
        ))}
      </ul>
    </SlideOver>
  );
};
