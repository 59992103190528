import React from 'react';
import { PropertyList } from '../components/properties-components/userPropAndRequests/propertyList';
import { Layout } from '../components/Layout';
import { Link } from 'react-router-dom';
import { useListing } from '../hooks/useListing';

const UserProperties = () => {
  const { data, isLoading } = useListing();

  return (
    <Layout loading={isLoading}>
      <div className='sm:flex sm:items-center pb-7 border-b mb-10'>
        <div className='sm:flex-auto'>
          <h1 className='text-base font-semibold leading-6 text-gray-900'>
            Listings
          </h1>
          <p className='mt-2 text-sm text-gray-700'>
            A list of all the Listings in your account.
          </p>
        </div>
        <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'>
          <Link
            to='/create-listing'
            className='rounded-md bg-sky-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600'
          >
            Add Listings
          </Link>
        </div>
      </div>
      <PropertyList properties={data} />
    </Layout>
  );
};

export default UserProperties;
