import { EnvelopeIcon, UserIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { MemberDetails } from './MemberDetails';
import { Link } from 'react-router-dom';

export const Member = ({ member }) => {
  const [openModal, setOpenModal] = useState(false);

  const onClose = () => {
    setOpenModal(false);
  };

  return (
    <li
      key={member._id}
      className='col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow'
    >
      <MemberDetails member={member} open={openModal} onClose={onClose} />
      <div className='flex flex-1 flex-col p-8'>
        <img
          className='mx-auto h-32 w-32 object-cover flex-shrink-0 rounded-full'
          src={
            member?.imageUrl ??
            'https://vercel.com/api/www/avatar/R7I3GPI1W8B4bTuz6urVv00F?&s=50'
          }
          alt=''
        />
        <div className='mt-6 text-sm font-medium text-gray-900'>
          <span className='mx-2 capitalize'>
            {member?.firstName} {member?.lastName}
          </span>
          <span className=' inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
            Verified
          </span>
        </div>
        <dl className='mt-1 flex flex-grow flex-col justify-between'>
          <dt className='sr-only'>Title</dt>
          {/* <dd className='text-sm text-gray-500'>{person.title}</dd> */}
          <dt className='sr-only'>Role</dt>
        </dl>
        <div className='text-gray-400 text-sm line-clamp-2'>{member?.bio}</div>
      </div>
      <div>
        <div className='-mt-px flex divide-x divide-gray-200'>
          <div className='flex w-0 flex-1'>
            <Link
              to={`/create-referrals?refId=${member._id}`}
              className='relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900'
            >
              <EnvelopeIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
              Refer
            </Link>
          </div>
          <div className='-ml-px flex w-0 flex-1'>
            <button
              onClick={() => setOpenModal(true)}
              className='relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900'
            >
              <UserIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
              Profile
            </button>
          </div>
        </div>
      </div>
    </li>
  );
};
